import React, { useState } from 'react';
import { View, Pressable } from 'react-native';
import { useTheme, Avatar, Text } from '@rneui/themed';
import { get, values } from 'lodash';
import PropTypes from 'prop-types';
import { ViewMore } from '../common';

export const Item = props => {
    const { theme } = useTheme();
    const imgSrc = get(props.json, 'image.cdnImagePath');
    const iconClass = get(props.json, 'iconClass');
    const heading = get(props.json, 'heading');
    const description = get(props.json, 'description');
    const linkKey = get(props.json, 'link.internalKey');
    const renderBorder = props.renderBorder;
    let image;

    if (imgSrc?.length > 0) {
        image = <Avatar rounded={true} size={96} source={{ uri: `https:${imgSrc}` }} />;
    } else if (iconClass?.length > 0) {
        image = (
            <Avatar
                icon={{
                    color: theme?.colors?.grey1,
                    name: iconClass,
                    type: theme?.iconKit || 'font-awesome-5'
                }}
                rounded={true}
                size={96}
                source={{ uri: `https:${imgSrc}` }}
            />
        );
    }

    return (
        <Pressable
            onPress={async () => {
                if (linkKey?.length > 0 && props?.followContentLink) {
                    await props.followContentLink(undefined, linkKey);
                }
            }}
        >
            <View
                style={
                    renderBorder
                        ? theme?.appComponents?.umbraco?.ListItemContainer?.itemWithBorder
                        : theme?.appComponents?.umbraco?.ListItemContainer?.item
                }
            >
                <View style={{ justifyContent: 'flex-start', marginRight: 20 }}>{image}</View>
                <View style={{ justifyContent: 'flex-end', flex: 1, marginBottom: 15 }}>
                    <Text numberOfLines={1} style={{ ...theme?.text?.H3, marginBottom: 5 }}>
                        {heading}
                    </Text>
                    <Text style={theme?.appComponents?.umbraco?.ListItemContainer?.itemDescription}>{description}</Text>
                </View>
            </View>
        </Pressable>
    );
};

const ListItemContainer = props => {
    const { theme } = useTheme();
    const [viewMoreIsOpen, setViewMoreIsOpen] = useState(false);
    const heading = get(props.json, 'heading');
    const listItems = values(props.json.contentItems);

    const renderListItems = () => {
        let itemsToRender = [];

        listItems.forEach((item, idx) => {
            if (idx > 2 && !viewMoreIsOpen) {
                return null;
            }

            itemsToRender.push(
                <Item key={item.id} followContentLink={props.followContentLink} json={item} renderBorder={idx !== listItems.length - 1} />
            );
        });

        return itemsToRender;
    };

    return (
        <View style={theme?.common?.Row}>
            <View style={theme?.common?.Col}>
                <View style={theme?.common?.Row}>
                    <View style={theme?.common?.Col}>
                        <Text numberOfLines={1} style={theme?.text?.ComponentTitle}>
                            {heading}
                        </Text>
                    </View>
                </View>
                <View style={theme?.common?.Row}>
                    <View style={theme?.common?.Col}>{renderListItems()}</View>
                </View>
                {listItems?.length > 3 && (
                    <View style={{ ...theme?.common?.Row, justifyContent: 'center', marginVertical: 5 }}>
                        <ViewMore handler={() => setViewMoreIsOpen(!viewMoreIsOpen)} text={viewMoreIsOpen ? 'less' : 'more'} />
                    </View>
                )}
            </View>
        </View>
    );
};

Item.propTypes = {
    json: PropTypes.shape({
        image: PropTypes.object,
        iconClass: PropTypes.string,
        heading: PropTypes.string,
        description: PropTypes.string
    })
};

ListItemContainer.propTypes = {
    json: PropTypes.shape({
        id: PropTypes.string,
        heading: PropTypes.string,
        contentItems: PropTypes.object
    })
};

export default ListItemContainer;
