import React from 'react';
import { View } from 'react-native';
import { useTheme, Button } from '@rneui/themed';
import { get } from 'lodash';
import PropTypes from 'prop-types';

const Options = props => {
    const register = get(props, 'register', false);
    const setRegister = get(props, 'setRegister');
    const { theme } = useTheme();

    return (
        <View
            style={{
                flexDirection: 'row',
                justifyContent: 'space-around',
                marginBottom: 20
            }}
            testID='Authentication.Options'
        >
            <Button
                buttonStyle={{
                    ...theme.appComponents?.common?.Authentication?.Options.button,
                    backgroundColor: !register ? theme?.colors?.navy : theme?.colors?.grey4
                }}
                containerStyle={{ flex: 1, marginRight: 10 }}
                onPress={() => setRegister(false)}
                testID='Authentication.Options.Login'
                title='LOGIN'
                titleStyle={{
                    ...theme.appComponents?.common?.Authentication?.Options.buttonTitle,
                    color: !register ? theme?.colors?.white : theme?.colors?.navy
                }}
                type='solid'
            />
            <Button
                buttonStyle={{
                    backgroundColor: register ? theme?.colors?.navy : theme?.colors?.grey4,
                    borderColor: theme?.colors?.navy,
                    borderWidth: 1
                }}
                containerStyle={{ flex: 1, marginLeft: 10 }}
                onPress={() => setRegister(true)}
                testID='Authentication.Options.Register'
                title='REGISTER'
                titleStyle={{
                    ...theme.appComponents?.common?.Authentication?.Options.buttonTitle,
                    color: register ? theme?.colors?.white : theme?.colors?.navy
                }}
                type='solid'
            />
        </View>
    );
};

Options.propTypes = {
    register: PropTypes.bool.isRequired,
    setRegister: PropTypes.func.isRequired
};

export default Options;
