import { useWindowDimensions, Platform } from 'react-native';
import { getDeviceType }                 from 'react-native-device-info';

const os    = Platform.OS;
const isTV  = Platform.isTV;

const isPad = os === 'web' 
                        ? (/iPad|Macintosh|MacIntel/.test(navigator.userAgent) && navigator.maxTouchPoints > 0) 
                        : Platform.isPad;
const deviceType = getDeviceType();

const useDeviceInfo = () => 
{
    const { height, scale, width }  = useWindowDimensions();
    const isDesktopOrLaptop         = ( width >= 991 ) && os === 'web';
    const isLandscape               = ( width >= height );
    const isMobile                  = ( width <= 480 );
    const isNarrow                  = ( width <= 220 );
    const isNative                  = (os.equals('ios') || os.equals('android'));
    const isPortrait                = ( height >= width );
    const isTablet                  = ( width >= 480 && width <= 991 );
    const vmin                      = Math.min(width / 100, height / 100);
    const vmax                      = Math.max(width / 100, height / 100);

    return  {  
        height,
        isDesktopOrLaptop,
        isIPad: isPad,
        isLandscape,
        isMobile,
        isNarrow,
        isNative,
        isPortrait,
        isTablet,
        isTV,
        os,
        scale,
        type: deviceType,
        vmin,
        vmax,
        width
    }
};

export default useDeviceInfo;