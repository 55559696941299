import React, { useContext, useState } from 'react';
import { View } from 'react-native';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { useTheme, BottomSheet, Image, Overlay } from '@rneui/themed';
import PropTypes from 'prop-types';
import Login from './Login';
import Options from './Options';
import Registration from './Registration';
import AuthContext from '../../../contexts/auth';
import { RegistrationContextProvider } from '../../../contexts/registration';
import { useDeviceInfo } from '../../../hooks';

const logo = require('../../../../assets/images/splash.png');

const Authentication = ({ visible, setVisible }) => {
    const { state } = useContext(AuthContext);
    const { isMobile, height } = useDeviceInfo();
    const [register, setRegister] = useState(false);
    const { theme } = useTheme();

    if (state.user.loggedIn) {
        return null;
    }

    if (isMobile) {
        return (
            <SafeAreaProvider>
                <View style={{ flex: 1, padding: 30 }}>
                    <BottomSheet
                        backdropStyle={{ backgroundColor: theme?.colors?.grey2, opacity: 0.5 }}
                        containerStyle={{ backgroundColor: theme.colors?.white, height: height, justifyContent: 'center' }}
                        isVisible={visible}
                        onBackdropPress={() => setVisible(false)}
                        testID='Authentication.Overlay'
                    >
                        <View style={{ marginHorizontal: theme.margins?.gutter }}>
                            <Image
                                childrenContainerStyle={{ height: undefined, width: undefined }}
                                containerStyle={{
                                    alignItems: 'center',
                                    flexDirection: 'row',
                                    justifyContent: 'center'
                                }}
                                source={logo}
                                style={{
                                    height: 85,
                                    position: undefined,
                                    resizeMode: 'contain',
                                    tintColor: theme.colors.navy,
                                    width: 125
                                }}
                            />
                            <Options register={register} setRegister={setRegister} />
                            {!register && <Login />}
                            {register && (
                                <RegistrationContextProvider>
                                    <Registration />
                                </RegistrationContextProvider>
                            )}
                        </View>
                    </BottomSheet>
                </View>
            </SafeAreaProvider>
        );
    } else {
        return (
            <View style={{ flex: 1, padding: 30, maxHeight: '50%' }}>
                <Overlay
                    backdropStyle={{ backgroundColor: theme?.colors?.grey2, opacity: 0.5 }}
                    isVisible={visible}
                    onBackdropPress={() => setVisible(false)}
                    overlayStyle={{ minHeight: '30%', minWidth: '30%' }}
                    testID='Authentication.Overlay'
                >
                    <Image
                        childrenContainerStyle={{ height: undefined, width: undefined }}
                        containerStyle={{
                            alignItems: 'center',
                            flexDirection: 'row',
                            justifyContent: 'center'
                        }}
                        source={logo}
                        style={{
                            height: 85,
                            position: undefined,
                            resizeMode: 'contain',
                            tintColor: theme.colors.navy,
                            width: 125
                        }}
                    />
                    <Options register={register} setRegister={setRegister} />
                    {!register && <Login />}
                    {register && (
                        <RegistrationContextProvider>
                            <Registration />
                        </RegistrationContextProvider>
                    )}
                </Overlay>
            </View>
        );
    }
};

Authentication.propTypes = {
    visible: PropTypes.bool.isRequired,
    setVisible: PropTypes.func.isRequired
};

export default Authentication;
