import { Alert, Platform } from 'react-native';

export default ({ message, title }) => {
    if (!message) {
        return null;
    }

    if (Platform.OS.equals('web')) {
        if (title) {
            window.alert(`${title}\r${message}`);
        } else {
            window.alert(message);
        }
    } else {
        Alert.alert(title, message);
    }
};
