import React, { useContext, useMemo, useState } from 'react';
import { View } from 'react-native';
import { useTheme, Button, CheckBox, Input, Text } from '@rneui/themed';
import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import Loading from '../../Loading';
import SelectField from '../../SelectField';
import AppContext from '../../../../contexts/app';
import AuthContext, { ActionTypes } from '../../../../contexts/auth';
import { useApi } from '../../../../hooks';

const RegistrationStep5 = ({ clearErrors, control, getValues, handleSubmit, isSubmitting, isValid, setValue }) => {
    const { get, post } = useApi();
    const { state: appState } = useContext(AppContext);
    const { dispatch: authDispatch } = useContext(AuthContext);
    const [addressValidated, setAddressValidated] = useState(false);
    const [authorized, setAuthorized] = useState(false);
    const { theme } = useTheme();

    const Countries = useMemo(() => {
        const countries = [];

        for (const [key, value] of Object.entries(appState.countries)) {
            countries.push({
                label: value,
                value: key
            });
        }

        return countries;
    }, [appState.countries]);

    const validateAddress = async () => {
        if (!addressValidated) {
            let endpoint = `${appState.settings?.smartyStreetsSettings?.usApiEndPoint}/street-address`;
            let queryString = `?auth-id=${appState.settings?.smartyStreetsSettings?.clientSideKey}`;
            const address = getValues('address');
            const address2 = getValues('address2');
            const city = getValues('city');
            const state = getValues('state');
            const zip = getValues('zip');
            const country = getValues('country');

            if (!country.toLowerCase().equals('us')) {
                endpoint = `${appState.settings?.smartyStreetsSettings?.intlApiEndPoint}/street-address`;
            }

            if (address && zip) {
                queryString += `&street=${address}&zipcode=${zip}`;

                if (address2) {
                    queryString += `&street2=${address2}`;
                }

                if (city) {
                    queryString += `&city=${city}`;
                }

                if (state) {
                    queryString += `&state=${state}`;
                }

                const result = await get(endpoint + queryString);

                if (result?.length > 0) {
                    setValue('address', result[0].delivery_line_1, {
                        shouldValidate: false
                    });
                    setValue('city', result[0].components?.city_name, {
                        shouldValidate: false
                    });
                    setValue('state', result[0].components?.state_abbreviation, { shouldValidate: false });
                    setValue('zip', `${result[0].components?.zipcode}-${result[0].components?.plus4_code}`, { shouldValidate: false });

                    clearErrors(['address', 'city', 'state', 'zip']);

                    setAddressValidated(() => true);
                }
            }
        }
    };

    const handleCreateAccount = async data => {
        const requestBody = {
            email: data.email,
            phone: data.mobile || getValues('mobileNumber'),
            password: data.password,
            firstname: data.firstName,
            lastName: data.lastName,
            country: data.country,
            address1: data.address,
            address2: data.address2,
            city: data.city,
            state: data.state,
            postalCode: data.zip
        };

        const response = await post(`${appState.settings.openIdSettings.authority}/Account/CreateAccountWithJsonResponse`, requestBody);

        if (response?.success && response?.token) {
            authDispatch({
                type: ActionTypes.LOGIN,
                data: {
                    accessToken: response.token,
                    details: {
                        ...requestBody,
                        given_name: `${requestBody.firstname}`
                    },
                    email: data.email,
                    loggedIn: true,
                    registered: true
                }
            });
        }
    };

    return (
        <View testID='Registration.Step5'>
            <Controller
                control={control}
                defaultValue=''
                name='password'
                render={({ field, fieldState }) => {
                    return (
                        <Input
                            {...field}
                            errorMessage={!fieldState.error ? 'CREATE A PASSWORD' : fieldState.error?.message}
                            errorStyle={
                                !fieldState.error
                                    ? theme?.appComponents?.common?.Authentication?.Registration?.placeholderStyle
                                    : theme?.appComponents?.common?.Authentication?.Registration?.errorStyle
                            }
                            innerRef={field.ref}
                            inputStyle={theme?.appComponents?.common?.Authentication?.Registration?.smallInputStyle}
                            onChangeText={val => field.onChange(val)}
                            secureTextEntry={true}
                            spellCheck={false}
                            textContentType='newPassword'
                        />
                    );
                }}
                rules={{
                    required: {
                        message: 'Create a password is required',
                        value: true
                    }
                }}
            />
            <Controller
                control={control}
                defaultValue=''
                name='confirmPassword'
                render={({ field, fieldState }) => {
                    return (
                        <Input
                            {...field}
                            errorMessage={!fieldState.error ? 'CONFIRM PASSWORD' : fieldState.error?.message}
                            errorStyle={
                                !fieldState.error
                                    ? theme?.appComponents?.common?.Authentication?.Registration?.placeholderStyle
                                    : theme?.appComponents?.common?.Authentication?.Registration?.errorStyle
                            }
                            innerRef={field.ref}
                            inputStyle={theme?.appComponents?.common?.Authentication?.Registration?.smallInputStyle}
                            onChangeText={val => field.onChange(val)}
                            secureTextEntry={true}
                            textContentType='newPassword'
                            spellCheck={false}
                        />
                    );
                }}
                rules={{
                    required: {
                        message: 'Confirm password is required',
                        value: true
                    }
                }}
            />
            <Controller
                control={control}
                defaultValue=''
                name='firstName'
                render={({ field, fieldState }) => {
                    return (
                        <Input
                            {...field}
                            errorMessage={!fieldState.error ? 'FIRST NAME' : fieldState.error?.message}
                            errorStyle={
                                !fieldState.error
                                    ? theme?.appComponents?.common?.Authentication?.Registration?.placeholderStyle
                                    : theme?.appComponents?.common?.Authentication?.Registration?.errorStyle
                            }
                            innerRef={field.ref}
                            inputStyle={theme?.appComponents?.common?.Authentication?.Registration?.smallInputStyle}
                            onChangeText={val => field.onChange(val)}
                            textContentType='givenName'
                            spellCheck={false}
                        />
                    );
                }}
                rules={{
                    required: {
                        message: 'First name is required',
                        value: true
                    }
                }}
            />
            <Controller
                control={control}
                defaultValue=''
                name='lastName'
                render={({ field, fieldState }) => {
                    return (
                        <Input
                            {...field}
                            errorMessage={!fieldState.error ? 'LAST NAME' : fieldState.error?.message}
                            errorStyle={
                                !fieldState.error
                                    ? theme?.appComponents?.common?.Authentication?.Registration?.placeholderStyle
                                    : theme?.appComponents?.common?.Authentication?.Registration?.errorStyle
                            }
                            innerRef={field.ref}
                            inputStyle={theme?.appComponents?.common?.Authentication?.Registration?.smallInputStyle}
                            onChangeText={val => field.onChange(val)}
                            textContentType='familyName'
                            spellCheck={false}
                        />
                    );
                }}
                rules={{
                    required: {
                        message: 'First name is required',
                        value: true
                    }
                }}
            />
            <Controller
                control={control}
                defaultValue='US'
                name='country'
                render={({ field }) => (
                    <View>
                        <SelectField
                            {...field}
                            containerStyle={{
                                borderBottomWidth: 0.5,
                                borderBottomColor: theme?.colors?.lightgrey2,
                                marginHorizontal: 10,
                                marginTop: 10
                            }}
                            innerRef={field.ref}
                            onValueChange={val => field.onChange(val)}
                            options={Countries}
                            prompt='Country'
                            style={{
                                ...theme?.appComponents?.common?.Authentication?.Registration?.selectFieldInputText,
                                border: 0,
                                color: theme?.colors?.black,
                                marginBottom: 10,
                                maxHeight: 40,
                                width: undefined
                            }}
                            testID='Registration.Step5.Country'
                        />
                        <Text
                            style={{
                                ...theme?.appComponents?.common?.Authentication?.Registration?.selectFieldInputText,
                                marginBottom: 10,
                                marginLeft: 20,
                                marginTop: 10
                            }}
                        >
                            Country
                        </Text>
                    </View>
                )}
                rules={{
                    required: {
                        message: 'Country is required',
                        value: true
                    }
                }}
            />
            <Controller
                control={control}
                defaultValue=''
                name='address'
                render={({ field, fieldState }) => {
                    return (
                        <Input
                            {...field}
                            errorMessage={!fieldState.error ? 'ADDRESS LINE #1 *' : fieldState.error?.message}
                            errorStyle={
                                !fieldState.error
                                    ? theme?.appComponents?.common?.Authentication?.Registration?.placeholderStyle
                                    : theme?.appComponents?.common?.Authentication?.Registration?.errorStyle
                            }
                            innerRef={field.ref}
                            inputContainerStyle={{ width: '100%' }}
                            inputStyle={
                                addressValidated
                                    ? theme?.appComponents?.common?.Authentication?.Registration?.validSmallInputStyle
                                    : theme?.appComponents?.common?.Authentication?.Registration?.smallInputStyle
                            }
                            placeholderTextColor={theme?.colors?.verydarkgrey}
                            textContentType='streetAddressLine1'
                            spellCheck={false}
                        />
                    );
                }}
                rules={{
                    required: {
                        message: 'Address Line #1 is required',
                        value: true
                    },
                    validate: async () => await validateAddress()
                }}
            />
            <Controller
                control={control}
                defaultValue=''
                name='address2'
                render={({ field }) => {
                    return (
                        <Input
                            {...field}
                            errorMessage='ADDRESS LINE #2'
                            errorStyle={theme?.appComponents?.common?.Authentication?.Registration?.placeholderStyle}
                            onChangeText={val => field.onChange(val)}
                            innerRef={field.ref}
                            inputContainerStyle={{ width: '100%' }}
                            inputStyle={theme?.appComponents?.common?.Authentication?.Registration?.smallInputStyle}
                            textContentType='streetAddressLine2'
                            spellCheck={false}
                        />
                    );
                }}
            />
            <View style={{ flexDirection: 'row', width: '100%' }}>
                <Controller
                    control={control}
                    defaultValue=''
                    name='city'
                    render={({ field, fieldState }) => {
                        return (
                            <Input
                                {...field}
                                containerStyle={{ width: '33%' }}
                                errorMessage={!fieldState.error ? 'CITY / REGION' : fieldState.error?.message}
                                errorStyle={
                                    !fieldState.error
                                        ? theme?.appComponents?.common?.Authentication?.Registration?.placeholderStyle
                                        : theme?.appComponents?.common?.Authentication?.Registration?.errorStyle
                                }
                                innerRef={field.ref}
                                inputStyle={
                                    addressValidated
                                        ? theme?.appComponents?.common?.Authentication?.Registration?.validSmallInputStyle
                                        : theme?.appComponents?.common?.Authentication?.Registration?.smallInputStyle
                                }
                                onChangeText={val => field.onChange(val)}
                                textContentType='addressCity'
                                spellCheck={false}
                            />
                        );
                    }}
                    rules={{
                        required: {
                            message: 'City is required',
                            value: true
                        }
                    }}
                />
                <Controller
                    control={control}
                    defaultValue=''
                    name='state'
                    render={({ field, fieldState }) => {
                        return (
                            <Input
                                {...field}
                                containerStyle={{ width: '33%' }}
                                errorMessage={!fieldState.error ? 'STATE / PROVINCE' : fieldState.error?.message}
                                errorStyle={
                                    !fieldState.error
                                        ? theme?.appComponents?.common?.Authentication?.Registration?.placeholderStyle
                                        : theme?.appComponents?.common?.Authentication?.Registration?.errorStyle
                                }
                                innerRef={field.ref}
                                inputStyle={
                                    addressValidated
                                        ? theme?.appComponents?.common?.Authentication?.Registration?.validSmallInputStyle
                                        : theme?.appComponents?.common?.Authentication?.Registration?.smallInputStyle
                                }
                                onChangeText={val => field.onChange(val)}
                                textContentType='addressState'
                                spellCheck={false}
                            />
                        );
                    }}
                    rules={{
                        required: {
                            message: 'State is required',
                            value: true
                        }
                    }}
                />
                <Controller
                    control={control}
                    defaultValue=''
                    name='zip'
                    render={({ field, fieldState }) => {
                        return (
                            <Input
                                {...field}
                                errorMessage={!fieldState.error ? 'POSTAL CODE' : fieldState.error?.message}
                                errorStyle={
                                    !fieldState.error
                                        ? theme?.appComponents?.common?.Authentication?.Registration?.placeholderStyle
                                        : theme?.appComponents?.common?.Authentication?.Registration?.errorStyle
                                }
                                innerRef={field.ref}
                                onChangeText={val => field.onChange(val)}
                                containerStyle={{ width: '33%' }}
                                textContentType='postalCode'
                                inputStyle={
                                    addressValidated
                                        ? theme?.appComponents?.common?.Authentication?.Registration?.validSmallInputStyle
                                        : theme?.appComponents?.common?.Authentication?.Registration?.smallInputStyle
                                }
                                spellCheck={false}
                            />
                        );
                    }}
                    rules={{
                        required: {
                            message: 'Postal Code is required',
                            value: true
                        },
                        validate: async () => await validateAddress()
                    }}
                />
            </View>
            <CheckBox
                center={true}
                checked={authorized}
                checkedColor={theme?.colors?.red}
                containerStyle={{ flexDirection: 'row', width: '100%' }}
                textStyle={theme?.appComponents?.common?.Authentication?.Registration?.checkboxText}
                //
                // title will be needed to come from Umbraco
                //
                title={
                    'By creating an account with Hagee Ministries, I accept the terms and conditions ' +
                    'of the Privacy Policy and Terms of Use. I consent to having my data stored and ' +
                    ' used for occasional marketing communications.'
                }
                onIconPress={() => setAuthorized(current => !current)}
            />
            <Button
                buttonStyle={theme.appComponents?.common?.Authentication?.Registration?.stepButton}
                containerStyle={theme.appComponents?.common?.Authentication?.Registration?.stepButtonContainer}
                disabled={!isValid || !authorized}
                disabledStyle={theme.appComponents?.common?.Authentication?.Registration?.disabledStepButton}
                disabledTitleStyle={theme.appComponents?.common?.Authentication?.Registration?.disabledStepButtonTitle}
                onPress={handleSubmit(handleCreateAccount)}
                title='CREATE ACCOUNT'
                titleStyle={theme.appComponents?.common?.Authentication?.Registration?.stepButtonTitle}
                type='solid'
            />
            {isSubmitting && <Loading />}
        </View>
    );
};

RegistrationStep5.propTypes = {
    clearErrors: PropTypes.func.isRequired,
    control: PropTypes.object.isRequired,
    getValues: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    isValid: PropTypes.bool.isRequired,
    setValue: PropTypes.func.isRequired
};

export default RegistrationStep5;
