import React                                    from 'react';
import {  View,ActivityIndicator,Platform }     from 'react-native';
import { Image,useTheme }                       from '@rneui/themed'; 
import { get }                                  from 'lodash';
import PropTypes                                from 'prop-types';
import Constants                                from 'expo-constants';
import { SvgUri }                               from "react-native-svg";


const Header = (topNav) => {
    const {theme}                               = useTheme()
    const contentItems                          = get(topNav)
    const logoCdnImagePath                      = get(topNav,'topNav.logo.cdnImagePath')
    const imgExt                                = logoCdnImagePath.substring(logoCdnImagePath.length - 4, logoCdnImagePath.length);

// TODO MAKE A STANDARD SVG COMPONENT FOR GLOBAL USE SEE IMAGEWITHDESCRIPTION
const SvgFallback = () => {
return (
    <SvgUri uri='https://jhm-cms.images.sardius.media/media/12467/cs_logo_4color_horiz-01.svg' //NEED A FALLBACK LOGO URI
            height={75}
            width={125}
            />
  )
};

  return (
        <View style={{flexDirection:'row',
                      width: '100%',
                      justifyContent:'center',
                      alignItems:'center',
                      backgroundColor:theme?.colors?.white,
                      paddingTop:Constants.statusBarHeight,
                      paddingBottom:15                
                    }}
                      >

            {
              imgExt?.equals('.svg') && !Platform.OS.equals('web') &&
                <SvgUri uri={`https:${logoCdnImagePath}`} 
                        // height={props.logo.height}
                        // width={props.logo.width}
                        height={50}
                        width={'100%'}
                        fallback={<SvgFallback />}
                />
            }
          {
            imgExt.equals('.svg') && Platform.OS.equals('web') &&
              <Image resizeMode='center'
                    source={{ uri: `https:${logoCdnImagePath}`}} 
                    // style={{height: props.height, width: props.width}} 
                    height={150}
                    width={200}
              />
          }
          {
            !imgExt.equals('.svg') &&
              <Image resizeMode='center'
                    source={{ uri: `https:${logoCdnImagePath}`}} 
                    style={{height: props.height, width: props.width}}
                alt={'Mobile Home screen Cornerstone Logo'} 
                    PlaceholderContent={<ActivityIndicator />}
              />
          }

        </View>
      )
  }

Header.propTypes ={
   splashLogo:PropTypes.string,
   backgroundColor:PropTypes.string,
   headerBackgroundColor:PropTypes.string,
   logUrl:PropTypes.string

}
export default Header

