import React                                    from 'react';
import { StyleSheet, View }                     from 'react-native';
import { useTheme, Text }                       from '@rneui/themed';
import { get, values }                          from 'lodash';
import PropTypes                                from 'prop-types';
import { ImageWithOptionalOverlay }             from '../common';
import { useDeviceInfo }                        from '../../hooks';

export const Item = props =>
{
	if (!props.json) {
		return null;
	}
	const {isMobile,isTablet,width} 		  = useDeviceInfo()
	const { theme }               			  = useTheme();
	const backgroundColor		  			  = get(props.json, 'backgroundColor');
	const backgroundImage		  			  = get(props.json, 'backgroundImage');
	const internalKey			  			  = get(props.json, 'link.internalKey');
	const isExternal			  			  = get(props.json, 'link.isExternal');
	const externalUrl			  			  = get(props.json, 'link.externalUrl');
	const linkText				  			  = get(props.json, 'linkText');
	const textColor               			  = ( backgroundColor ||  backgroundImage?.cdnImagePath?.length>0) ? '#ffffff' : '#007bff';

	return (
        <View style={{
			      backgroundColor: backgroundImage?.cdnImagePath?.length===0 ? backgroundColor || '#ffffff' : undefined,
			      justifyContent: 'center',
			      paddingBottom: StyleSheet.hairlineWidth, 
			      paddingRight: ( props.index % 2 === 0) ? StyleSheet.hairlineWidth : undefined, 
			      width: '50%',
			      height: isMobile || isTablet ? (width * 0.30) : (width * 0.16)
			  }}
		>
			<ImageWithOptionalOverlay alt={linkText}
			                          childContainerStyle={{ alignItems: 'center', justifyContent: 'center' }}
			                          enableOverlay={false}
									  imagePath={`https:${backgroundImage?.cdnImagePath}`}
									  containerStyle={{
									      alignItems: 'center', 
										  flex: 1, 
										  height:'100%', 
										  justifyContent: 'center', 
										  width:'100%' 
									  }}
									  onPress={()=> props.followContentLink(isExternal, internalKey, externalUrl)}
			>
				<Text numberOfLines={1} 
					  style={{
						  ...theme?.text?.H3,
						  color: textColor,
						  textAlign: 'center'
					  }}
				>
					{ linkText }
				</Text>
			</ImageWithOptionalOverlay>
        </View>
	);
};

const LinkPanel = props =>
{
	const { theme } = useTheme();
	const heading   = get(props.json, 'heading');
	const items     = get(props.json, 'contentItems');

	return (
		<View style={{
			      alignItems: 'center',
				  flex: 1, 
				  justifyContent: 'center'
			   }
		}>
			{
				!!heading &&
					<View style={{flexDirection:'row'}}>
						<Text numberOfLines={1} 
							  style={{
							      ...theme?.text?.H2,
								  marginBottom: 10, 
								  marginLeft: 20
							  }}
						>
							{ heading }
						</Text>
					</View>
			}
			<View style={{
					  flex: 1,
				      flexDirection:'row',
					  flexWrap: 'wrap',
					  justifyContent:'center',
					  width:'100%'
				  }}
			>
				{
					values(items).map((linkPanelItem, index) => 
						<Item index={index}
							  key={linkPanelItem.id}
						      followContentLink={props.followContentLink} 
						      json={linkPanelItem} 
							  testID='LinkPanel.Item'
						/> 
					)
				}
			</View>
	  	</View> 
    );
};

Item.propTypes = {
	json: PropTypes.shape({
		linkText: PropTypes.string,
		backgroundColor: PropTypes.string,
		backgroundImage: PropTypes.shape({
			cdnImagePath: PropTypes.string
		}),
		link: PropTypes.shape({
			externalUrl: PropTypes.string,
			internalKey: PropTypes.string,
			isExternal: PropTypes.bool
		})
	}),
};

LinkPanel.propTypes = {
	json: PropTypes.shape({ 
		contentItems: PropTypes.object,
		heading  : PropTypes.string
	})
};

export default LinkPanel;
