import React, { useCallback, useContext }  from 'react';
import { Linking }                         from 'react-native';
import * as ExpoFileLogger                 from 'expo-file-logger';
import * as SplashScreen                   from 'expo-splash-screen';
import AppContext, { AppActions }          from '../contexts/app';
import { useApi, useDeviceInfo }           from '../hooks';
import { MobileView, WebView }             from '../views';

const MainContainer = props => 
{
	const { fetchPage, fetchDomainItemByKey }   = useApi();
    const { state, dispatch }                   = useContext(AppContext);
	const { isIPad, isMobile, isTablet, os }    = useDeviceInfo();
	const siteWrapper                           = os.equals('ios') || os.equals('android')
													? state?.siteWrapper?.native 
													: state?.siteWrapper?.web;

	const goBack = useCallback(async () => {
		const href         = state.action.href;
		let previousHref   = state.action.previousHref;

		if (!previousHref) {
			previousHref = '/';
		}

		ExpoFileLogger.log(
			'debug', 
			`MainContainer.goBack[from: ${href}, to: ${previousHref}]`
		);

		await fetchPage(dispatch, previousHref);
	}, [ state.action.href, state.action.previousHref]);

    const followContentLink = useCallback(async (isExternal, internalKey, externalUrl, refs) => {
		if (isExternal) {
			ExpoFileLogger.log(
				'debug', 
				`MainContainer.followContentLink: external link (${externalUrl})`
			);

			Linking.openURL(externalUrl);
		}
		else {
			let page = state?.action?.data?.references[internalKey];

			if (!page) {
				if (refs) {
					page = refs[internalKey];
				}
				
				if (!page) {
					ExpoFileLogger.log(
						'debug', 
						`MainContainer.followContentLink domain item by key (${internalKey})`
					);

					const response = await fetchDomainItemByKey(state.settings.websiteSettings.domain, internalKey);
				
					if (response && response.item) {
						page = response.item;
					}
				}
			}

			if (page) {
				ExpoFileLogger.log(
					'debug', 
					`MainContainer.followContentLink page rootPath=${page.rootPath}`
				);

				dispatch({
					data: page,
					href: page.rootPath,
					type: AppActions.NAVIGATE
				});
			}
		}
	}, []);

    if (!state.settings || !state.navigation) {
        return null;
    }

	if (os.equals('web')) {
		if (isMobile || isTablet || isIPad) {
			return (
				<MobileView {...props}
				            followContentLink={followContentLink}
						    goBack={goBack}
						    onLayout={async() => await SplashScreen.hideAsync()}
							siteWrapper={siteWrapper}
				/>  
			);
		}
		else {
			return (
				<WebView {...props}
				         followContentLink={followContentLink}
				         goBack={goBack}
						 id='web-view'
						 siteWrapper={siteWrapper}
				/>
			);
		}
	}
	else {
		return (
			<MobileView {...props}
			            followContentLink={followContentLink}		
			            goBack={goBack}
			            onLayout={async() => await SplashScreen.hideAsync()}
						siteWrapper={siteWrapper}
			/>  
		);
	}
};

export default React.memo(MainContainer);
