import React from 'react'
import { View,StyleSheet } from "react-native";
import { useTheme } from "@rneui/themed";

const HorizontalRule = ({lineColor, lineOpacity, lineWidth, lineThickness}) => {
	const { theme } = useTheme();
	return (
		<View style={{
				  ...theme?.common?.HorizontalRule, 
				  alignSelf: 'center',
				  flexDirection: 'row', 
				  borderBottomColor: lineColor || theme?.colors?.grey2, 
				  opacity: lineOpacity || 0.5,
				  width: lineWidth || '95%',
				  borderBottomWidth:lineThickness || StyleSheet.hairlineWidth
			  }}
		/>	
	);
}
export default HorizontalRule;