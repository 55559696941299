import React, { useCallback, useEffect, useReducer, useRef } from 'react';
import { isEqual } from 'lodash';
import secureLocalStorage from 'react-secure-storage';
import * as ExpoFileLogger from 'expo-file-logger';

/**
 * For now I am including this only in the usePersistedState hook. In
 * the future we may want to pull it out into an exported hook.
 */
const usePreviousState = previousState => {
    const ref = useRef();

    useEffect(() => {
        ref.current = previousState;
    });

    return ref.current;
};

const usePersistedState = (key, initialState, reducer) => {
    const [state, dispatch] = useReducer(reducer, initialState, function () {
        const persistedState = secureLocalStorage.getItem(key);

        if (persistedState) {
            try {
                const obj = JSON.parse(persistedState);

                return {
                    ...initialState,
                    ...obj
                };
            } catch (e) {
                ExpoFileLogger.log('error', `Error deserializing state: ${e}`);
            }
        }

        return initialState;
    });

    const previousState = usePreviousState(state);

    const clear = useCallback(() => {
        secureLocalStorage.removeItem(key);
    }, [key]);

    useEffect(() => {
        if (!isEqual(previousState, state)) {
            secureLocalStorage.setItem(key, JSON.stringify(state));
        }
    }, [key, previousState, state]);

    return { clear, dispatch, state };
};

export default usePersistedState;
