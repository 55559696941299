import React, { useCallback, useEffect, useState }                                    from 'react';
import { useWindowDimensions, ActivityIndicator, Alert, AppState, Image, Platform }   from 'react-native';
import { setJSExceptionHandler, setNativeExceptionHandler }                           from 'react-native-exception-handler';
import { SafeAreaProvider }                                                           from 'react-native-safe-area-context';
import Animated, { FadeIn, FadeOut }                                                  from 'react-native-reanimated';
import { ThemeProvider }                                                              from '@rneui/themed';
import * as ExpoApplication                                                           from 'expo-application'
import { useAssets }                                                                  from 'expo-asset';
import Constants                                                                      from 'expo-constants';
import * as ExpoDevice                                                                from 'expo-device';
import * as ExpoFileLogger                                                            from 'expo-file-logger';
import * as Fonts                                                                     from 'expo-font';
import * as ScreenOrientation                                                         from 'expo-screen-orientation';
import * as SplashScreen                                                              from 'expo-splash-screen';
import Emarsys                                                                        from 'react-native-emarsys-wrapper';
// import * as Sentry                                                                    from 'sentry-expo';
import { parseNavigation }                                                            from './common/helpers/navigation';
import { AppContextProvider }                                                         from './contexts/app';
import { AuthContextProvider }                                                        from './contexts/auth';
import MainContainer                                                                  from './containers/main';
import { useApi, useDeviceInfo }                                                      from './hooks';
import { generateTheme }                                                              from './themes';
import ComponentMapping                                                               from './mapping';

if (!Platform.OS === 'web') {
    setJSExceptionHandler((e, isFatal) => {
        ExpoFileLogger.log(isFatal ? 'fatal': 'error', `Encountered JS exception: ${e.message}\n${e}`);
    
        if (isFatal) {
            if (!Platform.OS.equals('web')) {
                Alert.alert(
                    'Oops, an error occured', 
                    `
                        Error: ${e.name} ${e.message}
                        We have reported this to our team. Please close and restart the app.
                    `, 
                    [ { text: 'Close' } ]
                );
            }
        }
    });
    
    setNativeExceptionHandler(e => ExpoFileLogger.log('fatal', `Encountered Native exception: ${e.message}\n${e}`));
}

// if (Constants.manifest?.extra?.sentryDsn) {
//     Sentry.init({
//         dsn: Constants.manifest?.extra?.sentryDsn,
//         debug: true,
//         enableAppHangTracking: true,
//         enableInExpoDevelopment: false,
//         enableOutOfMemoryTracking: true,
//         tracesSampleRate: 1.0,
//     });
// }

SplashScreen.preventAutoHideAsync();

const App = () => 
{
    const { fetchStartupData }       = useApi();
    const { isNative }               = useDeviceInfo();
    const [ assets, error ]          = useAssets(require(`../assets/images/splash.png`));
    const [ globals, setGlobals ]    = useState();
    const { height, width }          = useWindowDimensions();
    const startPage                  = (Platform.OS.equals('web') ? window.location.pathname : isNative ? '/app' : '/');

    const loadFonts = useCallback(async () => {
        await Fonts.loadAsync({
            BarlowMedium:   require('../assets/fonts/BarlowCondensed-Medium.ttf'),
            BarlowRegular:  require('../assets/fonts/BarlowCondensed-Regular.ttf'),
            FrankRuhlLibre: require('../assets/fonts/FrankRuhlLibre.ttf'),
            GothamBook:     require('../assets/fonts/GothamBook.ttf'),
            GothamMedium:   require('../assets/fonts/GothamMedium.ttf'),
            Plantin:        require('../assets/fonts/Plantin.ttf')
        });
    }, []);

    useEffect(() => {
        let appStateChangeSubscription;
        let appStateMemoryWarnSubscription;

        const initialize = async () => {
            ExpoFileLogger.log(
                'debug', 
 `\n
          Device Info
 *******************************
 OS Version:        ${ExpoDevice.osVersion}
 OS Name:           ${ExpoDevice.osName}
 Brand:             ${ExpoDevice.brand}
 Model:             ${ExpoDevice.modelName}
 Model Year:        ${ExpoDevice.deviceYearClass}
 Total Memory:      ${ExpoDevice.totalMemory}
 App Version:       ${ExpoApplication.nativeApplicationVersion}
 App Build Version: ${ExpoApplication.nativeBuildVersion}
********************************
 `
            );

            ExpoFileLogger.log(
                'debug', 
                'Initializing application, Loading fonts ...'
            );
            
            await loadFonts();

            ExpoFileLogger.log(
                'debug', 
                'Fonts loaded successfully'
            );
            
            const tmpGlobals = await fetchStartupData(startPage);

            ExpoFileLogger.log(
                'debug', 
                'Startup data fetched, Parsing navigation ...'
            );

            if (tmpGlobals) {
                tmpGlobals.navigation = parseNavigation(tmpGlobals.navigation, tmpGlobals.settings.websiteSettings);
                setGlobals(tmpGlobals);
            }
            
            if (!Platform.OS.equals('web')) {
                ExpoFileLogger.log(
                    'debug', 
                    'Starting Emarsys event handler ...'
                );

                Emarsys.setEventHandler((eventName, payload) => {
                    ExpoFileLogger.log(
                        'debug', 
                        `Received ${eventName} from Emarsys: ${JSON.stringify(payload)}`
                    );
                 });
    
                ExpoFileLogger.log('debug', 'Emarsys event handler started.');

                ScreenOrientation.addOrientationChangeListener(e => {
                    ExpoFileLogger.log(
                        'debug', 
                        `ScreenOrientation changed to: ${e.orientationInfo.orientation}`
                    );
                });

                appStateChangeSubscription = AppState.addEventListener('change', state => {
                    ExpoFileLogger.log(
                        'debug', 
                        `AppState changed [${state}]`
                    );
                });

                appStateMemoryWarnSubscription = AppState.addEventListener('memoryWarning', e => {
                    ExpoFileLogger.log(
                        'warn', 
                        `AppState memoryWarning encountered: ${e}`
                    );
                });
            }

            ExpoFileLogger.log('debug', 'Initialization complete!');
        };

        initialize();

        return () => {
            appStateChangeSubscription?.remove();
            appStateMemoryWarnSubscription?.remove();
            ScreenOrientation?.removeOrientationChangeListeners();
        }
    }, [ startPage ]);

    if (!globals) {
        if (Platform.OS.equals('web')) {
            if (assets && !error) {
                return (
                    <Animated.View  entering={FadeIn}
                                    exiting={FadeOut}
                                    style={{
                                        alignItems: 'center',
                                        backgroundColor: Constants.manifest.extra?.splashScreenColor,
                                        height: height,
                                        justifyContent: 'center',
                                        width: width
                                    }}
                    >
                        <Image source={assets[0]}
                               style={{ 
                                   color: '#ffffff', 
                                   height: 200, 
                                   marginBottom: 10,
                                   width: 400
                                }}
                        />
                        <ActivityIndicator color='#ffffff' size='large' />
                    </Animated.View>
                );
            }
        }
        else {
            return null;
        }
    }

    return (
        <ThemeProvider theme={generateTheme(Constants.manifest.extra?.appTheme)}>
            <AppContextProvider globals={globals}>
                <AuthContextProvider>
                    <SafeAreaProvider>
                        <MainContainer componentMapping={ComponentMapping} />
                    </SafeAreaProvider>
                </AuthContextProvider>
            </AppContextProvider>
        </ThemeProvider>
    );
};

export default App;
