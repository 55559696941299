export const getText = () => {
    return {
        H1: {
            fontSize: 48,
        },
        H2: {
            fontSize: 44,
        },
        H3: {
            fontSize: 40,
        },
        H4: {
            fontSize: 36,
        },
        H5: {
            fontSize: 32,
        },
        H6: {
            fontSize: 28,
        }
    };    
};
