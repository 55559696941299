import React, { useContext, useState } from 'react';
import { View } from 'react-native';
import { useTheme, Button, Icon, Input, Text } from '@rneui/themed';
import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import Loading from '../../Loading';
import Utils from '../../../../common/utils';
import AppContext from '../../../../contexts/app';
import RegistrationContext, { Actions } from '../../../../contexts/registration';
import { useApi, useDeviceInfo } from '../../../../hooks';

const RegistrationStep1 = ({ control, getValues, isValid, setError }) => {
    const { post } = useApi();
    const { state } = useContext(AppContext);
    const { dispatch } = useContext(RegistrationContext);
    const { isMobile } = useDeviceInfo();
    const [confirming, setConfirming] = useState(false);
    const { theme } = useTheme();

    const handleConfirmAndVerifyEmail = async () => {
        const emailAddress = getValues('email');

        if (emailAddress) {
            setConfirming(current => !current);

            let endpoint = `${state.settings.openIdSettings.authority}/Account/ValidateEmailAddressIsAvailableAndNotGei`;
            let response = await post(endpoint, { email: emailAddress });

            if (response?.valid) {
                endpoint = `${state.settings.openIdSettings.authority}/Account/SendVerificationEmail`;
                response = await post(endpoint, { email: emailAddress, domain: state.settings.websiteSettings.domain });

                if (response?.success) {
                    dispatch({
                        type: Actions.SET_EMAIL_CONFIRM_ID,
                        emailConfirmationCodeId: response.confirmationCodeId,
                        resendCode: false
                    });
                } else {
                    setError('email', {
                        type: 'custom',
                        message: response?.message || 'Unable to confirm your email address. Please check your code and try again.'
                    });
                }
            } else {
                setError('email', {
                    type: 'custom',
                    message: response?.message || 'Unable to verify your email address. Please check and try again.'
                });
            }
        }

        setConfirming(_ => false);
    };

    return (
        <View style={{ width: '100%' }} testID='Registration.Step1'>
            <View style={{ flexDirection: 'row', marginHorizontal: theme?.margins?.gutter }}>
                <Text style={theme?.appComponents?.common?.Authentication?.Registration?.introductionText}>
                    Please provide some information so that we can get your account setup.
                </Text>
            </View>
            <View
                style={{
                    flexDirection: isMobile ? 'column' : 'row',
                    justifyContent: 'space-around',
                    marginHorizontal: theme?.margins?.inset1,
                    marginTop: 20
                }}
            >
                <View style={{ marginBottom: isMobile ? 20 : 0 }}>
                    <Icon
                        color={theme?.colors?.grey2}
                        containerStyle={{ overflow: 'visible' }}
                        name='user-circle'
                        size={isMobile ? 50 : 100}
                        type='font-awesome-5'
                    />
                    <Text style={theme.appComponents?.common?.Authentication?.Registration?.introductionIconText}>
                        24/7 Access to Account Information
                    </Text>
                </View>
                <View>
                    <Icon
                        color={theme?.colors?.grey2}
                        containerStyle={{ overflow: 'visible' }}
                        name='shopping-cart'
                        size={isMobile ? 50 : 100}
                        type='font-awesome-5'
                    />
                    <Text style={theme.appComponents?.common?.Authentication?.Registration?.introductionIconText}>
                        View Contribution & Purchase History
                    </Text>
                </View>
            </View>
            <View style={{ flexDirection: 'row', width: '100%' }}>
                <Controller
                    control={control}
                    defaultValue=''
                    name='email'
                    render={({ field, fieldState }) => {
                        return (
                            <Input
                                {...field}
                                containerStyle={{ width: '100%', marginVertical: 20 }}
                                errorMessage={!fieldState.error ? 'EMAIL ADDRESS' : fieldState.error?.message}
                                errorStyle={
                                    !fieldState.error
                                        ? theme?.appComponents?.common?.Authentication?.Registration?.placeholderStyle
                                        : theme?.appComponents?.common?.Authentication?.Registration?.errorStyle
                                }
                                innerRef={field.ref}
                                inputStyle={theme?.appComponents?.common?.Authentication?.Registration?.smallInputStyle}
                                onChangeText={val => field.onChange(val)}
                                textContentType='emailAddress'
                                spellCheck={false}
                            />
                        );
                    }}
                    rules={{
                        required: {
                            message: 'Email is required',
                            value: true
                        },
                        pattern: {
                            message: 'Not a valid email address',
                            value: Utils.ValidEmailRegExp
                        }
                    }}
                />
            </View>
            <Button
                buttonStyle={theme?.appComponents?.common?.Authentication?.Registration?.stepButton}
                containerStyle={theme?.appComponents?.common?.Authentication?.Registration?.stepButtonContainer}
                disabled={!isValid}
                disabledStyle={theme?.appComponents?.common?.Authentication?.Registration?.disabledStepButton}
                disabledTitleStyle={theme?.appComponents?.common?.Authentication?.Registration?.disabledStepButtonTitle}
                onPress={() => handleConfirmAndVerifyEmail()}
                title='NEXT STEP'
                titleStyle={theme?.appComponents?.common?.Authentication?.Registration?.stepButtonTitle}
                type='solid'
            />
            {confirming && <Loading />}
        </View>
    );
};

RegistrationStep1.propTypes = {
    control: PropTypes.object.isRequired,
    getValues: PropTypes.func.isRequired,
    isValid: PropTypes.bool.isRequired,
    setError: PropTypes.func.isRequired
};

export default RegistrationStep1;
