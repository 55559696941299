import Common              from '../common';
import ThemeColors         from './colors';
import { getComponents }   from './components';
import { getMargins }      from './margins';
import { getNavigation }   from './navigation' 
import { getText }         from './text';

export default () => {
    return {
        colors: ThemeColors,
        common: Common,
        appComponents: getComponents(),
        iconKit: 'font-awesome-5',
        margins: getMargins(),
        navigation: getNavigation(),
        splashScreenColor: '#0085c5',
        text: getText()
    };
};