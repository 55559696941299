import React, { useContext, useEffect, useState } from 'react';
import { View } from 'react-native';
import { useTheme, Button, Input, Overlay, Text } from '@rneui/themed';
import { useForm, Controller } from 'react-hook-form';
import { Alert, Loading } from '../../common';
import Utils from '../../../common/utils';
import AppContext from '../../../contexts/app';
import { useApi, useDeviceInfo } from '../../../hooks';

const SUPPORTED_METHODS = Object.freeze(['EMAIL', 'TEXT']);

const PasswordReset = ({ setVisible, visible }) => {
    const { fetch } = useApi();
    const { state } = useContext(AppContext);
    const { isMobile, os } = useDeviceInfo();
    const [step, setStep] = useState(1);
    const [confirmationCodeId, setConfirmationCodeId] = useState();
    const [resetMethod, setResetMethod] = useState(SUPPORTED_METHODS[0]);
    const { theme } = useTheme();

    const {
        clearErrors,
        control,
        formState: { isSubmitting, isValid },
        getValues,
        handleSubmit,
        reset,
        setError
    } = useForm({
        mode: 'onBlur'
    });

    const handleResetPassword = async () => {
        if (resetMethod.equals(SUPPORTED_METHODS[0])) {
            const email = getValues('email');
            const domain = state.settings.websiteSettings?.domain;

            if (!email || !domain) {
                <Alert message='Something has gone wrong. Please try again' title='Sorry' />;
                return;
            }

            const response = await fetch(
                `${state.settings.openIdSettings.authority}/Account/SendResetPasswordEmail?email=${email}&domain=${domain}`,
                null,
                'POST'
            );

            if (response?.success) {
                setConfirmationCodeId(response.confirmationCodeId);
                setStep(2);
            } else {
                <Alert message='Something has gone wrong. Please try again' title='Sorry' />;
            }
        } else if (resetMethod.equals(SUPPORTED_METHODS[1])) {
            const phone = getValues('mobileNumber');

            if (!phone) {
                <Alert message='Something has gone wrong. Please try again' title='Sorry' />;
                return;
            }

            const response = await fetch(
                `${state.settings.openIdSettings.authority}/Account/SendResetPasswordText?phone=${phone}`,
                null,
                'POST'
            );

            if (response?.success) {
                setConfirmationCodeId(response.confirmationCodeId);
                setStep(2);
            } else {
                <Alert message='Something has gone wrong. Please try again' title='Sorry' />;
            }
        }
    };

    const handleConfirmation = async () => {
        const confirmationCode = getValues('confirmCode');

        if (confirmationCode && confirmationCodeId) {
            const response = await fetch(
                `${state.settings.openIdSettings.authority}/Account/VerifyConfirmationCode?confirmationCodeId=${confirmationCodeId}&confirmationCode=${confirmationCode}`,
                null,
                'POST'
            );

            if (!response?.success) {
                setError('confirmCode', {
                    type: 'custom',
                    message: response?.errorMessage || 'Something went wrong. Please try again'
                });
            } else {
                setStep(3);
            }
        }
    };

    const handleCompleteReset = async () => {
        const password = getValues('password');
        let endpoint = `${state.settings.openIdSettings.authority}/Account/ResetPassword?password=${password}`;

        if (resetMethod === SUPPORTED_METHODS[0]) {
            endpoint += `&email=${getValues('email')}`;
        } else {
            endpoint += `&phone=${getValues('mobileNumber')}`;
        }

        const response = await fetch(endpoint, null, 'POST');

        if (!response?.success) {
            setError('confirmPassword', {
                type: 'custom',
                message: response?.errorMessage || 'Something went wrong. Please try again.'
            });
        } else {
            setVisible(false);
        }
    };

    const handleResendCode = async () => {
        let endpoint = state.settings.openIdSettings.authority;

        if (resetMethod === SUPPORTED_METHODS[0]) {
            endpoint += `/Account/SendVerificationEmail?email=${getValues('email')}&domain=${state.settings.websiteSettings?.domain}`;
        } else {
            endpoint += `/Account/SendVerificationText?phone=${getValues('mobileNumber')}`;
        }

        const response = await fetch(endpoint, null, 'POST');

        if (!response?.success) {
            setError('confirmCode', {
                type: 'custom',
                message: response?.errorMessage || 'Something went wrong. Please try again.'
            });
        } else {
            setConfirmationCodeId(response?.confirmationCodeId);
        }
    };

    useEffect(() => {
        return () => {
            clearErrors(['email', 'mobileNumber', 'confirmCode', 'password', 'confirmPassword']);
            reset();
        };
    }, [clearErrors, reset]);

    return (
        <Overlay
            backdropStyle={{ opacity: 0.5 }}
            isVisible={visible}
            onBackdropPress={() => setVisible(false)}
            overlayStyle={{ minHeight: '40%', minWidth: '40%', padding: 20 }}
            testID='Authentication.PasswordReset.Overlay'
        >
            <View style={{ borderColor: theme.colors?.grey3, borderRadius: 2, borderWidth: 1, flex: 4 }}>
                <View style={{ flex: 1, flexDirection: 'row' }}>
                    <Button
                        buttonStyle={{
                            backgroundColor: resetMethod.equals(SUPPORTED_METHODS[0]) ? theme?.colors?.navy : theme?.colors?.grey4,
                            borderColor: theme?.colors?.navy,
                            borderWidth: 1
                        }}
                        containerStyle={{ flex: 1 }}
                        onPress={() => setResetMethod(SUPPORTED_METHODS[0])}
                        title='RESET VIA EMAIL'
                        titleStyle={{
                            ...theme?.text?.H5,
                            color: resetMethod.equals(SUPPORTED_METHODS[0]) ? theme?.colors?.white : theme?.colors?.navy,
                            padding: 5
                        }}
                        type='solid'
                    />
                    <Button
                        buttonStyle={{
                            backgroundColor: resetMethod.equals(SUPPORTED_METHODS[1]) ? theme?.colors?.navy : theme?.colors?.grey4,
                            borderColor: theme?.colors?.navy,
                            borderWidth: 1
                        }}
                        containerStyle={{ flex: 1 }}
                        onPress={() => setResetMethod(SUPPORTED_METHODS[1])}
                        title='RESET VIA TEXT'
                        titleStyle={{
                            ...theme?.text?.H5,
                            color: resetMethod.equals(SUPPORTED_METHODS[1]) ? theme?.colors?.white : theme?.colors?.navy,
                            padding: 5
                        }}
                        type='solid'
                    />
                </View>
                {!step ||
                    (step === 1 && (
                        <>
                            <View style={{ flex: 1 }}>
                                {
                                    /* Reset Via Email */
                                    resetMethod.equals(SUPPORTED_METHODS[0]) && (
                                        <Controller
                                            control={control}
                                            defaultValue=''
                                            name='email'
                                            render={({ field, fieldState }) => {
                                                return (
                                                    <Input
                                                        {...field}
                                                        autoCorrect={false}
                                                        autoFocus={false}
                                                        errorMessage={!fieldState.error ? 'EMAIL' : fieldState.error?.message}
                                                        errorStyle={
                                                            !fieldState.error
                                                                ? theme?.appComponents?.common?.Authentication?.PasswordReset?.placeholderText
                                                                : theme?.appComponents?.common?.Authentication?.PasswordReset?.errorText
                                                        }
                                                        innerRef={field.ref}
                                                        inputStyle={{ outlineStyle: 'none' }}
                                                        textContentType='emailAddress'
                                                    />
                                                );
                                            }}
                                            rules={{
                                                required: {
                                                    message: 'Field is required',
                                                    value: true
                                                },
                                                pattern: {
                                                    message: 'Invalid email',
                                                    value: Utils.ValidEmailRegExp
                                                }
                                            }}
                                        />
                                    )
                                }
                                {
                                    /* Reset Via Text */
                                    resetMethod.equals(SUPPORTED_METHODS[1]) && (
                                        <Controller
                                            control={control}
                                            defaultValue=''
                                            name='mobileNumber'
                                            render={({ field, fieldState }) => {
                                                return (
                                                    <Input
                                                        {...field}
                                                        errorMessage={!fieldState.error ? 'MOBILE PHONE' : fieldState.error?.message}
                                                        errorStyle={
                                                            !fieldState.error
                                                                ? theme?.appComponents?.common?.Authentication?.PasswordReset?.placeholderText
                                                                : theme?.appComponents?.common?.Authentication?.PasswordReset?.errorText
                                                        }
                                                        innerRef={field.ref}
                                                        inputStyle={theme?.appComponents?.common?.Authentication?.PasswordReset?.smallInput}
                                                        onChangeText={val => field.onChange(val)}
                                                        textContentType='telephoneNumber'
                                                        spellCheck={false}
                                                    />
                                                );
                                            }}
                                            rules={{
                                                required: {
                                                    message: 'Field is required',
                                                    value: true
                                                },
                                                pattern: {
                                                    message: 'Invalid phone number',
                                                    value: Utils.ValidPhoneNumberRegExp
                                                }
                                            }}
                                        />
                                    )
                                }
                            </View>
                            <View style={{ flex: 1, justifyContent: 'flex-end' }}>
                                <Button
                                    buttonStyle={{
                                        ...theme.appComponents?.common?.Authentication.PasswordReset.nextButton,
                                        backgroundColor: isValid ? theme?.colors?.navy : theme?.colors?.grey4
                                    }}
                                    containerStyle={{ marginHorizontal: '5%' }}
                                    onPress={handleSubmit(handleResetPassword)}
                                    size='md'
                                    title='NEXT'
                                    titleStyle={{
                                        ...theme.appComponents?.common?.Authentication?.PasswordReset.nextButtonTitle,
                                        color: isValid ? theme?.colors?.white : theme?.colors?.navy
                                    }}
                                    type='solid'
                                />
                                <View style={{ flexDirection: 'row', justifyContent: 'center', marginVertical: 20 }}>
                                    <Text
                                        style={theme.appComponents?.common?.Authentication?.PasswordReset.alternateActionText}
                                        onPress={() => setVisible(false)}
                                    >
                                        Cancel
                                    </Text>
                                </View>
                            </View>
                        </>
                    ))}
                {step === 2 && (
                    <>
                        <View style={{ flex: 1 }}>
                            <Text style={theme.appComponents?.common?.Authentication?.PasswordReset?.introductionText}>
                                If an account exists with this
                                {resetMethod.equals(SUPPORTED_METHODS[0])
                                    ? ' email address you will receive an email '
                                    : ' phone number you will receive an text '}
                                with a confirmation code.
                            </Text>
                            <Controller
                                control={control}
                                defaultValue=''
                                name='confirmCode'
                                render={({ field, fieldState }) => {
                                    return (
                                        <Input
                                            {...field}
                                            autoCorrect={false}
                                            autoFocus={false}
                                            errorMessage={!fieldState.error ? 'CONFIRMATION CODE' : fieldState.error?.message}
                                            errorStyle={
                                                !fieldState.error
                                                    ? theme?.appComponents?.common?.Authentication?.PasswordReset?.placeholderText
                                                    : theme?.appComponents?.common?.Authentication?.PasswordReset?.errorText
                                            }
                                            innerRef={field.ref}
                                            inputStyle={{ outlineStyle: 'none' }}
                                            textContentType='oneTimeCode'
                                        />
                                    );
                                }}
                                rules={{
                                    required: {
                                        message: 'Field is required',
                                        value: true
                                    }
                                }}
                            />
                        </View>
                        <View
                            style={{
                                flex: 1,
                                justifyContent: 'flex-end',
                                marginTop: os.equals('web') && isMobile ? '10pt' : os.equals('web') && !isMobile ? '10%' : 10
                            }}
                        >
                            <Button
                                buttonStyle={{
                                    ...theme.appComponents?.common?.Authentication.PasswordReset.nextButton,
                                    backgroundColor: isValid ? theme?.colors?.navy : theme?.colors?.grey4
                                }}
                                containerStyle={{ marginHorizontal: '5%' }}
                                onPress={handleSubmit(handleConfirmation)}
                                size='md'
                                title='NEXT'
                                titleStyle={{
                                    ...theme.appComponents?.common?.Authentication?.PasswordReset.nextButtonTitle,
                                    color: isValid ? theme?.colors?.white : theme?.colors?.navy
                                }}
                                type='solid'
                            />
                            <View style={{ flexDirection: 'row', justifyContent: 'center', marginVertical: isMobile ? 10 : '10pt' }}>
                                <Text
                                    style={{
                                        ...theme.appComponents?.common?.Authentication?.PasswordReset.alternateActionText,
                                        color: theme.colors.navy,
                                        marginRight: 10
                                    }}
                                    onPress={handleSubmit(handleResendCode)}
                                >
                                    Resend Code
                                </Text>
                                <Text
                                    style={theme.appComponents?.common?.Authentication?.PasswordReset.alternateActionText}
                                    onPress={() => setVisible(false)}
                                >
                                    Cancel
                                </Text>
                            </View>
                        </View>
                    </>
                )}
                {step === 3 && (
                    <>
                        <View style={{ flex: 1 }}>
                            <Controller
                                control={control}
                                defaultValue=''
                                name='password'
                                render={({ field, fieldState }) => {
                                    return (
                                        <Input
                                            {...field}
                                            errorMessage={!fieldState.error ? 'CREATE A PASSWORD' : fieldState.error?.message}
                                            errorStyle={
                                                !fieldState.error
                                                    ? theme?.appComponents?.common?.Authentication?.Registration?.placeholderStyle
                                                    : theme?.appComponents?.common?.Authentication?.Registration?.errorStyle
                                            }
                                            innerRef={field.ref}
                                            inputStyle={theme?.appComponents?.common?.Authentication?.Registration?.smallInputStyle}
                                            onChangeText={val => field.onChange(val)}
                                            secureTextEntry={true}
                                            spellCheck={false}
                                            textContentType='newPassword'
                                        />
                                    );
                                }}
                                rules={{
                                    required: {
                                        message: 'Create a password is required',
                                        value: true
                                    },
                                    pattern: Utils.ValidPasswordRegExp
                                }}
                            />
                            <Controller
                                control={control}
                                defaultValue=''
                                name='confirmPassword'
                                render={({ field, fieldState }) => {
                                    return (
                                        <Input
                                            {...field}
                                            errorMessage={!fieldState.error ? 'CONFIRM PASSWORD' : fieldState.error?.message}
                                            errorStyle={
                                                !fieldState.error
                                                    ? theme?.appComponents?.common?.Authentication?.Registration?.placeholderStyle
                                                    : theme?.appComponents?.common?.Authentication?.Registration?.errorStyle
                                            }
                                            innerRef={field.ref}
                                            inputStyle={theme?.appComponents?.common?.Authentication?.Registration?.smallInputStyle}
                                            onChangeText={val => field.onChange(val)}
                                            secureTextEntry={true}
                                            textContentType='newPassword'
                                            spellCheck={false}
                                        />
                                    );
                                }}
                                rules={{
                                    required: {
                                        message: 'Confirm password is required',
                                        value: true
                                    },
                                    pattern: Utils.ValidPasswordRegExp,
                                    validate: () => {
                                        const password = getValues('password');
                                        const confirmPassword = getValues('confirmPassword');

                                        return confirmPassword === password;
                                    }
                                }}
                            />
                        </View>
                        <View
                            style={{
                                flex: 1,
                                justifyContent: 'flex-end',
                                marginTop: os.equals('web') && isMobile ? '10pt' : os.equals('web') && !isMobile ? '10%' : 10
                            }}
                        >
                            <Button
                                buttonStyle={{
                                    ...theme.appComponents?.common?.Authentication.PasswordReset.nextButton,
                                    backgroundColor: isValid ? theme?.colors?.navy : theme?.colors?.grey4
                                }}
                                containerStyle={{ marginHorizontal: '5%' }}
                                onPress={handleSubmit(handleCompleteReset)}
                                size='md'
                                title='RESET PASSWORD'
                                titleStyle={{
                                    ...theme.appComponents?.common?.Authentication?.PasswordReset.nextButtonTitle,
                                    color: isValid ? theme?.colors?.white : theme?.colors?.navy
                                }}
                                type='solid'
                            />
                            <View style={{ flexDirection: 'row', justifyContent: 'center', marginVertical: isMobile ? 10 : '10pt' }}>
                                <Text
                                    style={theme.appComponents?.common?.Authentication?.PasswordReset.alternateActionText}
                                    onPress={() => setVisible(false)}
                                >
                                    Cancel
                                </Text>
                            </View>
                        </View>
                    </>
                )}
                {isSubmitting && <Loading />}
            </View>
        </Overlay>
    );
};

export default PasswordReset;
