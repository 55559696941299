import { Dimensions, Platform } from 'react-native';
import ThemeColors from './colors';
import { dynamicFontSize, dynamicFontFamily, dynamicLineHeight, dynamicMarginSize, dynamicLetterSpacing } from '../common';

export const getText = () => {
    const { width } = Dimensions.get('window');
    const isMobile = width <= 480;

    return {
        General: {
            fontFamily: 'GothamBook',
            fontSize: 18,
            letterSpacing: 0,
            lineHeight: 20
        },
        ComponentTitle: {
            color: ThemeColors.textBlackColor,
            fontFamily: dynamicFontFamily('GothamBook'),
            fontSize: dynamicFontSize(36),
            lineHeight: dynamicLineHeight(48, dynamicFontSize(36)),
            marginLeft: dynamicMarginSize(30)
        },
        SmallText: {
            color: ThemeColors.textBlackColor,
            fontFamily: 'GothamBook',
            fontSize: 12
        },
        H1: {
            color: ThemeColors.textBlackColor,
            fontFamily: dynamicFontFamily('GothamBook'),
            fontSize: dynamicFontSize(36),
            letterSpacing: 0,
            lineHeight: dynamicLineHeight(48, dynamicFontSize(36))
        },

        H2: {
            color: ThemeColors.textBlackColor,
            fontFamily: dynamicFontFamily('GothamBook'),
            fontSize: dynamicFontSize(30),
            letterSpacing: 0,
            lineHeight: dynamicLineHeight(36, dynamicFontSize(30))
        },

        H3: {
            color: ThemeColors.textBlackColor,
            fontFamily: dynamicFontFamily('GothamBook'),
            fontSize: dynamicFontSize(24),
            letterSpacing: 0,
            lineHeight: dynamicLineHeight(30, dynamicFontSize(24))
        },

        H4: {
            color: ThemeColors.textBlackColor,
            fontFamily: dynamicFontFamily('GothamBook'),
            fontSize: dynamicFontSize(18),
            letterSpacing: 0,
            lineHeight: dynamicLineHeight(28, dynamicFontSize(18))
        },

        H5: {
            color: ThemeColors.textBlackColor,
            fontFamily: dynamicFontFamily('GothamBook'),
            fontSize: dynamicFontSize(14),
            letterSpacing: 0,
            lineHeight: dynamicLineHeight(23, dynamicFontSize(14))
        },

        H6: {
            color: ThemeColors.textBlackColor,
            fontFamily: dynamicFontFamily('GothamBook'),
            fontSize: dynamicFontSize(11),
            letterSpacing: 2 + 'pt',
            lineHeight: dynamicLineHeight(15, dynamicFontSize(11)),
            textTransform: 'uppercase'
        },

        Paragraph: {
            color: ThemeColors.textBlackColor,
            fontFamily: dynamicFontFamily('GothamBook'),
            fontSize: dynamicFontSize(16),
            lineHeight: dynamicLineHeight(18, dynamicFontSize(16)),
            letterSpacing: dynamicLetterSpacing(dynamicFontSize(16)),
            marginHorizontal: 40
        },
        RichTextParagraph: {
            color: ThemeColors.textPlaceholderColor,
            fontFamily: dynamicFontFamily('FrankRuhlLibre'),
            fontSize: dynamicFontSize(18),
            lineHeight: dynamicLineHeight(22, dynamicFontSize(18)),
            letterSpacing: dynamicLetterSpacing(dynamicFontSize(18)),
            marginVertical: dynamicMarginSize(10),
            textAlign: 'left'
        },
        ParagraphSmall: {
            color: ThemeColors.textBlackColor,
            fontFamily: dynamicFontFamily('GothamBook'),
            fontSize: dynamicFontSize(12),
            lineHeight: dynamicLineHeight(18, dynamicFontSize(12)),
            letterSpacing: dynamicLetterSpacing(dynamicFontSize(12)),
            margin: 0
        },

        PrimaryText: {
            fontFamily: 'GothamBook',
            color: ThemeColors.textPrimaryColor
        },

        SecondaryText: {
            fontFamily: 'GothamBook',
            color: ThemeColors.textSecondaryColor
        },

        TextBlack: {
            fontFamily: 'GothamBook',
            color: ThemeColors.textBlackColor
        },

        TextActive: {
            fontFamily: 'GothamBook',
            color: ThemeColors.textActiveColor
        },

        TextInfo: {
            fontFamily: 'GothamBook',
            color: ThemeColors.textInfoColor
        },

        TextSuccess: {
            fontFamily: 'GothamBook',
            color: ThemeColors.textSuccessColor
        },

        TextWarning: {
            fontFamily: 'GothamBook',
            color: ThemeColors.textWarningColor
        },

        TextError: {
            fontFamily: 'GothamBook',
            color: ThemeColors.textErrorColor
        }
    };
};
