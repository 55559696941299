import React, { useContext, useRef }   from 'react';
import { Pressable, View }             from 'react-native';
import { useTheme, Text, Icon }        from '@rneui/themed';
import { get, isNumber, pickBy }       from 'lodash';
import CornerBlock                     from '../../umbraco/CornerBlock';
import { findActive }                  from '../../../common/helpers/navigation';
import AppContext                      from '../../../contexts/app';
import useApi                          from '../../../hooks/useApi';

const SecondaryNav = props =>
{
	const { fetchPage }          = useApi();
	const { state, dispatch }    = useContext(AppContext);
    const { theme }              = useTheme();
    const activeChild            = useRef(findActive(state?.action?.href || window.location.pathname, 1));
    const cornerblock            = get(state?.action?.data?.page, 'cornerBlock') || 
									get(state?.action?.data?.references, state?.action?.data?.page?.sourceKeys[0]);
    const isTertiaryVisible      = state?.navigation?.tertiary && state?.navigation?.hasOwnProperty(activeChild.current);								  

	if (!state?.navigation?.secondary || state?.action?.href?.equals('/')) {
        return null;
    }

    const renderMenuItem  = item => {
	    if (item.doNotIncludeInNavigation) {
		    return null;
	    }

		const handlePress = async e => {
			e.preventDefault();
            activeChild.current = item.urlName;
	        await fetchPage(dispatch, item.rootPath);
        };
		
		const handleHover = (e, isIn) => {
			const target = e?.nativeEvent?.currentTarget;

			if (target) {
				let newStyles = {
					...target.style,
					backgroundColor: isIn ? 'rgba(107, 4, 16, 0.85)' : undefined
				}

				target.setNativeProps({ 
					style: pickBy(newStyles, (_, k) => isNaN(k))
				});

				const titleElement = target.children[0]?.children[0];

				if (titleElement) {
					newStyles = {
						...titleElement.style,
						color: isIn ? theme?.colors?.white : theme?.colors?.navy
					};

					titleElement.setNativeProps({
						style: pickBy(newStyles, (_, k) => isNaN(k))
					});
				}

				const iconElement = target.children[0].children[1].children[0].children[0].children[0];

				if (iconElement) {
					newStyles = {
						...iconElement,
						color: isIn ? theme?.colors?.white : theme?.colors?.navy
					};

					iconElement.setNativeProps({
						style: pickBy(newStyles, (_, k) => isNaN(k))
					})
				}
			}
		};

		const isActive  = activeChild?.current?.equals(item.urlName);

		return (
				<Pressable key={item.id}
				           onHoverIn={e => handleHover(e, true)}
						   onHoverOut={e => handleHover(e, false)}
				           onPress={e => handlePress(e)}
						   style={{ 
							    backgroundColor: isActive ? theme?.colors?.puce : theme?.colors?.white,
						   	    borderBottomColor: theme?.colors?.grey3, 
						   	    borderBottomWidth: 1.25,
						   	    cursor: 'pointer',
						   	    flex: 1
						   }}
						   testID={`Secondary.NavItem.${item.title}`}
				> 
					<View style={{  
							alignItems: 'center', 
							flex: 1, 
							flexDirection: 'row',
							justifyContent: 'space-between'
						}}
					>
						<Text style={{
								color: isActive ? theme?.colors?.white : theme?.colors?.navy,
								fontFamily: 'GothamBook',
								fontSize: 20,
								marginLeft: 40
							}}
							numberOfLines={1}
							testID='Secondary.NavItem.Text'
						>
							{ item.title }
						</Text>
						{
							!isTertiaryVisible &&
								<Icon color='transparent'
									  name='chevron-right-circle-outline'
									  reverse={true}
									  reverseColor={isActive ? theme?.colors?.white : theme?.colors?.navy}
									  type='material-community'
									  testID='Secondary.NavItem.Icon'
								/>							
						}  
					</View>
				</Pressable>
		);
    };

    const activeSecondary  = state?.navigation?.secondary[findActive(state?.action.href || window.location.pathname)];

	if (!activeSecondary) {
		return null;
	}

    return (
		<View style={{
			    borderRightColor: props.isCollapsed ? theme?.colors?.grey2 : undefined,
				borderRightWidth: props.isCollapsed ? 0.75 : 0,
				flex: 1, 
				overflowY: 'hidden', 
				...theme?.common?.Col
			  }} 
			  testID='secondaryNavContainer'
		>
			<View style={{flex: 0.6 }}>
				{ activeSecondary.map(item => renderMenuItem(item, activeSecondary.length)) }
			</View>
			{
				cornerblock &&
					<View style={{flex: 0.4}}>
						<CornerBlock followContentLink={props.followContentLink}
									key={cornerblock.id} 
									json={cornerblock} 
									renderSingle={props.isCollapsed} 
									{...props} 
						/>
					</View>
			}
		</View>
	);
};

export default SecondaryNav;
