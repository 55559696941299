import React, { useContext, useEffect, useState }   from 'react';
import { Linking, FlatList, View }                  from 'react-native';
import Constants                                    from 'expo-constants';
import { useTheme, Button, ListItem, Text, Image }  from '@rneui/themed';
import axios                                        from 'axios';
import PropTypes                                    from 'prop-types';
import ViewMore                                     from './ViewMore';
import AppConstants                                 from '../../common/constants';
import AppContext, { AppActions, AppEvents }        from '../../contexts/app'; 
import { useApi, useDeviceInfo }                    from '../../hooks';

const SearchResults = props => {

    const { state, dispatch }           = useContext(AppContext);
    const { fetchPage }                 = useApi();
    const { os }                        = useDeviceInfo();
    const [ results, setResults ]       = useState();
    const [ pageNumber, setPageNumber ] = useState(1);
    const { theme }                     = useTheme();

    const close = () => {
        props?.searchRef?.current?.close();

        dispatch({
            type: AppActions.SEARCH_STATE_CHANGE,
            event: AppEvents.SEARCH_QUERY_CLOSED
        });
    };

    const renderHeading = () => 
    {
        return (
            <View style={{ flexDirection: 'row', marginVertical: theme?.margins?.gutter }}>
                <View>
                    <Text style={theme?.text?.H3}>
                        Search Results
                    </Text>
                    <Text style={[theme?.text?.General, { fontSize: 16, marginBottom: 20 }]}>
                        {
                            results?.totalHits <= 0 
                                ? 'Displaying 0 of 0 results'
                                : results?.totalHits < 10
                                    ? `Displaying ${pageNumber} - ${results?.totalHits} results`
                                    : `Displaying ${pageNumber} - ${10 * pageNumber} of ${results?.totalHits}`
                        }
                    </Text>
                </View>
                <Button icon={{
                                color: theme?.colors?.puce,
                                name: 'times',
                                size: 20,
                                type: 'font-awesome-5'
                              }}
                        iconContainerStyle={{paddingHorizontal:2,}}
                        onPress={close}
                        type='clear'
                />
            </View>
        );
    };
// TODO solve handle press domain resolution
    const renderHit = (hit, index) => {
        const { url, title, highlight, images } = hit.item;
        const handlePress = async () => {
            if (url) {
                if (url.indexOf('http') === -1) {
                    const content = await fetchContent(url);

                    if (content) {
                        await fetchPage(dispatch, content.rootPath);
                    }
                }
                else {
                    Linking.openURL(url);
                }
            }
        };

        return (
            <ListItem key={index} style={{ flex: 1 }}>
                {
                    images?.main &&
                        <Image containerStyle={{ marginRight: 5}}
                               source={{uri: images.main}}
                               style={{height: 150, width: 150}}
                        />
                }
                <ListItem.Content style={{...theme?.common?.Col}}>
                    <Text   h4={true} h4Style={[theme?.text?.H4, { marginBottom: 5 }]}
                        >
                        {title}
                    </Text>
                    <Text  style={{...theme?.text?.General, color:theme?.colors?.grey,  fontSize: 15 }}
                        >
                        { highlight.replace(/(<([^>]+)>)/ig, '') }
                    </Text>
                    <Button title='Read More...' 
                            buttonStyle={{paddingLeft:0}}
                            onPress={() => handlePress(url)}
                            titleStyle={theme?.text?.Navy} 
                            type='clear'
                    />
                </ListItem.Content>
            </ListItem>
        );
    };

    useEffect(() => {
        if (state.search?.event?.equals(AppEvents.SEARCH_QUERY_SUBMITTED)) {
            const term = state.search?.term;

            if (!term?.equals(props.query)) {
                (async () => {
                    const endpoint = `${Constants.manifest.extra.baseUrl}${AppConstants.REACT_APP_SEARCH_BASE}${term}&pageNumber=${pageNumber}`;
                    const response = await axios.get(endpoint);

                    if (response?.data) {
                        dispatch({
                            type: AppActions.HAMBURGER_STATE_CHANGE,
                            state: AppEvents.HAMBURGER_STATE_CLOSED
                        });
                        
                        setResults({
                            hits: response.data.hits,
                            query: state.search.term,
                            totalHits: response.data.total_hits
                        });
                    }
                })();
            }
        }
    }, [ state.search.term]);

    if (results?.hits?.length > 0) {
        return (
            <View>
                { renderHeading() }

                <FlatList data={results.hits}
                          renderItem={renderHit}
                />
                
                {
                    ((10 * pageNumber) < results.hits.length) &&
                        <View style={{marginHorizontal: 25}}>
                            <ViewMore color={theme?.colors?.red} 
                                    handler={() => setPageNumber(pageNumber + 1)}
                                    text='More'
                            />
                        </View>
                }
            </View>
        );
    }
    else {
        return (
            <View style={{ alignItems: 'center', justifyContent: 'center' }}>
                { renderHeading() }
                
                <Text numberOfLines={1} style={{ ...theme?.text?.H3, marginHorizontal: 10 }}>
                    { 
                        `No results found for ${state?.search?.term || ''}`
                    }
                </Text>
            </View>
        );
    }
};

SearchResults.propTypes = {
    query: PropTypes.string,
    searchRef: PropTypes.shape({
        current: PropTypes.any
    })
};

export default SearchResults;
