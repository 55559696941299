import React, { useEffect, useState }                          from 'react';
import { useWindowDimensions, Image as RNImage, PixelRatio }   from 'react-native';
import { Image as RNEImage }                                   from '@rneui/themed';
import { get }                                                 from 'lodash';
import PropTypes                                               from 'prop-types';
import Loading                                                 from './Loading';
import Utils                                                   from '../../common/utils';
import { useDeviceInfo }                                       from '../../hooks';

const Image = props => 
{
    const { isDesktopOrLaptop }           = useDeviceInfo();
    const [ dimensions, setDimensions ]   = useState({height: '100%', width: '100%'});
    const { height, width }               = useWindowDimensions();
    const scale                           = get(props, 'scale', true); 
    const size                            = get(props, 'size', 400); // TODO determine the default sizing
    const src                             = get(props, 'src');

    useEffect(() => 
    {
        if (scale) {
            const onSuccess = (w, h) => {
                const imageDimensions = Utils.calculateImageDimensions(isDesktopOrLaptop, width, height, w, h);
    
                setDimensions(imageDimensions);
            };
            
            RNImage.getSize(`https:${src}`, onSuccess, error => console.warn(`Error fetching image size: ${src} ${error}`));
        }
        else {
            setDimensions({height: PixelRatio.getPixelSizeForLayoutSize(size), width: PixelRatio.getPixelSizeForLayoutSize(size)});
        }   
    }, [ scale, size, src ]);

    return (
        <RNEImage source={{uri: `https:${src}`}}
		          style={{
			          height: dimensions.height, 
			          width:  dimensions.width, 
			          resizeMode: 'contain'
			      }} 
                  PlaceholderContent={<Loading />}
		/>
    );
};

Image.propTypes = {
    scale: PropTypes.bool,
    size: PropTypes.number,
    src: PropTypes.string.isRequired
};

export default Image;
