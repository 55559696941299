import React, { useContext }   from 'react';
import { View }                from 'react-native';
import Header                  from './Header';
import NavFooter               from './NavFooter';
import PropTypes               from 'prop-types';
import AppContext              from '../../../contexts/app';

const NativeSiteWrapper = props => 
{
    const { state } = useContext(AppContext);

    return (
        <View style={{
                  backgroundColor: !state?.action?.href || state?.action?.href?.equals('/app') ? '#ededed' : '#ffffff',
                  flex: 1
              }}
              testID='NativeSiteWrapper'
        >
            <Header topNav={props.topNav} />
                { props.children }
            <NavFooter bottomNav={props.bottomNav} />
        </View>
    );
};

NativeSiteWrapper.propTypes = {
    bottomNav: PropTypes.object.isRequired,
    topNav: PropTypes.object.isRequired
};

export default React.memo(NativeSiteWrapper);
