import React from 'react';
import { View }                                                 from 'react-native';
import Animated, { useAnimatedStyle, interpolate, Extrapolate } from 'react-native-reanimated';
import PropTypes                                                from 'prop-types';

const Indicator = React.memo(props => 
{
    const { 
        animationValue, 
        backgroundColor, 
        borderColor, 
        index, 
        length,
        width
    } = props;

    const animStyle = useAnimatedStyle(() => {
        let inputRange = [index - 1, index, index + 1];
        let outputRange = [-width, 0, width];

        if (index === 0 && animationValue?.value > length - 1) {
            inputRange = [length - 1, length, length + 1];
            outputRange = [-width, 0, width];
        }

        return {
            transform: [{
                    translateX: interpolate(
                        animationValue?.value,
                        inputRange,
                        outputRange,
                        Extrapolate.CLAMP
                    ),
            }]
        };
    }, [animationValue, index, length]);

    return (
        <View style={{
                  backgroundColor: '#dde4e9',
                  borderColor: borderColor,
                  borderRadius: 50,
                  borderWidth: 0.5,
                  height: width,
                  marginRight: 2.5,
                  overflow: 'hidden',
                  width,
              }}
              testID={`${props.testID}.Indicator_${index}`}
        >
            <Animated.View style={[{
                                  backgroundColor: backgroundColor,
                                  borderColor: borderColor,
                                  borderRadius: 50,
                                  borderWidth: 0.8,
                                  flex: 1
                               },
                                animStyle
                           ]}
            />
        </View>
    );
});

const Pagination = props => 
{
    const length = props.length > 5 ? 5 : props.length;

    return (
        <View style={{
                  alignItems: 'center',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  padding:5,
                  margin:5
              }} 
              testID={`${props.testID}.Container`}
        >
            {
                new Array(length).fill("").map((_, index) => {
                    return (
                        <Indicator animationValue={props.animationValue}
                                   backgroundColor={props.activeIndex === index ? props.activeBackgroundColor : props.backgroundColor}
                                   borderColor={props.activeIndex === index ? props.activeBorderColor : props.borderColor}
                                   index={index}
                                   key={index}
                                   length={length}
                                   width={props.indicatorWidth}
                                   testID={props.testID}
                        />
                    )
                })
            }
        </View>
    );
};

Pagination.defaultProps = {
    activeBackgroundColor: '#ffffff',
    activeBorderColor: '#dde4e9',
    backgroundColor: '#ffffff',
    borderColor: '#153d6e',
    length: 5,
    testID: 'Pagination',
    indicatorWidth: 10
};

Pagination.propTypes = {
    activeIndex: PropTypes.number.isRequired,
    activeBackgroundColor: PropTypes.string,
    activeBorderColor: PropTypes.string,
    animationValue: PropTypes.object.isRequired,
    backgroundColor: PropTypes.string, 
    borderColor: PropTypes.string,
    indicatorWidth: PropTypes.number,
    length: PropTypes.number,
    marginTopOffset: PropTypes.number,
    testID: PropTypes.string
};

export default Pagination;
