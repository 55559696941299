import React, { useContext } from 'react';
import { View } from 'react-native';
import { useTheme, Button, Text } from '@rneui/themed';
import PropTypes from 'prop-types';
import AppContext, { AppEvents } from '../../../contexts/app';
import { useDeviceInfo } from '../../../hooks';

const WhereIndicator = props => {
    const { state } = useContext(AppContext);
    const { width } = useDeviceInfo();
    const { theme } = useTheme();
    const searchOpen = state?.search?.state?.equals(
        AppEvents.SEARCH_DIALOGUE_STATE_OPEN
    );

    return (
        <View
            style={theme?.navigation?.mobile?.WhereIndicator.container}
            testID={props.testID}
        >
            <Button
                buttonStyle={
                    props.active?.parent &&
                    theme?.navigation?.mobile?.WhereIndicator?.buttonStyle
                }
                containerStyle={
                    theme?.navigation?.mobile?.WhereIndicator
                        ?.buttonContainerStyle
                }
                onPress={async () => props.setActiveMenu(props.active?.parent)}
                title={props.active?.parent?.initCaps()}
                titleStyle={
                    theme?.navigation?.mobile?.WhereIndicator?.titleStyle
                }
                type={props.active?.parent ? 'outline' : 'clear'}
            />
            <Text style={theme?.navigation?.mobile?.WhereIndicator.text}>
                {props?.active?.active?.length > width * 0.1
                    ? `${props?.active?.active
                          ?.substring(0, 30)
                          .initCaps()}....`
                    : props?.active?.active?.initCaps()}
            </Text>
            <Button
                buttonStyle={{ backgroundColor: 'transparent' }}
                containerStyle={{ justifyContent: 'flex-end' }}
                icon={{
                    ...theme?.navigation?.mobile?.WhereIndicator?.searchIcon,
                    name: searchOpen
                        ? 'magnify-minus-outline'
                        : 'magnify-plus-outline'
                }}
                onPress={() => props.setShowSearch(state => !state)}
            />
        </View>
    );
};

WhereIndicator.defaultProps = {
    testID: 'WhereIndicator'
};

WhereIndicator.propTypes = {
    active: PropTypes.object,
    setActiveMenu: PropTypes.func.isRequired,
    setShowSearch: PropTypes.func.isRequired,
    testID: PropTypes.string
};

export default WhereIndicator;
