import React, { useCallback, useMemo, useState }    from 'react';
import { Pressable, View }                          from 'react-native';
import { useTheme, Button, Dialog, Text }           from '@rneui/themed';
import moment                                       from 'moment';
import PropTypes                                    from 'prop-types';

const DAYS = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

const WebDateTimePicker = props =>  
{
    const today                               = useMemo(() => new Date(Date.now()), []);
    const { theme }                           = useTheme();
    const [ state, setState ]                 = useState({
        month: moment(today).month() + 1,
        selectedDate: today,
        visible: true,
        year: moment(today).year()
    })

    const onNavigate  = useCallback(isPrevious => {
        let month = state.month;
        let year  = state.year;

        if (isPrevious) {
            if (month === 1) {
                year  = year - 1;
                month = 12;
            }
            else {
                month = month - 1;
            }
        }
        else {
            if (month === 12) {
                year  = year + 1;
                month = 1;
            }
            else {
                month = month + 1;
            }
        }

        setState({ ...state, month, year });
    }, [ state ]);

    const isToday = useCallback(date => {
        const todaysDate = moment(today).date();

        if (date === todaysDate) {
            return true;
        }

        return false;
    }, [today]);

    const renderMonth = useCallback(() => 
    {
        const daysInMonth  = moment(`${state.year}-${state.month}`, 'YYYY-MM').daysInMonth();
        const startOfMonth = moment(`${state.year}-${state.month}`, 'YYYY-MM').startOf('month').day();
        const todayDate    = moment(today).day()
        const cells        = [];
        let dayCounter     = 1;

        const renderCell = (index, text, onPress, style) => {
            const handlePress = () => {
                if (onPress) {
                    onPress(moment(`${text}-${state.month}-${state.year}`, 'DD-MM-YYYY'));
                }
            };

            return (
                <Pressable key={`webdatepicker-${index}`} onPress={handlePress}>
                    <View style={{...style, height: 50, width: 50}}>
                        {
                            text &&
                                <Text numberOfLines={1} style={style?.text ||  theme?.appComponents?.common?.WebDateTimePicker?.month}
                                    >
                                    { text }
                                </Text>
                        }
                    </View>
                </Pressable>
            );
        };

        for (let x = 0 - startOfMonth; x < daysInMonth; x++) {
            const isTodaysDate = isToday(dayCounter);

            const style = { 
                ...theme?.appComponents?.common?.WebDateTimePicker?.dateCellStyle, 
                backgroundColor: isTodaysDate ? theme?.appComponents?.common?.WebDateTimePicker?.isTodaysDate?.backgroundColor
                                              : undefined,
                text: {
                    ...theme?.appComponents?.common?.WebDateTimePicker?.textStyle, 
                    color: isTodaysDate ? theme?.appComponents?.common?.WebDateTimePicker?.isTodaysDate?.color
                                        : theme?.appComponents?.common?.WebDateTimePicker?.textStyle.color,
                },
            };
             
            cells.push(renderCell(x, x >= 0 ? dayCounter++ : undefined, props.onSelect, style));
        }

        return (
            <View>
                <View style={{ alignItems: 'center', flexDirection: 'row', justifyContent: 'center' }}>
                    {
                        DAYS.map((day, index) => renderCell(index, day))
                    }
                </View>
                <View style={{flexDirection: 'row', flexWrap: 'wrap'}}>
                    { cells }
                </View>
            </View>
        );
    }, [ state.month, state.year ]);

    return (
        <Dialog backdropStyle={{backgroundColor: theme?.colors?.black, opacity: 0.30}} 
                isVisible={state.visible}
                onBackdropPress={() => setState({...state, visible: false})}
                overlayStyle={{ borderRadius: 10, minHeight: 400, width: 400 }}
        >
            <View style={{alignItems: 'center', flexDirection: 'row', justifyContent: 'space-evenly', marginBottom: 5}}>
                <Button buttonStyle={{ backgroundColor: 'transparent' }}
                        icon={theme?.appComponents?.common?.WebDateTimePicker?.leftBtnIcon}
                        onPress={() => onNavigate(true)}
                />
                <Text numberOfLines={1} style={theme?.appComponents?.common?.WebDateTimePicker?.monthYear}
                    >
                    { moment(`${state.year}-${state.month}`, 'YYYY-MM').format('MMMM YYYY') }
                </Text>
                <Button buttonStyle={{ backgroundColor: 'transparent' }}
                        icon={theme?.appComponents?.common?.WebDateTimePicker?.rightBtnIcon}
                        onPress={() => onNavigate(false)}
                />
            </View>
            { renderMonth() }
        </Dialog>    
    );
};

WebDateTimePicker.defaultProps = {
    onSelect: () => {},
    value: new Date()
};

WebDateTimePicker.propTypes = {
    onSelect: PropTypes.func,
    value: PropTypes.instanceOf(Date)
}

export default WebDateTimePicker;
