import React, { useContext } from 'react';
import { View } from 'react-native';
import { useTheme, Button, Input, Text } from '@rneui/themed';
import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import AppContext from '../../../../contexts/app';
import RegistrationContext, { Actions } from '../../../../contexts/registration';
import { useApi, useDeviceInfo } from '../../../../hooks';

const RegistrationStep2 = ({ control, getValues, isValid, setError }) => {
    const { post } = useApi();
    const { state: appState } = useContext(AppContext);
    const { state, dispatch } = useContext(RegistrationContext);
    const { isMobile } = useDeviceInfo();
    const { theme } = useTheme();

    const handleEmailConfirmation = async () => {
        const confirmationCode = getValues('emailConfirmationCode');

        if (confirmationCode && state.emailConfirmationId) {
            const endpoint = `${appState.settings.openIdSettings.authority}/Account/VerifyConfirmationCode`;
            const response = await post(endpoint, { confirmationCodeId: state.emailConfirmationId, confirmationCode });

            if (response?.success) {
                dispatch({ type: Actions.NEXT });
            } else {
                setError('emailConfirmationCode', {
                    type: 'custom',
                    message: 'Code does not match our records, please make sure you entered it correctly.'
                });
            }
        }
    };

    const handleResendCode = async () => {
        const emailAddress = getValues('email');

        if (emailAddress) {
            const endpoint = `${appState.settings.openIdSettings.authority}/Account/SendVerificationEmail`;
            const response = await post(endpoint, { email: emailAddress, domain: appState.settings.websiteSettings.domain });

            if (response?.success) {
                dispatch({
                    type: Actions.SET_EMAIL_CONFIRM_ID,
                    emailConfirmationCodeId: response.confirmationCodeId,
                    resendCode: true
                });
            }
        }
    };

    return (
        <View style={{ width: '100%' }} testID='Registration.Step2'>
            <View style={{ flexDirection: 'row', marginHorizontal: theme?.margins?.gutter }}>
                <Text style={theme?.appComponents?.common?.Authentication?.Registration?.introductionText}>
                    Please check your email and enter the confirmation number that we just emailed to you.
                </Text>
            </View>
            <View style={{ flexDirection: 'row', width: '100%' }}>
                <Controller
                    control={control}
                    defaultValue=''
                    name='emailConfirmationCode'
                    render={({ field, fieldState }) => {
                        return (
                            <Input
                                {...field}
                                containerStyle={{ width: '100%', marginVertical: 20 }}
                                errorMessage={!fieldState.error ? 'CONFIRMATION CODE' : fieldState.error?.message}
                                errorStyle={
                                    !fieldState.error
                                        ? theme?.appComponents?.common?.Authentication?.Registration?.placeholderStyle
                                        : theme?.appComponents?.common?.Authentication?.Registration?.errorStyle
                                }
                                innerRef={field.ref}
                                inputStyle={theme?.appComponents?.common?.Authentication?.Registration?.smallInputStyle}
                                onChangeText={val => field.onChange(val)}
                                textContentType='oneTimeCode'
                                spellCheck={false}
                            />
                        );
                    }}
                    rules={{
                        required: {
                            message: 'Confirmation Code is required',
                            value: true
                        }
                    }}
                />
            </View>
            <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
                <Text onPress={handleResendCode} style={theme.appComponents?.common?.Authentication?.Registration?.resendConfirmationText}>
                    Resend Code
                </Text>
            </View>
            <Button
                buttonStyle={theme.appComponents?.common?.Authentication?.Registration?.stepButton}
                containerStyle={theme.appComponents?.common?.Authentication?.Registration?.stepButtonContainer}
                disabled={!isValid}
                disabledStyle={theme.appComponents?.common?.Authentication?.Registration?.disabledStepButton}
                disabledTitleStyle={theme.appComponents?.common?.Authentication?.Registration?.disabledStepButtonTitle}
                onPress={() => handleEmailConfirmation()}
                title='NEXT STEP'
                titleStyle={theme.appComponents?.common?.Authentication?.Registration?.stepButtonTitle}
                type='solid'
            />
        </View>
    );
};

RegistrationStep2.propTypes = {
    control: PropTypes.object.isRequired,
    getValues: PropTypes.func.isRequired,
    isValid: PropTypes.bool.isRequired,
    setError: PropTypes.func.isRequired
};

export default RegistrationStep2;
