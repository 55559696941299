const AppConstants = {
    ADDTHIS_SCRIPT_ID: 'jhm-addthis',
    ARROW_UP: 'Up',
    ARROW_DOWN: 'Down',
    ARROW_LEFT: 'Left',
    ARROW_RIGHT: 'Right',
    CROP_NONE: 'none',
    CROP_FIT: 'fit',
    CROP_FACE: 'face',
    ENV_PROD: 'production',
    ENV_DEV: 'development',
    ENV_STAGE: 'staging',
    ENV_TEST: 'testing',
    EVENT_CALENDAR_HREF: 'event-calendar',
    GOOGLE_MAPS_URL:
        'https://www.google.com/maps/place/18755+Stone+Oak+Pkwy,+San+Antonio,+TX+78258/@29.6116366,-98.4972525,17z/data=!3m1!4b1!4m5!3m4!1s0x865c621d1e76216b:0x2c52b27ef477a5ad!8m2!3d29.6116366!4d-98.4950638',
    GOOGLE_RECAPTCHA_ID: 'jhm-grc',
    REACT_APP_ANCILLARY_BASE: '/api/cms/GetAncillaryNavigation',
    REACT_APP_NAVIGATION_BASE: '/api/cms/GetNavigation',
    REACT_APP_STARTUP_DATA_BASE: '/api/cms/GetStartupData',
    REACT_APP_CONTENT_BASE: '/api/cms/GetItem?key=',
    REACT_APP_GET_ITEMS_BASE: '/api/cms/GetItemsByType?documentType=',
    REACT_APP_PAGE_BASE: '/api/cms/GetPage?url=',
    REACT_APP_LIVE_SERVICE_BASE: '/api/cms/GetNextOrCurrentLiveService',
    REACT_APP_DOMAIN_DATA_BASE: '/api/cms/GetDomainData?domainName=',
    REACT_APP_NAME_ACQUIRE: '/api/marketing/add',
    REACT_APP_SEARCH_BASE: '/api/search?term=',
    REACT_APP_SETTINGS_BASE: '/api/settings',
    REACT_APP_SUBSCRIPTION_INFO: '/api/subscription/signupinfo',
    REACT_APP_SUBSCRIPTION_SIGNUP: '/api/subscription/subscribe',
    REACT_APP_VCMS_MEDIA_BASE: '/api/vcms/GetMedia?publicId=',
    REACT_APP_FEATURED_SERVICE_BASE: '/api/cms/GetFeaturedService',
    REACT_APP_DOMAIN_ITEM_BY_KEY_BASE: '/api/cms/GetDomainItemByKey',
    REACT_APP_INTERNAL_REDIRECT_FEATURED: '/featured-service',
    REACT_APP_GET_FORMSTACK_FORM_BASE: '/api/cms/GetFormStackForm?formCode=',
    REACT_APP_GET_COUNTRIES_BASE: '/Util/Address/GetCountriesWithName',
    REACT_APP_WATCH_ROUTE: '/watch',
    SOCIAL_MEDIA_TWITTER: 'twitter',
    SOCIAL_MEDIA_FACEBOOK: 'facebook',
    SOCIAL_MEDIA_INSTAGRAM: 'instagram',
    SOCIAL_MEDIA_YOUTUBE: 'youtube',
    STYLE_GUIDE_HREF: 'style-guide',
    TITLE_ABOVE_IMAGE: 'Above Image',
    TITLE_UPPER_LEFT: 'Upper Left Corner',
    TITLE_LOWER_LEFT: 'Lower Left Corner',
    TITLE_BELOW_VIDEO: 'Below Video',
    TITLE_ABOVE_VIDEO: 'Above Video',
    VALID_URI_SCHEMES: ['https', 'http', 'mailto', 'tel']
};

export default AppConstants;
