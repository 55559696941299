import { Dimensions, Platform, StyleSheet } from 'react-native';
import ThemeColors from './colors';
import { getMargins } from './margins';
import { getText } from './text';
import { dynamicFontFamily, dynamicFontSize, dynamicLineHeight, dynamicMarginSize } from '../common';
import { color } from 'react-native-reanimated';

export const getComponents = () => {
    const { width } = Dimensions.get('window');
    const isMobile = width <= 480;
    const isDesktopOrLaptop = width >= 991;
    const isTablet = width >= 480 && width <= 991;
    const ThemeText = getText();
    const ThemeMargins = getMargins();

    return {
        wrapper: {
            marginBottom: 40
        },

        common: {
            Dropdown: {
                placeholderStyle: {
                    ...ThemeText?.Paragraph,
                    marginVertical: 0,
                    marginHorizontal: 0,
                    fontFamily: 'GothamBook',
                    color: ThemeColors.navy
                },
                webContainer: {
                    backgroundColor: ThemeColors?.white,
                    borderColor: ThemeColors?.black,
                    borderBottomLeftRadius: 15,
                    borderBottomRightRadius: 15,
                    borderWidth: StyleSheet.hairlineWidth,
                    top: 30,
                    right: 1,
                    position: 'absolute',
                    elevation: 4,
                    shadowColor: ThemeColors?.black,
                    shadowOffset: { width: -6, height: 6 },
                    shadowRadius: 8,
                    shadowOpacity: 0.35
                }
            },

            Loading: {
                Container: {
                    alignItems: 'center',
                    backgroundColor: 'rgba (255,255,255, 0.5)',
                    bottom: 0,
                    flex: 1,
                    justifyContent: 'center',
                    left: 0,
                    padding: 10,
                    position: 'absolute',
                    right: 0,
                    top: 0
                },
                ActivityIndicator: {
                    color: ThemeColors.red2
                }
            },

            Authentication: {
                Login: {
                    alternateActionText: {
                        color: ThemeColors.red,
                        fontFamily: dynamicFontFamily('GothamBook'),
                        fontSize: dynamicFontSize(12)
                    },
                    errorText: {
                        color: ThemeColors.red,
                        fontFamily: dynamicFontFamily('GothamBook'),
                        fontSize: dynamicFontSize(14)
                    }
                },
                Options: {
                    button: {
                        borderColor: ThemeColors.navy,
                        borderWidth: Platform.OS === 'web' ? '1pt' : 1
                    },
                    buttonTitle: {
                        ...ThemeText.H5,
                        padding: 5
                    }
                },
                PasswordReset: {
                    alternateActionText: {
                        color: ThemeColors.red,
                        fontFamily: dynamicFontFamily('GothamBook'),
                        fontSize: dynamicFontSize(12)
                    },
                    errorText: {
                        color: ThemeColors.red,
                        fontFamily: dynamicFontFamily('GothamBook'),
                        fontSize: dynamicFontSize(14)
                    },
                    introductionText: {
                        fontFamily: dynamicFontFamily('GothamBook'),
                        fontSize: dynamicFontSize(14),
                        color: 'rgb(93, 110, 127)',
                        padding: 20
                    },
                    nextButton: {
                        borderColor: ThemeColors.navy,
                        borderWidth: Platform.OS === 'web' ? '1pt' : 1
                    },
                    nextButtonTitle: {
                        ...ThemeText.H5,
                        padding: 5
                    },
                    placeholderText: {
                        color: 'rgb(93, 110, 127)',
                        fontFamily: dynamicFontFamily('GothamBook'),
                        fontSize: dynamicFontSize(12)
                    },
                    smallInput: {
                        fontFamily: dynamicFontFamily('GothamBook'),
                        fontSize: dynamicFontSize(12)
                        // outlineStyle: Platform.OS.equals('web') ? 'none' : undefined
                    }
                },
                Registration: {
                    checkboxContainer: {
                        alignItems: 'center',
                        flexDirection: 'row',
                        justifyContent: 'center'
                    },
                    checkboxText: {
                        color: ThemeColors?.verydarkgrey,
                        fontFamily: dynamicFontFamily('GothamMedium'),
                        fontSize: dynamicFontSize(12),
                        textAlign: 'center'
                    },
                    errorStyle: {
                        color: ThemeColors?.darkred,
                        fontFamily: dynamicFontFamily('GothamBook'),
                        fontSize: dynamicFontSize(12)
                    },
                    introductionIconText: {
                        color: 'rgb(93, 110, 127)',
                        display: isMobile ? 'flex' : undefined,
                        fontFamily: dynamicFontFamily('GothamBook'),
                        fontSize: dynamicFontSize(12),
                        justifyContent: isMobile ? 'center' : undefined,
                        padding: isMobile ? 0 : 20
                    },
                    introductionText: {
                        fontFamily: dynamicFontFamily('GothamBook'),
                        fontSize: dynamicFontSize(14),
                        color: 'rgb(93, 110, 127)',
                        padding: isMobile ? 0 : 20
                    },
                    selectFieldInputText: {
                        color: 'rgb(93, 110, 127)',
                        fontFamily: dynamicFontFamily('GothamBook'),
                        fontSize: dynamicFontSize(12)
                        // outlineStyle: Platform.OS.equals('web') ? 'none' : undefined
                    },
                    smallInputStyle: {
                        fontFamily: dynamicFontFamily('GothamBook'),
                        fontSize: dynamicFontSize(12),
                        minHeight: isMobile ? undefined : 40
                        // outlineStyle: Platform.OS.equals('web') ? 'none' : undefined
                    },
                    validSmallInputStyle: {
                        borderBottomColor: ThemeColors?.green,
                        borderBottomWidth: 0.35,
                        fontFamily: dynamicFontFamily('GothamBook'),
                        fontSize: dynamicFontSize(12),
                        // outlineStyle: Platform.OS.equals('web') ? 'none' : undefined,
                        paddingLeft: 10
                    },
                    placeholderStyle: {
                        color: 'rgb(93, 110, 127)',
                        fontFamily: dynamicFontFamily('GothamBook'),
                        fontSize: dynamicFontSize(12)
                    },
                    resendConfirmationText: {
                        color: ThemeColors.navy,
                        fontFamily: dynamicFontFamily('GothamBook'),
                        fontSize: dynamicFontSize(12),
                        fontWeight: 800,
                        marginBottom: 10
                    },
                    stepButtonContainer: {
                        boxShadow: undefined,
                        flex: 1,
                        marginHorizontal: 10,
                        marginVertical: 10
                    },
                    stepButton: {
                        backgroundColor: ThemeColors.navy,
                        paddingHorizontal: 10
                    },
                    stepButtonTitle: {
                        color: ThemeColors.white,
                        fontFamily: dynamicFontFamily('GothamMedium'),
                        fontSize: dynamicFontSize(20),
                        textTransform: 'uppercase'
                    },
                    disabledStepButton: {
                        backgroundColor: ThemeColors.grey2,
                        opacity: 0.4
                    },
                    disabledStepButtonTitle: {
                        color: ThemeColors.white,
                        fontFamily: dynamicFontFamily('GothamMedium'),
                        fontSize: dynamicFontSize(20),
                        textTransform: 'uppercase'
                    },
                    previousStepButtonContainer: {
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        width: '100%'
                    },
                    previousStepButtonTitle: {
                        color: ThemeColors.navy,
                        fontFamily: dynamicFontFamily('GothamMedium'),
                        fontSize: dynamicFontSize(12)
                    },
                    alternateActionText: {
                        color: ThemeColors.red,
                        fontFamily: dynamicFontFamily('GothamBook'),
                        fontSize: dynamicFontSize(14)
                    }
                }
            },

            WebDateTimePicker: {
                dateCellStyle: {
                    alignItems: 'center',
                    borderColor: ThemeColors?.grey3,
                    borderWidth: 0.75,
                    justifyContent: 'center'
                },
                isTodaysDate: {
                    backgroundColor: ThemeColors?.navy,
                    color: ThemeColors?.white
                },
                textStyle: {
                    fontFamily: 'GothamBook',
                    color: ThemeColors.black,
                    fontSize: 16,
                    marginBottom: 0,
                    lineHeight: 18
                },
                leftBtnIcon: {
                    color: ThemeColors?.navy,
                    name: 'chevron-left',
                    size: 16,
                    type: 'font-awesome-5'
                },
                rightBtnIcon: {
                    color: ThemeColors?.navy,
                    name: 'chevron-right',
                    size: 16,
                    type: 'font-awesome-5'
                },
                monthYear: {
                    color: ThemeColors?.navy,
                    fontFamily: 'GothamBook'
                },
                month: {
                    fontFamily: 'GothamBook',
                    color: ThemeColors?.black,
                    fontSize: 16,
                    marginBottom: 0,
                    lineHeight: 18
                }
            }
        },

        umbraco: {
            LiveService: {
                watchNowContainer: {
                    alignItems: 'center',
                    flex: 1,
                    justifyContent: 'center',
                    marginBottom: 10
                }
            },
            Accordion: {
                blockQuote: ThemeColors?.red,
                title: {
                    ...ThemeText.H5,
                    marginBottom: 18,
                    marginLeft: 30
                },
                ListItem: {
                    title: {
                        ...ThemeText?.H5,
                        fontSize: 22,
                        marginVertical: 10,
                        marginLeft: 45
                    },
                    containerStyle: {
                        borderBottomColor: ThemeColors?.grey3,
                        borderBottomWidth: 0.75,
                        borderTopColor: ThemeColors?.grey3,
                        borderTopWidth: 0.75,
                        justifyContent: 'space-between'
                    }
                }
            },
            AddToCalendar: {
                buttonStyle: {
                    color: ThemeColors?.navy,
                    borderColor: ThemeColors?.black,
                    paddingVertical: 0
                },
                buttonIcon: {
                    color: ThemeColors?.navy,
                    name: 'calendar-alt',
                    size: 10
                },
                titleStyle: {
                    ...ThemeText?.Paragraph,
                    marginVertical: 0,
                    marginHorizontal: 0,
                    fontFamily: 'GothamBook',
                    color: ThemeColors.navy
                }
            },
            AvatarPlusText: {
                description: {
                    ...ThemeText?.Paragraph,
                    marginBottom: 0,
                    marginHorizontal: 0,
                    fontFamily: 'GothamBook',
                    marginTop: 10
                },
                title: {
                    ...ThemeText.H3
                },
                titleSubText: {
                    ...ThemeText.H5,
                    color: ThemeColors.grey2,
                    marginBottom: 0,
                    lineHeight: 18
                }
            },
            AvatarPlusTextWithSlider: {
                buttonIconRight: {
                    color: ThemeColors?.grey2,
                    name: 'chevron-right-circle-outline',
                    size: 30,
                    type: 'material-community'
                },
                buttonIconLeft: {
                    color: ThemeColors?.grey2,
                    name: 'chevron-left-circle-outline',
                    size: 30,
                    type: 'material-community'
                },
                container: {
                    flex: 1
                },
                paginationDot: {
                    width: 10,
                    height: 10,
                    borderRadius: 5,
                    backgroundColor: ThemeColors?.white,
                    borderColor: ThemeColors?.navy,
                    borderWidth: 0.25
                },
                paginationInactiveDot: {
                    backgroundColor: ThemeColors?.grey3,
                    borderColor: ThemeColors?.grey3,
                    width: 10,
                    height: 10,
                    borderRadius: 5
                },
                title: {
                    fontFamily: 'GothamBook',
                    fontSize: 20,
                    marginBottom: 10,
                    marginLeft: 40
                }
            },
            CornerBlockWYSIWYG: {
                container: {
                    backgroundColor: ThemeColors.black
                },
                title: {
                    color: ThemeColors.white,
                    fontFamily: 'GothamBook',
                    fontSize: 24,
                    letterSpacing: 0,
                    lineHeight: 30,
                    paddingTop: 20,
                    paddingHorizontal: 10,
                    paddingBottom: 20,
                    marginTop: 10
                }
            },
            CornerBlockCTA: {
                buttonTitle: {
                    fontFamily: 'GothamBook',
                    fontSize: 18
                }
            },
            Cta: {
                background: {
                    position: 'absolute',
                    left: 0,
                    height: '100%',
                    right: 0,
                    top: 0,
                    zIndex: -1
                },
                btnTitle: {
                    ...ThemeText?.Paragraph,
                    marginVertical: 0,
                    marginHorizontal: 0,
                    fontFamily: 'GothamBook',
                    color: ThemeColors?.white
                },
                btnTextColor: ThemeColors?.white,
                desktopCtaContainer: {
                    alignItems: 'center',
                    flexDirection: 'row',
                    height: isTablet ? width / 3 : width / 4.3,
                    paddingHorizontal: ThemeMargins?.gutter
                },
                desktopCtaHeadline: {
                    ...ThemeText.H3,
                    color: ThemeColors?.white,
                    marginVertical: 0
                },
                mobileCtaContainer: {
                    flex: 1,
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    paddingHorizontal: ThemeMargins?.gutter
                },
                mobileCtaHeadline: {
                    ...ThemeText?.H3,
                    paddingTop: ThemeMargins?.gutter,
                    color: ThemeColors?.white
                },
                linearGradient: {
                    firstColor: ThemeColors?.navy,
                    secondColor: ThemeColors?.lightNavy
                }
            },
            CtaContainer: {
                containerItem: {
                    alignContent: 'center',
                    borderBottomWidth: StyleSheet.hairlineWidth,
                    flexDirection: 'row',
                    justifyContent: 'space-between'
                }
            },
            Event: {
                Cta: {
                    backgroundColor: ThemeColors?.red,
                    paddingVertical: 1
                },
                CtaTitle: {
                    ...ThemeText?.Paragraph,
                    marginVertical: 0,
                    marginHorizontal: 0,
                    fontFamily: 'GothamBook',
                    color: ThemeColors?.white
                },
                Details: {
                    content: {
                        ...ThemeText?.Paragraph,
                        marginVertical: 0,
                        marginHorizontal: 0,
                        fontFamily: 'GothamBook',
                        color: ThemeColors.grey,
                        flex: 1
                    },
                    dataRow: {
                        flexDirection: 'row',
                        marginHorizontal: ThemeMargins.gutter,
                        marginTop: 10
                    },
                    label: {
                        ...ThemeText?.Paragraph,
                        marginVertical: 0,
                        marginHorizontal: 0,
                        fontFamily: 'GothamBook',
                        color: ThemeColors.black
                    },
                    link: {
                        ...ThemeText?.Paragraph,
                        marginVertical: 0,
                        marginHorizontal: 0,
                        fontFamily: 'GothamBook',
                        color: ThemeColors.navy
                    },
                    text: {
                        ...ThemeText?.Paragraph,
                        marginVertical: 0,
                        marginHorizontal: 0,
                        fontFamily: 'GothamBook'
                    }
                },
                Header: {
                    bottomContainer: {
                        backgroundColor: '#f8f9fa',
                        paddingVertical: 20,
                        paddingHorizontal: ThemeMargins?.gutter
                    }
                }
            },
            EventCalendar: {
                Body: {
                    hourGuideColumn: {
                        alignItems: 'center',
                        backgroundColor: ThemeColors?.white,
                        borderBottomColor: ThemeColors?.grey100,
                        borderBottomWidth: 0.45,
                        borderRightColor: ThemeColors?.grey100,
                        borderRightWidth: 0.45,
                        justifyContent: 'center',
                        width: 75,
                        zIndex: 1000
                    },
                    hourGuideColumnText: {
                        color: ThemeColors?.black,
                        fontFamily: 'GothamBook',
                        fontSize: 16
                    },
                    nowIndicator: {
                        position: 'absolute',
                        zIndex: 10000,
                        backgroundColor: ThemeColors?.red,
                        height: 2,
                        width: '100%'
                    }
                },
                CommonStyles: {
                    dateCell: {
                        borderWidth: 1,
                        borderColor: ThemeColors?.grey100,
                        borderRightWidth: 0,
                        borderTopWidth: 0.5,
                        backgroundColor: ThemeColors?.white
                    },
                    guideText: {
                        color: ThemeColors?.black,
                        fontSize: 16,
                        textAlign: 'center'
                    },
                    hourGuide: {
                        backgroundColor: ThemeColors?.white,
                        width: 50,
                        zIndex: 1000
                    },
                    isToday: ThemeColors?.aliceLightBlue,
                    eventCell: {
                        backgroundColor: ThemeColors?.red3,
                        borderRadius: 3,
                        elevation: 2,
                        end: 3,
                        minWidth: '33%',
                        overflow: 'hidden',
                        padding: 4,
                        position: 'absolute',
                        shadowColor: ThemeColors?.black,
                        shadowOffset: { width: 0, height: 1 },
                        shadowOpacity: 0.2,
                        shadowRadius: 1,
                        start: 3,
                        zIndex: 100
                    },
                    eventTitle: {
                        color: ThemeColors?.white,
                        fontFamily: 'GothamBook',
                        fontSize: 12
                    }
                },
                Event: {
                    eventTime: {
                        color: ThemeColors?.white,
                        fontFamily: 'GothamBook',
                        fontSize: 12
                    }
                },
                Header: {
                    arrowIndicatorMobile: {
                        fontFamily: 'GothamBook',
                        fontSize: 24
                    },
                    container: {
                        borderBottomColor: ThemeColors?.grey100,
                        borderBottomWidth: 0.25,
                        flexDirection: 'row'
                    },
                    dayText: {
                        color: ThemeColors?.grey2,
                        fontFamily: 'GothamBook',
                        fontSize: 20,
                        marginTop: 4,
                        textAlign: 'center'
                    },
                    dateText: {
                        color: ThemeColors?.black,
                        fontFamily: 'GothamBook',
                        fontSize: 30,
                        marginBottom: 4,
                        marginRight: 8,
                        textAlign: 'center'
                    },
                    hourGuide: {
                        backgroundColor: '#fff',
                        width: 50,
                        zIndex: 1000
                    },
                    hourGuideColumn: {
                        alignItems: 'center',
                        backgroundColor: ThemeColors?.white,
                        borderBottomColor: ThemeColors?.grey100,
                        borderBottomWidth: 0.45,
                        borderRightColor: ThemeColors?.grey100,
                        borderRightWidth: 0.45,
                        borderTopColor: ThemeColors?.grey100,
                        borderTopWidth: 0.45,
                        justifyContent: 'center',
                        width: 75,
                        height: '100%',
                        zIndex: 1000
                    },
                    hourGuideSpacer: {
                        borderBottomColor: ThemeColors?.grey100,
                        borderBottomWidth: 0.45
                    },
                    isToday: ThemeColors?.aliceLightBlue,
                    mobileContainer: {
                        alignItems: 'center',
                        backgroundColor: ThemeColors?.grey3,
                        flexDirection: 'row',
                        justifyContent: 'space-around',
                        paddingVertical: 10
                    },
                    wrapperItem: {
                        borderRightColor: ThemeColors?.grey100,
                        borderRightWidth: 0.45,
                        borderLeftColor: ThemeColors?.grey100,
                        borderLeftWidth: 0.45,
                        borderTopColor: ThemeColors?.grey100,
                        borderTopWidth: 0.45,
                        flex: 1,
                        paddingTop: 2,
                        backgroundColor: ThemeColors?.white
                    }
                },
                ToolBar: {
                    btnContainerStyle: {
                        backgroundColor: ThemeColors?.navy,
                        flexDirection: 'column'
                    },
                    btnTitleStyle: {
                        color: ThemeColors?.white
                    },
                    btnIconStyle: {
                        backgroundColor: 'transparent',
                        marginVertical: 2
                    },
                    leftIconBtn: {
                        color: ThemeColors?.navy,
                        name: 'chevron-left-circle-outline',
                        size: 30,
                        type: 'material-community'
                    },
                    rightIconBtn: {
                        color: ThemeColors?.navy,
                        name: 'chevron-right-circle-outline',
                        size: 30,
                        type: 'material-community'
                    },
                    selectDateBtnStyle: {
                        backgroundColor: ThemeColors?.grey4
                    },
                    selectDateBtnContainer: {
                        borderColor: ThemeColors?.navy,
                        borderWidth: StyleSheet.hairlineWidth,
                        flexDirection: 'column'
                    },
                    selectDateBtnIcon: {
                        color: ThemeColors?.navy,
                        name: 'calendar-alt',
                        size: 12,
                        style: { marginRight: 2 },
                        type: 'font-awesome-5'
                    },
                    selectDateBtnTitleStyle: {
                        color: ThemeColors?.navy
                    },
                    filterBtnStyle: {
                        backgroundColor: ThemeColors?.grey4
                    },
                    filterBtnContainer: {
                        borderColor: ThemeColors?.navy,
                        borderWidth: StyleSheet.hairlineWidth,
                        flexDirection: 'column'
                    },
                    filterBtnIcon: {
                        color: ThemeColors?.navy,
                        name: 'filter',
                        size: 12,
                        type: 'font-awesome-5'
                    },
                    filterBtnTitleStyle: {
                        color: ThemeColors?.navy
                    },
                    filterCloseIcon: {
                        color: ThemeColors?.grey2,
                        name: 'times',
                        size: 18,
                        type: 'font-awesome-5'
                    },
                    filterApplyBtnStyle: {
                        backgroundColor: ThemeColors?.red
                    },
                    filterApplyBtnTitleStyle: {
                        color: ThemeColors?.white
                    },
                    checkBoxColor: ThemeColors?.navy,
                    checkBoxTitle: ThemeColors?.black
                }
            },
            EventsGrid: {
                controlButtonContainerStyle: {
                    margin: 0
                },
                calendarText: {
                    ...ThemeText?.Paragraph,
                    marginVertical: 0,
                    marginHorizontal: 0,
                    fontFamily: 'GothamBook',
                    color: ThemeColors?.navy,
                    marginLeft: 10
                },
                calendarIcon: {
                    color: ThemeColors?.navy,
                    name: 'calendar-alt',
                    size: 18,
                    type: 'font-awesome-5'
                },
                backBtnIcon: {
                    color: ThemeColors?.navy,
                    name: 'chevron-back-circle-outline',
                    size: 24,
                    type: 'ionicon'
                },
                forwardBtnIcon: {
                    color: ThemeColors?.navy,
                    name: 'chevron-forward-circle-outline',
                    size: 24,
                    type: 'ionicon'
                },
                eventDateBubble: {
                    alignItems: 'center',
                    backgroundColor: ThemeColors?.white,
                    borderColor: ThemeColors?.black,
                    borderRadius: isMobile ? 8 : 10,
                    height: isMobile ? 45 : 50,
                    justifyContent: 'center',
                    position: 'absolute',
                    width: isMobile ? 45 : 50
                },
                eventDay: {
                    fontFamily: 'GothamBook',
                    fontSize: 20
                },
                eventDetails: {
                    lineHeight: 24
                },
                eventMonth: {
                    fontFamily: 'GothamBook',
                    fontSize: 18,
                    marginTop: 0.75,
                    textTransform: 'uppercase',
                    color: ThemeColors?.grey
                },
                eventText: {
                    color: ThemeColors?.white,
                    fontFamily: 'GothamBook',
                    fontSize: 14,
                    marginRight: 3,
                    marginBottom: 8
                },
                eventTitle: {
                    color: ThemeColors?.white,
                    fontFamily: 'GothamBook',
                    fontSize: 18,
                    textTransform: 'capitalize',
                    marginBottom: 8
                }
            },
            FullWidthImage: {
                containerWithTitleOverlayDesktop: {
                    flex: 1
                },
                imageContainerStyle: {
                    height: width / 2.5
                },
                description: {
                    ...ThemeText?.H4,
                    marginHorizontal: ThemeMargins?.gutter,
                    color: ThemeColors?.black
                },
                heading: {
                    ...ThemeText?.ComponentTitle,
                    marginHorizontal: ThemeMargins?.gutter,
                    color: ThemeColors?.white
                },
                arrowIndicatorTitle: {
                    color: ThemeColors.black,
                    fontFamily: 'GothamBook',
                    fontSize: 12
                },
                container: {
                    width: isMobile ? width : width,
                    height: isMobile ? width / 1.3 : width / 1.6
                },
                textContentContainer: {
                    flexDirection: 'row',
                    backgroundColor: ThemeColors?.grey3
                },
                title: {
                    ...ThemeText?.ComponentTitle,
                    color: ThemeColors?.black,
                    marginHorizontal: 0
                },
                textTitleDescriptionView: {
                    flex: 1,
                    marginVertical: dynamicMarginSize(6),
                    marginHorizontal: 0
                }
            },
            Giving: {
                ConstituentCard: {
                    addressVerifiedIndicator: {
                        borderRadius: 7,
                        borderWidth: 1,
                        color: ThemeColors.white,
                        fontFamily: 'GothamBook',
                        fontSize: '11pt',
                        marginLeft: '10pt',
                        marginTop: '5pt',
                        padding: 3,
                        textAlign: 'center',
                        width: '10%'
                    },
                    container: {
                        alignItems: 'center',
                        borderWidth: 0,
                        borderRadius: Platform.OS === 'web' ? '10pt' : 10,
                        boxShadow: 'rgba(100, 100, 111, 0.2) 0pt 7pt 29pt 0pt',
                        marginHorizontal: 0,
                        marginVertical: Platform.OS === 'web' ? '30pt' : 30,
                        padding: 0,
                        width: '90%'
                    },
                    confirmationCheckbox: {
                        color: ThemeColors.brightred,
                        text: {
                            cursor: 'text',
                            color: ThemeColors.verydarkgrey,
                            fontFamily: dynamicFontFamily('GothamMedium'),
                            fontSize: dynamicFontSize(10),
                            marginLeft: 0,
                            textAlign: 'left'
                        }
                    },
                    confirmationContainer: {
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginTop: 25,
                        width: '100%'
                    },
                    confirmationText: {
                        color: ThemeColors.verydarkgrey,
                        fontFamily: dynamicFontFamily('GothamMedium'),
                        fontSize: dynamicFontSize(12),
                        textAlign: 'center',
                        width: '100%'
                    },
                    donationButton: {
                        borderRadius: 8,
                        borderWidth: 0,
                        backgroundColor: ThemeColors.darkblue2,
                        paddingHorizontal: '18pt',
                        paddingVertical: '8pt'
                    },
                    donationButtonContainer: {
                        boxShadow: undefined,
                        flexDirection: 'row',
                        justifyContent: 'center',
                        marginHorizontal: 30,
                        marginVertical: 20
                    },
                    donationButtonDisabled: {
                        backgroundColor: ThemeColors.darkblue2,
                        opacity: 0.4
                    },
                    donationButtonDisabledTitle: {
                        color: ThemeColors.white,
                        fontWeight: 900,
                        fontFamily: dynamicFontFamily('GothamMedium'),
                        fontSize: dynamicFontSize(30)
                    },
                    donationButtonTitle: {
                        color: ThemeColors.white,
                        fontWeight: 900,
                        fontFamily: dynamicFontFamily('GothamMedium'),
                        fontSize: dynamicFontSize(30)
                    },
                    manualAddressOverrideText: {
                        color: 'rgb(255, 165, 0)',
                        fontFamily: dynamicFontFamily('GothamBook'),
                        fontSize: dynamicFontSize(12),
                        paddingVertical: 10,
                        width: '30%'
                    }
                },
                ContributionCard: {
                    addDontationButtonContainer: {
                        alignItems: 'center',
                        flexDirection: 'row',
                        justifyContent: 'flex-end',
                        marginBottom: '20pt',
                        marginHorizontal: '20pt',
                        marginTop: '5pt',
                        paddingRight: 18
                    },
                    addDontationButtonTitle: {
                        color: ThemeColors.red4,
                        fontFamily: dynamicFontFamily('GothamBook'),
                        fontSize: dynamicFontSize(12),
                        textDecorationColor: ThemeColors.red4,
                        textDecorationLine: 'underline',
                        textDecorationStyle: 'solid'
                    },
                    cardTitle: {
                        alignSelf: 'center',
                        color: ThemeColors.verydarkgray,
                        fontSize: dynamicFontSize(30),
                        fontFamily: dynamicFontFamily('GothamMedium'),
                        fontWeight: 'normal',
                        paddingTop: 20,
                        textAlign: 'center'
                    },
                    requireWarning: {
                        fontSize: dynamicFontSize(10),
                        fontFamily: dynamicFontFamily('GothamBook'),
                        color: ThemeColors?.brightred,
                        position: 'absolute',
                        top: isMobile ? 5 : '10pt',
                        left: isMobile ? 5 : '10pt'
                    },
                    container: {
                        alignItems: 'center',
                        backgroundColor: ThemeColors.white,
                        borderColor: ThemeColors.verydarkgray,
                        borderRadius: 10,
                        borderWidth: 0.75,
                        marginBottom: 0,
                        marginHorizontal: 0,
                        padding: 0,
                        width: '90%'
                    },
                    currencySymbol: {
                        color: ThemeColors.darkgray,
                        fontFamily: dynamicFontFamily('GothamBook'),
                        fontSize: dynamicFontSize(22),
                        paddingRight: '3pt'
                    },
                    donationAmountContainer: {
                        borderWidth: 1,
                        borderColor: ThemeColors.verydarkgray,
                        height: 40,
                        paddingVertical: 0,
                        paddingHorizontal: 0
                    },
                    input: {
                        borderColor: 'transparent',
                        borderWidth: 0,
                        fontFamily: dynamicFontFamily('GothamBook'),
                        fontSize: dynamicFontSize(14),
                        margin: 0,
                        minWidth: '20%',
                        padding: 0,
                        paddingRight: 5,
                        textAlign: 'right'
                    },
                    inputContainer: {
                        alignContent: 'center',
                        alignItems: 'center',
                        borderColor: 'transparent',
                        borderWidth: 0,
                        justifyContent: 'flex-end',
                        padding: 0,
                        textAlignVertical: 'center'
                    },
                    selectFieldContainer: {
                        borderRadius: 4,
                        minHeight: 40
                    },
                    selectField: {
                        borderWidth: 0,
                        fontFamily: 'sans-serif',
                        fontSize: dynamicFontSize(12),
                        height: '100%',
                        paddingLeft: 5
                    },
                    subtitle: {
                        color: ThemeColors.lightgray2,
                        fontSize: dynamicFontSize(42),
                        fontFamily: dynamicFontFamily('GothamBook'),
                        marginBottom: 10,
                        textAlign: 'center'
                    }
                },
                FormContent: {
                    asterisk: {
                        color: ThemeColors.brightred,
                        fontWeight: 'bold',
                        paddingLeft: '2pt'
                    },
                    errorStyle: {
                        color: ThemeColors?.brightred,
                        fontFamily: dynamicFontFamily('GothamBook'),
                        fontSize: dynamicFontSize(10),
                        margin: 0,
                        paddingLeft: Platform.OS === 'web' ? '3pt' : 3
                    },
                    inputStyle: {
                        fontFamily: dynamicFontFamily('GothamBook'),
                        fontSize: dynamicFontSize(16),
                        width: '100%',
                        minHeight: 20,
                        paddingBottom: Platform.OS === 'web' ? '2pt' : 2,
                        paddingLeft: Platform.OS === 'web' ? '3pt' : 3,
                        paddingTop: Platform.OS === 'web' ? '4pt' : 4
                    },
                    smallInputStyle: {
                        fontFamily: dynamicFontFamily('GothamBook'),
                        fontSize: dynamicFontSize(16),
                        width: '100%',
                        minHeight: 20,
                        paddingBottom: Platform.OS === 'web' ? '2pt' : 2,
                        paddingLeft: Platform.OS === 'web' ? '3pt' : 3,
                        paddingTop: Platform.OS === 'web' ? '4pt' : 4
                    },
                    validSmallInputStyle: {
                        borderColor: ThemeColors?.green,
                        borderWidth: 0.35,
                        fontFamily: dynamicFontFamily('GothamBook'),
                        fontSize: dynamicFontSize(12),
                        width: '100%',
                        paddingBottom: Platform.OS === 'web' ? '2pt' : 2,
                        paddingLeft: Platform.OS === 'web' ? '3pt' : 3,
                        paddingTop: Platform.OS === 'web' ? '4pt' : 4
                    },
                    placeholderStyle: {
                        fontFamily: dynamicFontFamily('GothamBook'),
                        fontSize: dynamicFontSize(10),
                        margin: 0,
                        paddingBottom: Platform.OS === 'web' ? '2pt' : 2,
                        paddingLeft: Platform.OS === 'web' ? '3pt' : 3,
                        paddingTop: Platform.OS === 'web' ? '4pt' : 4
                    },
                    label: {
                        color: ThemeColors.black2,
                        fontFamily: 'GothamBook',
                        fontSize: Platform.OS === 'web' ? '10pt' : 10
                    }
                },
                PaymentMethod: {
                    container: {
                        borderColor: ThemeColors.verydarkgray,
                        borderWidth: 0.75,
                        borderRadius: 10,
                        alignItems: 'center',
                        backgroundColor: ThemeColors.white,
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                        marginHorizontal: 0,
                        marginVertical: 30,
                        padding: 0,
                        width: '90%'
                    },
                    headingText: {
                        //fontSize: Platform.OS.equals('web') && width > 480 ? '20pt' : 12,
                        fontFamily: 'GothamMedium',
                        marginLeft: 4,
                        textTransform: 'uppercase'
                    },
                    inputContainer: {
                        alignItems: 'center',
                        backgroundColor: ThemeColors.lightgray2,
                        borderBottomEndRadius: 10,
                        borderBottomStartRadius: 10,
                        padding: Platform.OS.equals('web') && width > 480 ? 40 : 20,
                        width: '100%'
                    },
                    inputWrapper: {
                        alignItems: 'center',
                        backgroundColor: ThemeColors.white,
                        borderRadius: 6,
                        borderColor: ThemeColors.verydarkgray,
                        borderWidth: 1,
                        justifyContent: 'center',
                        paddingBottom: Platform.OS.equals('web') ? 60 : 20,
                        paddingTop: Platform.OS.equals('web') ? 60 : 20,
                        width: '100%'
                    }
                }
            },
            HowToWatch: {
                activeOption: {
                    backgroundColor: ThemeColors.navy
                },
                imageIcon: {
                    height: 75,
                    marginBottom: 10,
                    width: '100%'
                },
                inactiveOption: {
                    backgroundColor: ThemeColors.grey2
                },
                nativeSvgIcon: {
                    color: ThemeColors?.white
                },
                option: {
                    borderBottomWidth: 0,
                    borderLeftWidth: 0,
                    borderRightWidth: 0,
                    borderTopWidth: 0,
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginHorizontal: 2,
                    marginVertical: 0
                },
                optionTitleActive: {
                    fontSize: isMobile ? dynamicFontSize(14) : dynamicFontSize(18),
                    fontFamily: 'GothamBook',
                    color: ThemeColors.white,
                    textAlign: 'center',
                    marginTop: isMobile ? 0 : 15
                },
                optionTitleInactive: {
                    fontSize: isMobile ? dynamicFontSize(14) : dynamicFontSize(18),
                    fontFamily: 'GothamBook',
                    color: ThemeColors.navyDark,
                    textAlign: 'center',
                    marginTop: isMobile ? 0 : 15
                },
                optionsContainer: {
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-around',
                    marginTop: 6
                },
                webSvgIcon: {
                    color: ThemeColors?.white,
                    height: '100%',
                    marginVertical: 10,
                    width: '100%'
                },
                Broadcast: {
                    AccordionListItem: {
                        container: {
                            borderBottomColor: ThemeColors.grey2,
                            borderBottomWidth: 0.75,
                            cursor: Platform.OS === 'web' ? 'pointer' : undefined,
                            flex: 1,
                            justifyContent: 'space-between',
                            marginHorizontal: 50,
                            marginVertical: 20
                        },
                        header: {
                            borderBottomColor: ThemeColors?.grey2,
                            borderBottomWidth: 0.75,
                            justifyContent: 'center',
                            paddingBottom: 30,
                            paddingTop: 10,
                            marginHorizontal: 5
                        }
                    },
                    textStyle: {
                        color: ThemeColors?.grey2,
                        marginBottom: 5
                    },
                    TabItemTitle: {
                        fontFamily: dynamicFontFamily('GothamBook'),
                        fontSize: dynamicFontSize(16),
                        marginBottom: 0,
                        lineHeight: dynamicLineHeight(18)
                    },
                    activeTabItemTitle: {
                        color: ThemeColors?.red
                    },
                    inactiveTabItemTitle: {
                        color: ThemeColors?.navy
                    },
                    description: {
                        color: ThemeColors.grey2
                    },
                    chevronColorStyle: {
                        color: ThemeColors?.grey
                    }
                }
            },
            IconPlusHeading: {
                container: {
                    alignContent: 'center',
                    flex: 1,
                    flexDirection: 'row'
                },
                iconClassContainer: {
                    alignContent: 'center',
                    height: 60,
                    justifyContent: 'center',
                    marginRight: 20,
                    width: 60
                },
                iconImgContainer: {
                    alignContent: 'center',
                    height: 60,
                    justifyContent: 'center',
                    marginRight: 20,
                    width: 60
                },
                headline: {
                    fontFamily: 'GothamBook',
                    overflow: 'break-word',
                    textAlign: 'center'
                }
            },
            Job: {
                detailsLabel: {
                    ...ThemeText?.H4,
                    textAlign: 'left',
                    marginVertical: 0
                },
                detailsValue: {
                    ...ThemeText?.RichTextParagraph,
                    marginVertical: 0
                },
                detailsValueActionable: {
                    ...ThemeText?.RichTextParagraph,
                    textDecorationLine: 'underline',
                    color: ThemeColors?.navy
                },
                headerContainer: {
                    flexDirection: 'row',
                    backgroundColor: ThemeColors?.grey4,
                    paddingLeft: ThemeMargins.gutter,
                    paddingVertical: 20,
                    width: '100%',
                    flexWrap: 'wrap',
                    marginBottom: 10
                },
                descrTitle: {
                    ...ThemeText?.H3,
                    marginTop: 20,
                    marginBottom: 4,
                    fontWeight: 900
                },
                jobTitle: {
                    ...ThemeText?.ComponentTitle,
                    color: ThemeColors?.white,
                    marginLeft: 0
                },
                btnBackTitle: {
                    ...ThemeText?.Paragraph,
                    marginVertical: 0,
                    marginHorizontal: 0,
                    fontFamily: 'GothamBook',
                    color: ThemeColors?.white
                },
                departmentText: {
                    ...ThemeText?.H3,
                    width: '100%',
                    marginBottom: 10
                },
                btnApplyStyle: {
                    backgroundColor: ThemeColors?.red,
                    padding: 6
                },
                btnApplyTitle: {
                    ...ThemeText?.Paragraph,
                    marginHorizontal: 0,
                    color: ThemeColors?.white
                }
            },
            JobContainer: {
                headingTitle: {
                    ...ThemeText?.H1
                },
                departmentText: {
                    ...ThemeText?.H6,
                    fontSize: dynamicFontSize(15),
                    marginBottom: 10,
                    color: ThemeColors?.grey
                },
                jobTitle: {
                    ...ThemeText?.Paragraph,
                    marginVertical: 0,
                    marginHorizontal: 0,
                    fontFamily: 'GothamBook',
                    color: ThemeColors?.navy
                },
                organization: {
                    ...ThemeText?.Paragraph,
                    color: ThemeColors?.black,
                    marginVertical: 0,
                    marginHorizontal: 0,
                    fontFamily: 'GothamBook'
                }
            },
            ListItemContainer: {
                container: {
                    alignItems: 'center',
                    flex: 1,
                    justifyContent: 'center',
                    paddingHorizontal: 5,
                    paddingVertical: 5,
                    color: ThemeColors.red
                },
                item: {
                    alignItems: 'center',
                    flexDirection: 'row',
                    marginHorizontal: ThemeMargins.gutter,
                    marginVertical: ThemeMargins.gutter
                },
                itemDescription: {
                    ...ThemeText?.Paragraph,
                    marginTop: 0,
                    marginHorizontal: 0,
                    fontFamily: 'GothamBook',
                    marginVertical: 5
                },
                itemWithBorder: {
                    alignItems: 'center',
                    borderBottomColor: ThemeColors.grey3,
                    borderBottomWidth: 0.75,
                    flexDirection: 'row',
                    marginHorizontal: ThemeMargins.gutter,
                    marginVertical: ThemeMargins.gutter
                },
                viewMoreBtnStyle: {
                    backgroundColor: ThemeColors?.white
                },
                viewMoreBtnTitle: {
                    ...ThemeText?.Paragraph,
                    marginVertical: 0,
                    marginHorizontal: 0,
                    fontFamily: 'GothamBook',
                    color: ThemeColors?.red,
                    paddingTop: 5
                },
                viewMoreIcon: {
                    color: ThemeColors?.red,
                    containerStyle: { paddingHorizontal: 5 },
                    type: 'font-awesome-5'
                }
            },
            Ministry: {
                ctaButtonTitle: {
                    ...ThemeText?.Paragraph,
                    marginVertical: 0,
                    marginHorizontal: 0,
                    fontFamily: 'GothamMedium',
                    color: ThemeColors?.white
                },
                ctaButtonIcon: {
                    color: ThemeColors?.white,
                    name: 'chevron-circle-right',
                    size: isMobile ? 18 : 26
                },
                ctaHeading: {
                    ...ThemeText?.Paragraph,
                    marginVertical: 0,
                    marginHorizontal: 0,
                    fontFamily: 'GothamBook',
                    color: ThemeColors?.white
                },
                ctaOptionalBar: {
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: 'rgba(21, 61, 110, 0.5)',
                    flexDirection: 'row',
                    position: 'absolute',
                    height: 75,
                    top: 0,
                    left: 0,
                    right: 0,
                    flex: 1,
                    paddingHorizontal: ThemeMargins.gutter
                },
                nameContainer: {
                    alignItems: 'center',
                    bottom: 0,
                    flexDirection: 'row',
                    position: 'absolute',
                    width: '100%',
                    paddingLeft: ThemeMargins.gutter
                },
                ministryName: {
                    ...ThemeText?.ComponentTitle,
                    color: ThemeColors?.white
                }
            },
            MinistryGrid: {
                descriptionContainer: {
                    paddingHorizontal: ThemeMargins?.gutter,
                    paddingVertical: 10,
                    flex: 1,
                    height: 300
                },
                description: {
                    ...ThemeText.Paragraph,
                    marginHorizontal: 0,
                    marginVertical: 0
                },
                ministryContainer: {
                    flexDirection: isMobile ? 'column' : 'row',
                    borderBottomColor: ThemeColors?.grey2,
                    borderBottomWidth: StyleSheet.hairlineWidth
                },
                ministryGridContainer: {
                    borderTopColor: ThemeColors?.grey2,
                    borderTopWidth: StyleSheet.hairlineWidth,
                    flex: 1
                },
                visitButtonIcon: {
                    color: ThemeColors.navy,
                    name: 'chevron-circle-right',
                    size: 20,
                    container: {
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        marginHorizontal: ThemeMargins.inset1
                    }
                },
                visitButtonTitle: isMobile ? { ...ThemeText.H3, color: ThemeColors?.navy } : { ...ThemeText.H5, color: ThemeColors?.navy }
            },
            NameAcquisition: {
                errorText: {
                    ...ThemeText?.Paragraph,
                    marginVertical: 0,
                    marginHorizontal: 0,
                    fontFamily: 'GothamBook',
                    color: 'red',
                    paddingTop: 10,
                    textAlign: 'center'
                },
                small: {
                    ...ThemeText.ParagraphSmall,
                    color: ThemeColors.white,
                    fontSize: 12,
                    textAlign: 'center'
                },
                placeholderStyle: {
                    ...ThemeText?.Paragraph,
                    marginVertical: 0,
                    marginHorizontal: 0,
                    fontFamily: 'GothamBook',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center',
                    borderRadius: 4
                },
                placeholderTextColor: ThemeColors?.grey2,
                errorTitle: {
                    ...ThemeText.H5,
                    paddingVertical: 20,
                    textAlign: 'center'
                },
                errorBtnStyle: {
                    color: ThemeColors.white,
                    fontFamily: 'GothamBook',
                    fontSize: 20,
                    backgroundColor: ThemeColors?.dodgerbluelight,
                    width: 80,
                    height: 40
                },
                inputStyle: {
                    width: '25%',
                    padding: 10,
                    color: ThemeColors?.black
                },
                inputContainerStyle: {
                    backgroundColor: 'white',
                    borderRadius: 4,
                    alignItems: 'center'
                },
                buttonDisabledStyle: {
                    opacity: 0.5,
                    borderColor: ThemeColors?.grey,
                    borderWidth: 0.75,
                    backgroundColor: ThemeColors?.grey
                },
                buttonStyle: {
                    borderWidth: 0.75,
                    borderColor: ThemeColors?.white
                },
                buttonTitleStyle: {
                    color: ThemeColors?.white,
                    fontFamily: 'GothamBook',
                    fontSize: 16
                },
                formContainer: {
                    alignContent: 'center',
                    flex: 1,
                    justifyContent: 'center',
                    paddingHorizontal: ThemeMargins?.gutter,
                    backgroundColor: ThemeColors?.navy
                },
                overlayStyle: {
                    boxShadow: '0,0,0,0',
                    borderColor: ThemeColors?.grey3,
                    borderWidth: 2,
                    width: isMobile ? width : width / 2
                },
                requestOverlayStyle: {
                    backgroundColor: ThemeColors?.white,
                    borderWidth: 0,
                    justifyContent: 'center',
                    marginHorizontal: 20,
                    marginVertical: 5
                },
                requestButtonOverlayTitle: {
                    fontFamily: 'GothamBook',
                    fontSize: 16
                }
            },
            NavSearchIcon: ThemeColors?.navy,
            NavSearchIconContainer: {
                borderLeftColor: ThemeColors?.grey3,
                borderLeftWidth: StyleSheet.hairlineWidth,
                paddingLeft: 5
            },
            NewsArticleCard: {
                date: {
                    ...ThemeText?.H4,
                    marginHorizontal: 0,
                    color: ThemeColors?.grey
                },
                title: {
                    ...ThemeText.H3,
                    width: '100%'
                },
                author: {
                    ...ThemeText?.Paragraph,
                    marginVertical: 0,
                    marginHorizontal: 0,
                    fontFamily: 'GothamBook',
                    color: ThemeColors?.black
                },
                contentContainer: {
                    borderColor: ThemeColors?.lightgrey,
                    borderWidth: StyleSheet.hairlineWidth,
                    flex: 1,
                    flexDirection: 'row'
                },
                category: {
                    ...ThemeText?.H6
                }
            },
            NewsArticleGrid: {
                placeholderStyle: {
                    ...ThemeText?.Paragraph,
                    marginVertical: 0,
                    marginHorizontal: 0,
                    fontFamily: 'GothamBook',
                    color: ThemeColors?.grey1,
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    padding: 2
                },
                dropdownItemTextColor: ThemeColors?.grey1
            },
            NewsArticle: {
                tagTextStyle: {
                    ...ThemeText?.Paragraph,
                    marginVertical: 0,
                    marginHorizontal: 0,
                    fontFamily: 'GothamBook',
                    color: ThemeColors?.navyDark
                },
                tagLabelTextStyle: {
                    ...ThemeText?.Paragraph,
                    marginVertical: 0,
                    marginHorizontal: 0,
                    fontFamily: 'GothamBook',
                    color: ThemeColors?.grey
                },
                authorDate: {
                    ...ThemeText?.Paragraph,
                    marginVertical: 0,
                    marginHorizontal: 0,
                    fontFamily: 'GothamBook',
                    color: ThemeColors?.black
                },
                buttonIcon: {
                    color: ThemeColors?.navy,
                    name: 'chevron-circle-right',
                    size: isMobile ? 16 : 32
                },
                buttonTitle: {
                    ...ThemeText?.Paragraph,
                    marginVertical: 0,
                    marginHorizontal: 0,
                    fontFamily: 'GothamBook',
                    color: ThemeColors.navy
                },
                buttonStyle: {
                    backgroundColor: ThemeColors?.white,
                    borderColor: ThemeColors.navy,
                    borderWidth: 2,
                    borderRadius: 10
                }
            },
            Pagination: {
                activeBackgroundColor: ThemeColors?.white,
                activeBorderColor: ThemeColors?.navy,
                backgroundColor: ThemeColors.grey3,
                borderColor: ThemeColors.grey3
            },
            Photo: {
                description: {
                    color: ThemeColors?.white,
                    fontFamily: 'GothamBook',
                    fontSize: 16,
                    letterSpacing: 0.8,
                    lineHeight: 24
                },
                title: {
                    color: ThemeColors?.white,
                    textAlign: 'left'
                },
                leftButtonIcon: {
                    color: ThemeColors?.white,
                    name: 'chevron-left',
                    size: 40
                },
                rightButtonIcon: {
                    color: ThemeColors?.white,
                    name: 'chevron-right',
                    size: 40
                }
            },
            PhotoGallery: {
                photoBtn: {
                    borderRadius: 6,
                    borderWidth: 0.5,
                    height: 10,
                    marginHorizontal: 5,
                    marginVertical: 5,
                    width: 10,
                    backgroundColor: ThemeColors.grey3,
                    borderColor: ThemeColors.navy
                }
            },
            PhotoGrid: {
                overlayTitle: {
                    ...ThemeText.H3,
                    color: ThemeColors?.white,
                    width: '100%'
                },
                overlayDescription: {
                    ...ThemeText?.ParagraphSmall,
                    color: ThemeColors?.white
                }
            },
            ServiceBlock: {
                desktopContainer: {
                    flexDirection: 'row',
                    maxHeight: 225,
                    minHeight: 225,
                    marginHorizontal: ThemeMargins?.gutter,
                    marginTop: 20
                },
                desktopText: {
                    ...ThemeText?.Paragraph,
                    marginVertical: 0,
                    marginHorizontal: 0,
                    fontFamily: 'GothamBook',
                    color: ThemeColors?.navy,
                    paddingRight: 4
                },
                desktopDateTime: {
                    ...ThemeText?.Paragraph,
                    marginVertical: 0,
                    marginHorizontal: 0,
                    fontFamily: 'GothamBook',
                    color: ThemeColors?.grey,
                    borderLeftColor: ThemeColors?.grey,
                    borderLeftWidth: 0.75,
                    paddingLeft: 4,
                    width: '60%'
                },
                container: {
                    flexDirection: 'row',
                    borderBottomColor: ThemeColors?.grey3,
                    marginTop: 10,
                    borderBottomWidth: 1,
                    minHeight: 225,
                    maxHeight: 225
                },
                text: {
                    ...ThemeText?.Paragraph,
                    marginVertical: 0,
                    marginHorizontal: 0,
                    fontFamily: 'GothamBook',
                    marginLeft: 1,
                    color: ThemeColors?.navy,
                    paddingRight: isMobile ? 0 : 4
                },
                dateTime: {
                    ...ThemeText?.Paragraph,
                    marginVertical: 0,
                    marginHorizontal: 0,
                    fontFamily: 'GothamBook',
                    color: ThemeColors?.grey,
                    borderLeftColor: isMobile ? undefined : ThemeColors?.grey,
                    borderLeftWidth: isMobile ? undefined : 0.75,
                    paddingLeft: isMobile ? 0 : 4
                }
            },
            ServiceContainer: {
                dropdownTextColor: ThemeColors?.navy,
                dropdownIconColor: ThemeColors?.black,
                placeholderStyle: {
                    ...ThemeText?.Paragraph,
                    marginHorizontal: 0,
                    fontFamily: 'GothamBook',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    padding: 2,
                    marginVertical: 0
                }
            },
            SiteFooter: {
                affiliatesContainer: {
                    marginVertical: 20,
                    paddingHorizontal: isMobile ? 4 : 40,
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    width: '100%'
                },
                affiliateContainer: {
                    alignItems: 'center',
                    flexDirection: 'row',
                    width: '100%'
                },
                affiliatesLeftCol: {
                    width: '50%'
                },
                affiliatesRightCol: {
                    width: isMobile ? '50%' : undefined,
                    right: '-2%'
                },
                affiliateImage: {
                    height: Platform.OS === 'web' && !isMobile ? 50 : 25,
                    margin: 5,
                    width: Platform.OS === 'web' && !isMobile ? 50 : 25
                },
                affiliateText: {
                    color: ThemeColors?.navy,
                    flex: Platform.OS === 'web' ? 1 : undefined,
                    flexShrink: Platform.OS === 'web' ? 1 : undefined,
                    fontFamily: 'GothamMedium',
                    fontSize: Platform.OS === 'web' && !isMobile ? 14 : 12,
                    maxWidth: Platform.OS === 'web' ? undefined : '75%'
                },
                container: {
                    flex: 1,
                    alignItems: 'center'
                },
                containerRow: {
                    backgroundColor: ThemeColors?.white,
                    width: '100%'
                },
                contactContainer: {
                    flex: 1
                },
                horizontalLineColor: ThemeColors?.grey,
                legalContainer: {
                    alignItems: 'center',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    justifyContent: 'center',
                    marginVertical: 10
                },
                legalText: {
                    fontFamily: 'GothamBook',
                    color: ThemeColors.grey,
                    fontSize: Platform.OS === 'web' ? 14 : 7.5,
                    letterSpacing: -0.5,
                    lineHeight: Platform.OS === 'web' ? 18 : 12,
                    marginBottom: 0
                },
                locationAddress: {
                    ...ThemeText.Grey1,
                    flex: 0.5,
                    justifyContent: 'flex-start',
                    fontSize: Platform.OS === 'web' ? 14 : 10
                },
                locationAddressCol: {
                    flex: 0.5
                },
                locationContainer: {
                    alignItems: 'center',
                    flex: 1,
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    marginHorizontal: isMobile ? ThemeMargins.gutter : ThemeMargins?.inset1,
                    marginVertical: 10
                },
                locationEmail: {
                    ...ThemeText.Navy,
                    fontFamily: 'GothamMedium',
                    fontSize: Platform.OS === 'web' ? 14 : 10
                },
                locationPhoneEmailCol: {
                    flex: 0.5,
                    alignItems: 'flex-end'
                },
                locationPhoneNumber: {
                    ...ThemeText.Grey1,
                    fontSize: Platform.OS === 'web' ? 14 : 10
                },
                privacyLink: {
                    fontFamily: 'GothamBook',
                    color: ThemeColors?.navy,
                    fontSize: Platform.OS === 'web' ? 14 : 7.5,
                    letterSpacing: -0.5,
                    lineHeight: Platform.OS === 'web' ? 18 : 12,
                    marginLeft: 5
                },
                socialsContainer: {
                    flex: 0.5,
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    justifyContent: 'flex-end'
                },
                socialIconSize: isDesktopOrLaptop ? 32 : 20,
                socialIconStyle: undefined,
                socialIconTypeFacebook: 'facebook',
                socialIconTypeTwitter: 'twitter',
                socialIconTypeInstagram: 'instagram',
                socialIconTypeYoutube: 'youtube',
                socialIconViewStyle: {
                    backgroundColor: ThemeColors?.transparent,
                    marginHorizontal: -5
                },
                topLinksContainer: {
                    flex: 0.5,
                    flexDirection: 'row',
                    flexWrap: isMobile ? 'wrap' : 'nowrap',
                    justifyContent: 'flex-start'
                },
                topLinkSocialsContainer: {
                    alignItems: 'center',
                    flex: 1,
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    marginHorizontal: isMobile ? ThemeMargins?.gutter : ThemeMargins?.inset1
                },
                topLink: {
                    ...ThemeText.Navy,
                    fontSize: Platform.OS === 'web' ? 16 : 11,
                    flexWrap: 'wrap',
                    paddingRight: 4
                }
            },
            SocialPostContainer: {
                instagramParagraph: {
                    ...ThemeText?.Paragraph,
                    marginVertical: 0,
                    fontFamily: 'GothamBook',
                    color: ThemeColors.grey,
                    marginHorizontal: ThemeMargins.gutter,
                    textAlign: 'center'
                },
                socialPostHandle: {
                    ...ThemeText?.Paragraph,
                    marginVertical: 0,
                    marginHorizontal: 0,
                    fontFamily: 'GothamBook',
                    color: ThemeColors?.white,
                    textAlign: 'center'
                },
                postPlusImageHandleMobile: {
                    ...ThemeText?.Paragraph,
                    marginVertical: 0,
                    marginHorizontal: 0,
                    fontFamily: 'GothamBook',
                    color: ThemeColors.grey,
                    paddingLeft: 10
                },
                postPlusImageHandle: {
                    ...ThemeText?.Paragraph,
                    marginVertical: 0,
                    marginHorizontal: 0,
                    fontFamily: 'GothamBook',
                    color: ThemeColors.grey,
                    paddingLeft: 10
                },
                socialParagraph: {
                    ...ThemeText?.Paragraph,
                    marginTop: 0,
                    fontFamily: 'GothamBook',
                    color: ThemeColors?.white,
                    marginBottom: 15,
                    marginHorizontal: ThemeMargins.gutter,
                    textAlign: 'center'
                }
            },
            TabbedAlternatingContainer: {
                activeTabName: {
                    color: ThemeColors?.red
                },
                activeTabUnderline: {
                    borderBottomColor: ThemeColors?.red,
                    borderBottomWidth: 2
                },
                inactiveTabName: {
                    color: ThemeColors?.grey
                },
                scrollViewStyle: {
                    width: isMobile ? '100%' : '50%',
                    height: 300,
                    backgroundColor: ThemeColors?.grey4
                },
                tabbedTitle: {
                    ...ThemeText?.H4,
                    color: ThemeColors.grey,
                    marginBottom: 2,
                    letterSpacing: -1
                },
                title: {
                    ...ThemeText?.H1,
                    marginBottom: 40,
                    marginHorizontal: ThemeMargins.gutter
                },
                heading: {
                    ...ThemeText.H5,
                    textAlign: isMobile ? 'center' : 'left',
                    marginHorizontal: ThemeMargins.gutter,
                    marginTop: ThemeMargins.gutter
                },
                description: {
                    ...ThemeText?.Paragraph,
                    marginTop: 0,
                    fontFamily: 'GothamBook',
                    marginHorizontal: ThemeMargins.gutter,
                    marginBottom: ThemeMargins.gutter
                },
                btnContainer: {
                    alignItems: isMobile ? 'center' : 'flex-start',
                    marginBottom: ThemeMargins.gutter,
                    marginHorizontal: ThemeMargins.gutter
                },
                btnTitleStyle: {
                    color: ThemeColors?.grey,
                    fontSize: dynamicFontSize(16),
                    lineHeight: null,
                    marginHorizontal: 40,
                    fontFamily: 'GothamBook'
                }
            },
            TabbedContainer: {
                desktopContainer: {
                    alignItems: 'center',
                    flexDirection: 'row',
                    flex: 1,
                    justifyContent: 'center',
                    marginHorizontal: ThemeMargins?.gutterSmall
                },
                dropdown: {
                    backgroundColor: 'transparent',
                    borderBottomLeftRadius: 0,
                    borderBottomRightRadius: 0,
                    borderBottomWidth: 0,
                    borderLeftWidth: 0,
                    borderRightWidth: 0,
                    borderTopWidth: 0,
                    width: '100%'
                },
                dropdownPlaceholder: {
                    ...ThemeText?.Paragraph,
                    marginVertical: 0,
                    marginHorizontal: 0,
                    fontFamily: 'GothamBook',
                    color: ThemeColors?.black
                },
                dropdownTextColor: ThemeColors?.black,
                dropdownIconColor: ThemeColors?.white,
                horizontalLineColor: ThemeColors?.white,
                imageOverlayTitle: {
                    ...ThemeText?.H3,
                    color: ThemeColors?.black
                },
                overlay: {
                    bottom: 0,
                    height: '100%',
                    left: 0,
                    position: 'absolute',
                    right: 0,
                    width: '100%'
                },
                textWrapper: {
                    display: 'flex',
                    flexDirection: 'column',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    height: '100%',
                    width: '100%'
                },
                title: {
                    ...ThemeText?.Paragraph,
                    marginVertical: 10,
                    marginHorizontal: 0,
                    width: '100%',
                    fontFamily: 'GothamBook',
                    color: ThemeColors?.black
                }
            },
            TabbedContainerItem: {
                container: {
                    flex: 0.5,
                    justifyContent: 'center',
                    alignItems: 'center',
                    pointerEvents: 'auto'
                },
                desktopContainer: {
                    flexDirection: 'row',
                    width: '100%',
                    flexWrap: 'wrap',
                    paddingVertical: ThemeMargins?.gutter,
                    alignItems: 'center'
                },
                headline: {
                    ...ThemeText?.H3,
                    color: ThemeColors.black
                },
                btnStyle: {
                    borderColor: ThemeColors?.white,
                    borderWidth: 0.75,
                    padding: 4,
                    width: isTablet ? '25%' : '50%'
                },
                btnTitle: {
                    ...ThemeText?.Paragraph,
                    marginVertical: 0,
                    marginHorizontal: 0,
                    fontFamily: 'GothamBook',
                    color: ThemeColors?.white
                },
                headlineMobile: {
                    ...ThemeText?.Paragraph,
                    marginVertical: 0,
                    marginHorizontal: 0,
                    fontFamily: 'GothamBook',
                    color: ThemeColors?.white
                },
                verticalBtnStyle: {
                    borderColor: ThemeColors?.white,
                    borderWidth: 0.75,
                    width: isTablet ? '33%' : '50%'
                }
            },
            TextPlusImageWithLink: {
                btnContainerStyle: {
                    alignItems: 'flex-start',
                    marginTop: 10,
                    paddingBottom: 10
                },
                btnIcon: {
                    color: ThemeColors?.navy,
                    name: 'chevron-circle-right',
                    size: isDesktopOrLaptop ? 24 : 20,
                    type: 'font-awesome-5'
                },
                btnStyle: {
                    paddingHorizontal: 0,
                    paddingVertical: 0,
                    marginVertical: ThemeMargins?.gutter - 20
                },
                btnTitleStyle: {
                    color: ThemeColors?.navy,
                    fontFamily: 'GothamBook',
                    textAlign: 'left',
                    fontSize: 18,
                    letterSpacing: 0,
                    lineHeight: 20
                },
                btnType: 'clear',
                card: {
                    flexDirection: 'row',
                    flex: 1,
                    justifyContent: 'center',
                    borderBottomColor: ThemeColors?.grey3,
                    borderBottomWidth: 0.5,
                    borderTopColor: ThemeColors?.grey3,
                    borderTopWidth: 0.5
                },
                description: {
                    fontFamily: dynamicFontFamily('GothamBook'),
                    color: ThemeColors.grey2,
                    fontSize: dynamicFontSize(16),
                    lineHeight: dynamicLineHeight(22, dynamicFontSize(16)),
                    letterSpacing: 0,
                    marginLeft: 0,
                    marginRight: 0
                },
                desktopContainer: {
                    flex: 1,
                    alignItems: 'center'
                },
                heading: {
                    ...ThemeText?.H3,
                    color: ThemeColors.black,
                    margin: 0,
                    marginBottom: 10
                },
                imageContainer: {
                    width: isDesktopOrLaptop ? 300 : 250,
                    height: isDesktopOrLaptop ? 300 : 250
                },
                imageContainerStyle: {
                    width: '100%',
                    height: '100%',
                    flexShrink: 2
                },
                imageStyle: {
                    height: '100%',
                    width: '100%',
                    aspectRatio: 1
                },
                mobileBtnContainerStyle: undefined,
                mobileBtnStyle: undefined,
                mobileContainer: {
                    flex: 1,
                    width: width,
                    alignItems: 'center',
                    paddingHorizontal: ThemeMargins?.gutter
                },
                mobileDescription: {
                    fontFamily: dynamicFontFamily('GothamBook'),
                    fontSize: dynamicFontSize(17),
                    lineHeight: dynamicLineHeight(24, dynamicFontSize(17)),
                    color: ThemeColors.grey,
                    letterSpacing: 0,
                    marginVertical: 10
                },
                mobileHeading: {
                    ...ThemeText?.ComponentTitle,
                    paddingVertical: 0,
                    margin: 0,
                    flex: 1
                },
                mobileImageContainer: {
                    width: '100%',
                    height: '100%',
                    flexShrink: 2
                },
                mobileImageStyle: {
                    height: '100%',
                    width: '100%',
                    aspectRatio: 1
                },
                mobileTextContentContainer: {
                    width: '100%',
                    marginTop: 10
                },
                scrollContentContainer: {
                    flex: 1,
                    justifyContent: 'center'
                },
                scrollStyle: {
                    height: isDesktopOrLaptop ? 300 : 250,
                    paddingVertical: ThemeMargins?.gutter
                },
                scrollContentView: {
                    maxHeight: isDesktopOrLaptop ? 300 : 250,
                    paddingVertical: ThemeMargins?.gutter,
                    paddingHorizontal: ThemeMargins?.gutter
                }
            },
            TwoColumnWYSIWYG: {
                container: {
                    justifyContent: 'center',
                    flexWrap: 'wrap',
                    flex: 1,
                    flexDirection: 'row'
                },
                contentContainer: {
                    flex: 1,
                    alignItems: 'center',
                    marginHorizontal: ThemeMargins?.gutter
                },
                leftCol: {
                    flex: 1,
                    alignItems: 'center'
                },
                leftColMarginRight: 0,
                oneCol: {
                    flex: 1,
                    alignItems: 'center',
                    flexWrap: 'wrap'
                },
                paragraph: {
                    ...ThemeText?.Paragraph,
                    marginTop: 0,
                    marginLeft: 0,
                    fontFamily: 'GothamBook',
                    marginRight: ThemeMargins?.gutter - 10,
                    textAlign: 'left',
                    marginBottom: 10
                },
                rightCol: {
                    flex: 1,
                    alignItems: 'center'
                },
                title: {
                    ...ThemeText?.ComponentTitle,
                    margin: 0,
                    width: '100%',
                    paddingBottom: 10,
                    textAlign: 'left'
                }
            }
        }
    };
};
