import React                 from 'react';
import { Button, useTheme}   from '@rneui/themed';
import { get }               from 'lodash';
import PropTypes             from 'prop-types';

const ViewMore = props => 
{
    const {theme}       = useTheme();
    const handler       = get(props, 'handler');
    const size          = get(props, 'size');
    const text          = get(props, 'text');
    
    return (
        <Button buttonStyle={theme?.appComponents?.umbraco?.ListItemContainer?.viewMoreBtnStyle}
                icon={{
                ...theme?.appComponents?.umbraco?.ListItemContainer?.viewMoreIcon,
                   name: text.equals('more') ? 'plus' : 'minus',
                   size: size,
                }}
                iconPosition='top'
                onPress={handler}
                title={`View ${text.initCaps()}`}
                titleStyle={theme?.appComponents?.umbraco?.ListItemContainer?.viewMoreBtnTitle}
                testID='ViewMore.Button'
        />
    );
};

ViewMore.defaultProps = {
    text: 'more',
    color: '#000',
    handler: () => {},
    size: 14
};

ViewMore.propTypes = {
    text: PropTypes.string,
    handler: PropTypes.func,
    size: PropTypes.number
};

export default ViewMore;
