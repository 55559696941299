import React, { useContext, useEffect, useReducer, useState } from 'react';
import Constants from 'expo-constants';
import AppContext from './app';
import { useApi, usePersistedState } from '../hooks';

const PERSISTENCE_KEY = 'saca';

const ActionTypes = Object.freeze({
    LOGIN: 0,
    REGISTER: 1
});

const initialState = {
    authority: undefined,
    user: {
        accessToken: undefined,
        email: undefined,
        loggedIn: false,
        refreshToken: undefined,
        registered: false,
        details: undefined
    }
};

const AuthContext = React.createContext();

const AuthReducer = (state, action) => {
    const { type, data } = action;

    if (ActionTypes.LOGIN === type) {
        return {
            ...state,
            user: {
                ...data
            }
        };
    } else if (ActionTypes.REGISTER === type) {
        console.log('handle registion action');
    }
};

const AuthContextProvider = props => {
    const { fetch } = useApi();
    const { state: appState } = useContext(AppContext);
    const [initialized, setInitialized] = useState(false);
    const { state, dispatch } = usePersistedState(PERSISTENCE_KEY, initialState, AuthReducer);
    const enabled = Constants.manifest.extra?.enableAuth?.toBoolean();

    useEffect(() => {
        const init = async () => {
            const endpoint = `${appState.settings.openIdSettings.authority}/.well-known/openid-configuration`;
            const response = await fetch(endpoint);

            if (response) {
                state.authority = response;
            }
            // TJC = Need to re-investigate how to handle issues with failed configuration fetching
            setInitialized(true);
        };

        if (enabled && appState.settings?.openIdSettings) {
            init();
        }
    }, [appState.settings, enabled, fetch, state]);

    if (enabled && !initialized) {
        return null;
    }

    return <AuthContext.Provider value={{ state, dispatch }}>{props.children}</AuthContext.Provider>;
};

export { ActionTypes, AuthContextProvider };

export default AuthContext;
