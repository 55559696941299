import React, { useCallback, useContext, useMemo, useState } from 'react';
import { Linking, View } from 'react-native';
import { useTheme, Button, SearchBar, Text } from '@rneui/themed';
import DropDownPicker from 'react-native-dropdown-picker';
import { get } from 'lodash';
import moment from 'moment';
import { collectAncillaryOptions } from '../../../common/helpers/navigation';
import AppContext, { AppActions, AppEvents } from '../../../contexts/app';
import useApi from '../../../hooks/useApi';
import NavSearchIcon from './NavSearchIcon';

const Ancillary = props => {
    const { fetchLiveService, fetchPage } = useApi();
    const { state, dispatch } = useContext(AppContext);
    const [menuOpen, setMenuOpen] = useState(false);
    const [searchQuery, setSearchQuery] = useState();
    const { theme } = useTheme();

    const liveService = useMemo(async () => {
        return await fetchLiveService();
    }, [fetchLiveService]);

    const handleMenuSelection = useCallback(
        async e => {
            return e.isExternal ? Linking.openURL(e.value) : await fetchPage(dispatch, e.value);
        },
        [fetchPage, dispatch]
    );

    const options = useMemo(() => collectAncillaryOptions(props.siteWrapper), [props.siteWrapper]);

    const renderLinks = () => {
        const renderWatchLive = () => {
            if (!liveService) {
                return null;
            } else {
                const upcomingDateTime = get(liveService, 'upcomingDateTime');
                const durationMinutes = get(liveService, 'durationMinutes');
                const now = moment();
                const upcomingMoment = moment(upcomingDateTime);
                const endingMoment = moment(upcomingDateTime).add(durationMinutes, 'm');

                if (!now.isBetween(upcomingMoment, endingMoment)) {
                    return null;
                }
            }

            return (
                <Button
                    containerStyle={{ ...theme?.navigation?.web?.Ancillary?.liveButton, alignSelf: 'center' }}
                    onPress={async () => await fetchPage(dispatch, '/watch')}
                    buttonStyle={{ backgroundColor: theme?.colors?.red, padding: 0 }}
                    radius={10}
                    title='Live'
                    titleStyle={{ ...theme?.text?.White, fontSize: 12, fontFamily: 'GothamBook' }}
                />
            );
        };

        return options.links.map(link => {
            const handlePress = async () => {
                link.isExternal ? Linking.openURL(link.url) : await fetchPage(dispatch, link.url);
            };

            return (
                <View key={link.text} style={{ cursor: 'pointer', margin: 20 }} testID={`Ancillary.Link.${link.text}`}>
                    <Text
                        alt={link.text}
                        onPress={handlePress}
                        style={theme?.navigation?.web?.Ancillary?.link?.title}
                        testID='Ancillary.Link.Text'
                    >
                        {link.text}
                    </Text>
                    {link.text.equals('Watch', false) && renderWatchLive()}
                </View>
            );
        });
    };

    const renderMenus = () => {
        return options.menus.map(menu => {
            const items = menu.entries.map(entry => {
                return {
                    label: get(entry, 'text'),
                    labelStyle: theme?.text?.Navy,
                    value: get(entry, 'url'),
                    isExternal: get(entry, 'isExternal'),
                    testID: 'MenuItem'
                };
            });

            return (
                <View key={menu.name} style={{ zIndex: 100 }} testID='Ancillary.Menus.Dropdown.Container'>
                    <DropDownPicker
                        closeAfterSelecting={true}
                        items={items}
                        listMode='SCROLLVIEW'
                        listParentContainerStyle={{ zIndex: 100 }}
                        open={menuOpen}
                        placeholder={menu.name}
                        placeholderStyle={theme?.navigation?.web?.Ancillary?.dropdown?.placeholderStyle}
                        setOpen={() => setMenuOpen(!menuOpen)}
                        onSelectItem={item => handleMenuSelection(item)}
                        style={theme?.navigation?.web?.Ancillary?.dropdown?.container}
                        textColor={theme?.colors?.navy}
                        testID={`Web.AncillaryMenu-${menu.name}`}
                        zIndex={-1}
                    />
                </View>
            );
        });
    };

    if (!props.siteWrapper) {
        return null;
    } else if (state?.action?.data?.page) {
        const hasAncillaryNavigation = get(state.action.data.page, 'hasAncillaryNavigation');

        if (!hasAncillaryNavigation) {
            return null;
        }
    }

    return (
        <View>
            <View style={theme?.navigation?.web?.Ancillary?.headerContainer}>
                <View style={theme?.navigation?.web?.Ancillary?.leftContainer} testID='Ancillary.Links.Container'>
                    {renderLinks()}
                </View>
                <View style={theme?.navigation?.web?.Ancillary?.rightContainer} testID='Ancillary.Menus.Container'>
                    {renderMenus()}
                    <View style={{ alignContent: 'center' }}>
                        <NavSearchIcon />
                    </View>
                </View>
            </View>
            {state?.search?.state?.equals(AppEvents.SEARCH_DIALOGUE_STATE_OPEN) && (
                <SearchBar
                    containerStyle={{
                        backgroundColor: theme?.colors?.grey4,
                        borderColor: theme?.colors?.grey3,
                        borderWidth: 0.25,
                        borderRadius: 5
                    }}
                    placeholder='Search ...'
                    placeholderTextColor={theme?.colors?.navy}
                    onCancel={() => {
                        dispatch({
                            type: AppActions.SEARCH_STATE_CHANGE,
                            event: AppEvents.SEARCH_QUERY_CLOSED
                        });
                    }}
                    onChangeText={query => setSearchQuery(query)}
                    onSubmitEditing={() => {
                        setSearchQuery('');
                        dispatch({
                            type: AppActions.SEARCH_STATE_CHANGE,
                            event: AppEvents.SEARCH_QUERY_SUBMITTED,
                            data: searchQuery
                        });
                    }}
                    round={true}
                    searchIcon={{
                        color: theme?.colors?.navy,
                        name: 'search',
                        size: 20,
                        type: 'material'
                    }}
                    style={[theme?.text?.General, { color: theme?.colors?.black }]}
                    testID='Ancillary.SearchBar'
                    value={searchQuery}
                />
            )}
        </View>
    );
};

export default Ancillary;
