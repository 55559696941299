import React                             from 'react';
import { ActivityIndicator, View }       from 'react-native';
import { useTheme }                      from '@rneui/themed';
import PropTypes                         from 'prop-types';

const Loading = ({ color, size = 'large' }) => 
{  
    const { theme } = useTheme();
    
    return (
        <View style={theme?.appComponents?.common?.Loading?.Container}>
            <ActivityIndicator color={color || theme?.appComponents?.common?.Loading?.ActivityIndicator.color}
                               size={size}
                               style={{ 
                                    alignItems: 'center', 
                                    backgroundColor: theme?.colors?.grey3,
                                    borderRadius: 10,
                                    justifyContent: 'center',
                                    padding: 10
                                }}
            />
        </View>
    );
};

Loading.propTypes = {
    color: PropTypes.string,
    size: PropTypes.string
};

export default Loading;
