import React, { useContext, useState } from 'react';
import { View } from 'react-native';
import { useTheme, Button, CheckBox, Input } from '@rneui/themed';
import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import AppContext from '../../../../contexts/app';
import RegistrationContext, { Actions } from '../../../../contexts/registration';
import { useApi } from '../../../../hooks';

const RegistrationStep3 = ({ control, getValues, setError }) => {
    const { post } = useApi();
    const { state } = useContext(AppContext);
    const { dispatch } = useContext(RegistrationContext);
    const [authroized, setAuthorized] = useState(false);
    const { theme } = useTheme();

    const handleSendTextConfirmation = async () => {
        const mobileNumber = getValues('mobileNumber');

        if (mobileNumber) {
            const endpoint = `${state.settings.openIdSettings.authority}/Account/SendVerificationText?phone=${mobileNumber}`;
            const response = await post(endpoint);

            if (response?.success) {
                dispatch({
                    type: Actions.SET_MOBILE_CONFIRM_ID,
                    mobileConfirmationId: response.confirmationCodeId
                });
            } else {
                setError('mobileConfirmationCode', {
                    type: 'custom',
                    message: 'Code does not match our records, please make sure you entered it correctly.'
                });
            }
        }
    };

    return (
        <View style={{ width: '100%' }} testID='Registration.Step3'>
            <View style={{ flexDirection: 'row', width: '100%' }}>
                <Controller
                    control={control}
                    defaultValue=''
                    name='mobileNumber'
                    render={({ field, fieldState }) => {
                        return (
                            <Input
                                {...field}
                                containerStyle={{ width: '100%', marginVertical: 20 }}
                                errorMessage={!fieldState.error ? 'MOBILE PHONE (OPTIONAL) ' : fieldState.error?.message}
                                errorStyle={
                                    !fieldState.error
                                        ? theme?.appComponents?.common?.Authentication?.Registration?.placeholderStyle
                                        : theme?.appComponents?.common?.Authentication?.Registration?.errorStyle
                                }
                                innerRef={field.ref}
                                inputStyle={theme?.appComponents?.common?.Authentication?.Registration?.smallInputStyle}
                                onChangeText={val => field.onChange(val)}
                                textContentType='telephoneNumber'
                                spellCheck={false}
                            />
                        );
                    }}
                />
            </View>
            <CheckBox
                center={true}
                checked={authroized}
                checkedColor={theme?.colors?.red}
                containerStyle={theme?.appComponents?.common?.Authentication?.Registration?.checkboxContainer}
                textStyle={theme?.appComponents?.common?.Authentication?.Registration?.checkboxText}
                //
                // title will be needed to come from Umbraco
                //
                title='I authorize Hagee Ministries to send messages to the mobile number provided above. I understand that message & data rates may apply.'
                onIconPress={() => setAuthorized(current => !current)}
            />
            <View style={{ alignContent: 'space-between', width: '100%' }}>
                <Button
                    buttonStyle={theme.appComponents?.common?.Authentication?.Registration.stepButton}
                    containerStyle={theme.appComponents?.common?.Authentication?.Registration.stepButtonContainer}
                    disabled={!authroized}
                    disabledStyle={theme.appComponents?.common?.Authentication?.Registration.disabledStepButton}
                    disabledTitleStyle={theme.appComponents?.common?.Authentication?.Registration.disabledStepButtonTitle}
                    onPress={() => handleSendTextConfirmation()}
                    title='NEXT STEP'
                    titleStyle={theme.appComponents?.common?.Authentication?.Registration.stepButtonTitle}
                    type='solid'
                />
                <Button
                    buttonStyle={theme.appComponents?.common?.Authentication?.Registration.stepButton}
                    containerStyle={theme.appComponents?.common?.Authentication?.Registration.stepButtonContainer}
                    disabledStyle={theme.appComponents?.common?.Authentication?.Registration.disabledStepButton}
                    disabledTitleStyle={theme.appComponents?.common?.Authentication?.Registration.disabledStepButtonTitle}
                    onPress={() => dispatch({ type: Actions.SKIP })}
                    title='Skip'
                    titleStyle={theme.appComponents?.common?.Authentication?.Registration.stepButtonTitle}
                    type='solid'
                />
            </View>
        </View>
    );
};

RegistrationStep3.propTypes = {
    control: PropTypes.object.isRequired,
    getValues: PropTypes.func.isRequired,
    setError: PropTypes.func.isRequired
};

export default RegistrationStep3;
