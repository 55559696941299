import React, { useCallback }                                     from 'react';
import { View }                                                   from 'react-native';
import { useTheme, BottomSheet, Button, ListItem, Text }          from '@rneui/themed';
import { get }                                                    from 'lodash';
import PropTypes                                                  from 'prop-types';
import { useDeviceInfo }                                          from '../../hooks';

const Dropdown = props => 
{
    const { isDesktopOrLaptop,
            isNarrow,
            isMobile 
        }                                 = useDeviceInfo();
    const { theme }                       = useTheme();
    const items                           = get(props, 'items', []);
    const placeholder                     = get(props, 'placeholder', 'Select Option');
    const placeholderIcon                 = get(props, 'placeholderIcon');
    const placeholderStyle                = get(props, 'placeholderStyle');
    const textColor                       = get(props, 'textColor', theme?.colors?.black);
    const isOpen                          = get(props, 'isOpen', false);
    const value                           = get(props, 'value');
    const isOpenSetter                    = get(props, 'setOpen');
    const valueSetter                     = get(props, 'setValue');
    const forceWeb                        = get(props,'forceWeb',false);
    const iconColor                       = get(props,'iconColor');
    const style                           = get(props,'style')

    const handleSelection = useCallback(value => {
        valueSetter(value);
        isOpenSetter(false);
    }, [ isOpenSetter, valueSetter]);

    const renderItems = useCallback(() => {
        const itemCmps = items.map(item => {
            const key   = get(item, 'key');
            const label = get(item, 'label');
            const value = get(item, 'value');

            return (
                <ListItem key={key} onPress={() => handleSelection(value)} 
                          style={{
                              ...theme?.common?.Row,
                              backgroundColor:theme?.colors?.white,
                              justifyContent:'flex-start',
                              alignItems:'center',
                              width:isDesktopOrLaptop? "50%":'100%',
                              borderBottomColor: !isDesktopOrLaptop ? textColor || theme?.colors?.black : undefined, 
                              borderBottomWidth: !isDesktopOrLaptop ? 0.75 : undefined,
                              backgroundColor: !isDesktopOrLaptop ? 'white' :undefined 

                            }}
                            
                            testID={`Dropdown.${label}`}
                            >
                    {/* Bug defect with stacking */}
                    <ListItem.Content>
                        <ListItem.Title  numberOfLines={1} style={{color: textColor, fontFamily: 'GothamBook'}}>{label}</ListItem.Title>
                    </ListItem.Content>
                </ListItem>
            );
        });

        if (!isDesktopOrLaptop) {
            itemCmps.push(
                <ListItem key='dropdown-cancel' onPress={() => isOpenSetter(false)} containerStyle={{ backgroundColor: theme?.colors?.red}}>
                    <ListItem.Content>
                        <ListItem.Title style={{color: theme?.colors?.white, fontFamily: 'GothamBook'}}>Cancel</ListItem.Title>
                    </ListItem.Content>
                </ListItem>
            );
        }

        return itemCmps;
    }, [])

    const renderForWeb = useCallback(() => {
        if (!isOpen) {
            return null;
        }

        return (
                <View style={theme?.appComponents?.common?.Dropdown?.webContainer}
                        testID='Dropdown.Web.ListContainer'>
                    { renderItems() }
                </View>
        );
    }, [ items, isOpen]);

    const renderForMobile = useCallback(() => {
        if (!isOpen) {
            return null;
        }

        return (
            <BottomSheet modalProps={{}} isVisible={true} onBackdropPress={() => isOpenSetter(false)}>
                { renderItems() }
            </BottomSheet>
        );
    }, [ items, isOpen]);

    if (items.length === 0) {
        return null;
    }

    return (
        <>
            <View style={style? style : { ...theme?.common?.Row, alignItems: 'center', justifyContent: 'center' }}>
                {
                    !isDesktopOrLaptop && !!placeholderIcon && 
                        <Button containerStyle={{width:50, height:50, justifyContent: 'center'}}
                                icon={{
                                  color: theme?.colors?.navy,
                                  name: placeholderIcon,
                                  size: isNarrow ? 10 : isMobile? 15 : 20,
                                  type: theme?.iconKit || 'font-awesome-5'
                                }}
                                onPress={() => isOpenSetter(!isOpen)}
                                type='clear'
                        />
                }
                {
                    !placeholderIcon &&
                        <>
                            
                            <Text numberOfLines={1}
                                style={ placeholderStyle 
                                            ? placeholderStyle 
                                            : { ...theme?.appComponents?.common?.Dropdown?.placeholderStyle, color:textColor }}
                            >
                                { placeholder }
                            </Text>
                            <Button icon={{
                                        color: iconColor,
                                        name: isOpen ? 'chevron-up' : 'chevron-down',
                                        size: 16,
                                        type: theme?.iconKit || 'font-awesome-5',
                                    }}
                                    iconContainerStyle={{ alignItems:'flex-end', justifyContent:'flex-end', paddingRight:2 }}
                                    iconRight={true}
                                    onPress={() => isOpenSetter(!isOpen)}
                                    type='clear' 
                            />
                        </>
                }
            </View>
            { isDesktopOrLaptop ? renderForWeb() : renderForMobile() }
        </>
    );
};



Dropdown.propTypes = {
    props: PropTypes.shape({
        forceWeb:PropTypes.bool,
        isOpen: PropTypes.bool.isRequired,
        items: PropTypes.arrayOf(PropTypes.shape({
            key: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
            value: PropTypes.any.isRequired
        })).isRequired,
        placeholder: PropTypes.string,
        placeholderStyle:PropTypes.object,
        setOpen: PropTypes.func.isRequired,
        setValue: PropTypes.func.isRequired,
        textColor: PropTypes.string,
        iconColor:PropTypes.string,
        value: PropTypes.any
    })
};

export default Dropdown;
