import { Platform, Dimensions } from 'react-native';
import moment from 'moment';

const NumericRegExp = new RegExp(/^\d+$/);
const ValidCreditCardRegExp = new RegExp(
    /^(?:4[0-9]{12}(?:[0-9]{3})?|5[1-5][0-9]{14}|(222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)[0-9]{12}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11}|62[0-9]{14})$/
);
const ValidEmailRegExp = new RegExp(
    /^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)/
);
const ValidPhoneNumberRegExp = new RegExp(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/);
const ValidVisaCardRegExp = new RegExp(/^4[0-9]{12}(?:[0-9]{3})?$/);
const ValidMasterCardRegExp = new RegExp(/^(?:5[1-5][0-9]{2}|222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)[0-9]{12}$/);
const ValidAmexCardRegExp = new RegExp(/^3[47][0-9]{13}$/);
const ValidDiscoverCardRegExp = new RegExp(/^6(?:011|5[0-9]{2})[0-9]{12}$/);
const ValidCountryCodeRegExp = new RegExp(
    /^(AF|AX|AL|DZ|AS|AD|AO|AI|AQ|AG|AR|AM|AW|AU|AT|AZ|BS|BH|BD|BB|BY|BE|BZ|BJ|BM|BT|BO|BQ|BA|BW|BV|BR|IO|BN|BG|BF|BI|KH|CM|CA|CV|KY|CF|TD|CL|CN|CX|CC|CO|KM|CG|CD|CK|CR|CI|HR|CU|CW|CY|CZ|DK|DJ|DM|DO|EC|EG|SV|GQ|ER|EE|ET|FK|FO|FJ|FI|FR|GF|PF|TF|GA|GM|GE|DE|GH|GI|GR|GL|GD|GP|GU|GT|GG|GN|GW|GY|HT|HM|VA|HN|HK|HU|IS|IN|ID|IR|IQ|IE|IM|IL|IT|JM|JP|JE|JO|KZ|KE|KI|KP|KR|KW|KG|LA|LV|LB|LS|LR|LY|LI|LT|LU|MO|MK|MG|MW|MY|MV|ML|MT|MH|MQ|MR|MU|YT|MX|FM|MD|MC|MN|ME|MS|MA|MZ|MM|NA|NR|NP|NL|NC|NZ|NI|NE|NG|NU|NF|MP|NO|OM|PK|PW|PS|PA|PG|PY|PE|PH|PN|PL|PT|PR|QA|RE|RO|RU|RW|BL|SH|KN|LC|MF|PM|VC|WS|SM|ST|SA|SN|RS|SC|SL|SG|SX|SK|SI|SB|SO|ZA|GS|SS|ES|LK|SD|SR|SJ|SZ|SE|CH|SY|TW|TJ|TZ|TH|TL|TG|TK|TO|TT|TN|TR|TM|TC|TV|UG|UA|AE|GB|US|UM|UY|UZ|VU|VE|VN|VG|VI|WF|EH|YE|ZM|ZW)$/
);
const ValidMonthYearRegExp = new RegExp(/^(0[1-9]|1[0-2])([0-9]{2})$/);
const ValidCityStateNameRegExp = new RegExp(/^([a-zA-Z\u0080-\u024F]+(?:. |-| |'))*[a-zA-Z\u0080-\u024F]*$/);
const ValidCVVRegExp = new RegExp(/^[0-9]{3,4}$/);
const ValidRoutingNumberExp = new RegExp(/^\d{9}$/);
const ValidAccountNumberExp = new RegExp(/^\d{12}$/);
const ValidNameRegExp = new RegExp(/^[a-zA-Z- ]+$/);
const ValidDateFormat = new RegExp(/^(0[1-9]|1[0-2])\/?([0-9]{4}|[0-9]{2})$/);
///new RegExp(/^(0[1-9]|1[0-2])\/([0-9]{4})$/);
const ValidDateFormatPart1 = new RegExp(/^(0[1-9]|1[0-2])$/);
const ValidPasswordRegExp = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,24}$/);

const validateEmail = email => {
    if (!ValidEmailRegExp.test(email)) {
        return false;
    }

    return true;
};

const validatePhone = phone => {
    // https://ihateregex.io/expr/phone/
    /* This format US/CAN Numbers only
     +919367788755
     8989829304
     +16308520397
     786-307-3615
     */
    if (!ValidPhoneNumberRegExp.test(phone)) {
        return false;
    }

    return true;
};

const numericOnlyCharactersString = str => {
    if (!NumericRegExp.test(str)) {
        return false;
    }

    return true;
};

const buildImageCdnUrl = (path, height, width) => {
    let query = '?';

    if (width > 0) {
        query += `width=${width}`;
    }

    if (height > 0) {
        query += `&height=${height}`;
    }

    return `${path}${query}`;
};

const formatImageUrl = imgUrl => {
    if (imgUrl && !imgUrl.startsWith('//')) {
        imgUrl = `//${imgUrl}`;
    }

    return imgUrl;
};

const chunkArray = (array, maxChunks) => {
    return array.reduce(
        (accumulator, value) => {
            if (accumulator[accumulator.length - 1].length >= array.length / maxChunks) {
                accumulator.push([]);
            }

            accumulator[accumulator.length - 1].push(value);
            return accumulator;
        },
        [[]]
    );
};

const applyColor = color => {
    if (color) {
        if (!color.startsWith('#')) {
            color = `#${color}`;
        }
    }

    return color;
};

const cleanupInternalUrl = (url, depth) => {
    if (!url) {
        return url;
    }

    const parts = url.substring(1, url.lastIndexOf('/')).split('/');
    const urlSet = [...new Set(parts)];
    let cleaned = '/';
    let count = 0;

    for (const part of urlSet) {
        if (part.length === 0) {
            continue;
        } else if (depth > 0 && count === depth) {
            break;
        }

        cleaned += `${part}/`;
        count++;
    }

    return cleaned;
};

const setCursor = cursor => {
    document.body.style.setProperty('cursor', 'pointer'.equals(cursor) ? 'auto' : cursor);

    const links = document.getElementsByTagName('a');

    if (links && links.length) {
        for (const link of links) {
            link.style.setProperty('cursor', cursor);
        }
    }

    const buttons = document.getElementsByTagName('button');

    if (buttons && buttons.length) {
        for (const button of buttons) {
            button.style.setProperty('cursor', cursor);
        }
    }

    const images = document.getElementsByTagName('img');

    if (images && images.length) {
        for (const image of images) {
            image.style.setProperty('cursor', cursor);
        }
    }
};

const download = (filename, content, contentType) => {
    if (Platform.OS.equals('web')) {
        const blob = new Blob([content], { type: contentType });
        const link = document.createElement('a');
        const url = URL.createObjectURL(blob);

        link.href = url;
        link.download = filename;

        document.body.appendChild(link);

        link.click();

        setTimeout(() => {
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        }, 0);
    }
};

const calculateImageDimensions = (isDesktopOrLaptop, deviceWidth, deviceHeight, actualWidth, actualHeight) => {
    if (!isDesktopOrLaptop) {
        if (deviceHeight && deviceWidth) {
            const ratio = Math.min(deviceWidth / actualWidth, deviceHeight / actualHeight);

            return {
                height: Math.floor(actualHeight * ratio),
                width: Math.floor(actualWidth * ratio)
            };
        }
    } else {
        return {
            height: 600,
            width: '100%'
        };
    }
};

const truncateString = (str, num) => {
    if (str.length <= num) return str;
    return str.slice(0, num) + '...';
};

const formatDateTimeShow30MinOrNot = (dateTime, isAllDay) => {
    const windowWidth = Dimensions.get('window').width;
    const minutes = moment(dateTime).format('mm');
    const isMobile = windowWidth < 414;

    //Handling the 30 minutes marker and mobile device truncation and if an all day event
    if (minutes === '30') {
        return isMobile ? moment(dateTime).format('ddd MMM Do, YYYY, h:mm a') : moment(dateTime).format('dddd MMMM Do, YYYY, h:mm a');
    } else if (minutes === '30' && isAllDay) {
        return isMobile ? moment(dateTime).format('ddd - MMM Do, YYYY, h:mm a') : moment(dateTime).format('dddd - MMMM Do, YYYY, h:mm a');
    } else if (minutes === '30' && !isAllDay) {
        return isMobile ? moment(dateTime).format('ddd - MMM Do, YYYY, h: mm a') : moment(dateTime).format('dddd - MMMM Do, YYYY, h: mm a');
    } else if (minutes !== '30' && isAllDay) {
        return isMobile ? moment(dateTime).format('ddd - MMM Do, YYYY, h a') : moment(dateTime).format('dddd - MMMM Do, YYYY, h a');
    } else {
        return isMobile ? moment(dateTime).format('ddd MMM Do, YYYY, h a') : moment(dateTime).format('dddd MMMM Do, YYYY, h a');
    }
};

const swapElementsInArray = (arr, pos1, pos2) => {
    const temp = arr[pos1];

    arr[pos1] = arr[pos2];

    arr[pos2] = temp;

    return arr;
};

export default {
    validateEmail,
    buildImageCdnUrl,
    formatImageUrl,
    chunkArray,
    applyColor,
    cleanupInternalUrl,
    setCursor,
    download,
    calculateImageDimensions,
    truncateString,
    formatDateTimeShow30MinOrNot,
    swapElementsInArray,
    validatePhone,
    numericOnlyCharactersString,
    NumericRegExp,
    ValidCreditCardRegExp,
    ValidEmailRegExp,
    ValidPhoneNumberRegExp,
    ValidAmexCardRegExp,
    ValidDiscoverCardRegExp,
    ValidMasterCardRegExp,
    ValidVisaCardRegExp,
    ValidCountryCodeRegExp,
    ValidMonthYearRegExp,
    ValidCityStateNameRegExp,
    ValidCVVRegExp,
    ValidRoutingNumberExp,
    ValidAccountNumberExp,
    ValidNameRegExp,
    ValidDateFormat,
    ValidDateFormatPart1,
    ValidPasswordRegExp
};
