import React, { useCallback, useContext, useEffect, useRef, useState, Suspense } from 'react';
import { useWindowDimensions, ScrollView, SafeAreaView, View } from 'react-native';
import { useTheme } from '@rneui/themed';
import Constants from 'expo-constants';
import * as ExpoFileLogger from 'expo-file-logger';
import { debounce, get, isEmpty, values } from 'lodash';
import { findActive } from '../common/helpers/navigation';
import SearchResults from '../components/common/SearchResults';
import { LoggedComponent, UnsupportedPage } from '../components/common';
import { Ancillary, Primary, Secondary, TopNavigation } from '../components/navigation/web';
import SiteFooter from '../components/umbraco/SiteFooter';
import AppContext, { AppActions, AppEvents } from '../contexts/app';
import { generateTheme } from '../themes';
import { Helmet } from 'react-helmet';

const WebView = props => {
    const { state, dispatch } = useContext(AppContext);
    const scrollViewRef = useRef();
    const [isCollapsed, setIsCollapsed] = useState(false);
    const { replaceTheme, theme } = useTheme();
    const { height, width } = useWindowDimensions();
    const footer = get(props.siteWrapper?.contentItems, 'Footer');
    const modernLayout = get(props.siteWrapper, 'modernLayout');

    const scrollTo = useCallback(
        ref => {
            if (!ref?.current) {
                return null;
            }

            ref.current.measureInWindow((_, y) => {
                scrollViewRef?.current?.scrollTo({
                    animated: true,
                    x: 0,
                    y: y
                });
            });
        },
        [scrollViewRef]
    );

    const renderComponent = useCallback(
        item => {
            const documentType = item.nodeTypeAlias;

            try {
                const mappedType = props.componentMapping[documentType];

                if (mappedType) {
                    const UmbracoComponent = mappedType;

                    return (
                        <View key={item.id} style={theme?.appComponents?.wrapper} testID={documentType}>
                            <LoggedComponent name={documentType}>
                                <UmbracoComponent
                                    {...props}
                                    json={item}
                                    isCollapsed={isCollapsed}
                                    references={state.action?.data?.references}
                                    scrollTo={scrollTo}
                                />
                            </LoggedComponent>
                        </View>
                    );
                }
            } catch (e) {
                ExpoFileLogger.log(
                    'error',
                    `Error rendering View: [${state?.action?.data?.page.title}] Component [${documentType}] - ${e.message}`
                );
            }
        },
        [props, isCollapsed, scrollTo, state.action?.data?.page.title, state.action?.data?.references, theme?.appComponents?.wrapper]
    );

    const renderContent = () => {
        const { data, href } = state.action;

        if (
            state.navigation?.secondary?.hasOwnProperty(href?.replaceAll('/', '')) ||
            state.navigation?.secondary?.hasOwnProperty(data?.page?.rootPath?.replaceAll('/', ''))
        ) {
            return (
                <View style={{ flex: 1 }}>
                    <Ancillary {...props} isCollapsed={isCollapsed} />
                    <Secondary {...props} isCollapsed={isCollapsed} />
                </View>
            );
        } else if (data) {
            if (!data.page?.navigationOnly) {
                const contentItems = data.contentItems || data.page.contentItems;

                if (state.search?.term && state.search?.event?.equals(AppEvents.SEARCH_QUERY_SUBMITTED)) {
                    ExpoFileLogger.log('debug', 'Rendering SearchResults');

                    return (
                        <View style={{ flex: 1 }}>
                            <Ancillary {...props} isCollapsed={isCollapsed} />
                            <SearchResults {...props} isCollapsed={isCollapsed} />
                        </View>
                    );
                } else if (contentItems) {
                    if (state.action?.data?.page?.developmentOnly && state.settings?.websiteSettings?.environment?.equals('Production')) {
                        return <UnsupportedPage {...props} />;
                    } else {
                        ExpoFileLogger.log('debug', `Rendering View ${data?.page?.title}`);

                        const shouldRenderSecondary = !isEmpty(
                            state?.navigation?.secondary[findActive(state?.action.href || window.location.pathname)]
                        );

                        return (
                            <View style={theme?.common?.Col}>
                                <Ancillary {...props} isCollapsed={isCollapsed} />
                                {!isCollapsed && (
                                    <>
                                        <Secondary {...props} isCollapsed={isCollapsed} />
                                        <ScrollView ref={scrollViewRef}>
                                            {values(state.action.data?.page?.contentItems).map(cmp => renderComponent(cmp))}
                                            {footer && (
                                                <SiteFooter
                                                    {...footer}
                                                    {...props}
                                                    domain={state?.settings?.websiteSettings?.domain}
                                                    modernLayout={modernLayout}
                                                    footerRefs={props.siteWrapper?.refs}
                                                />
                                            )}
                                        </ScrollView>
                                    </>
                                )}
                                {isCollapsed && (
                                    <View
                                        style={{
                                            flex: 2,
                                            flexDirection: 'row'
                                        }}
                                    >
                                        {shouldRenderSecondary && (
                                            <View
                                                style={{
                                                    flex: '20%',
                                                    overflowY: isCollapsed ? 'hidden' : 'auto'
                                                }}
                                            >
                                                <Secondary {...props} isCollapsed={isCollapsed} />
                                            </View>
                                        )}
                                        <ScrollView ref={scrollViewRef} style={{ flex: '80%' }}>
                                            {values(state.action.data?.page?.contentItems).map(cmp => renderComponent(cmp))}
                                            {footer && (
                                                <SiteFooter
                                                    {...footer}
                                                    {...props}
                                                    domain={state?.settings?.websiteSettings?.domain}
                                                    modernLayout={modernLayout}
                                                    footerRefs={props.siteWrapper?.refs}
                                                />
                                            )}
                                        </ScrollView>
                                    </View>
                                )}
                            </View>
                        );
                    }
                }

                if (!isEmpty(contentItems)) {
                    return (
                        <View style={theme?.common?.Col}>
                            <Ancillary {...props} isCollapsed={isCollapsed} />
                            <Secondary {...props} isCollapsed={isCollapsed} />
                        </View>
                    );
                }
            }
        }
    };

    const renderEmbeddedScripts = () => {
        return values(props.siteWrapper?.contentItems).map(item => {
            if (item.nodeTypeAlias.equals('reactEmbeddedScript')) {
                const EmbeddedScript = props.componentMapping[item.nodeTypeAlias];

                return <EmbeddedScript json={item} key={item.id} />;
            }

            return null;
        });
    };

    useEffect(() => {
        const { pathname } = window.location;
        const href = state?.action?.href;

        if (pathname?.replaceAll('/', '').equals(href?.replaceAll('/', ''))) {
            return;
        }

        window.history.pushState(state?.action, '', href);

        scrollViewRef.current?.scrollTo({
            y: 0,
            animated: true
        });
    }, [state?.action?.href, state.action]);

    useEffect(() => {
        const onResize = debounce(() => {
            ExpoFileLogger.log('debug', 'Regenerating theme(s) based on window resize event');

            replaceTheme(generateTheme(Constants.manifest.extra.appTheme));
        }, 250);

        const onPopState = async e => {
            const entry = e.state;

            if (!entry) {
                return;
            }

            dispatch({
                type: AppActions.NAVIGATE,
                data: entry.data,
                href: entry.href
            });
        };

        window.addEventListener('resize', () => {
            onResize();
        });

        window.addEventListener('popstate', onPopState);

        return () => {
            onResize?.cancel();
            window.removeEventListener('resize', onResize);
            window.removeEventListener('popstate', onPopState);
        };
    }, [replaceTheme, dispatch]);

    useEffect(() => {
        const pathname = state.action.href || window?.location?.pathname;

        if (
            !pathname ||
            pathname.equals('/') ||
            pathname.indexOf('/speaker-pages') !== -1 ||
            pathname.indexOf('/event') !== -1 ||
            pathname.indexOf('/visit') !== -1 ||
            pathname.indexOf('/article') !== -1 ||
            pathname.indexOf('/ministry-programs') !== -1 ||
            pathname.indexOf('/jobs') !== -1 ||
            pathname.indexOf('/campuses') !== -1
        ) {
            setIsCollapsed(false);
        } else if (pathname.equals('/watch') || pathname.indexOf('/featured-service') !== -1 || pathname.indexOf('/sermon-carousel') !== -1) {
            setIsCollapsed(true);
        } else {
            const shouldCollapse = pathname.substring(1, pathname.length - 1).split('/').length > 1;
            setIsCollapsed(shouldCollapse);
        }
    }, [state.action.href]);

    const modernizedLayout = (
        <>
            <Primary {...props} isCollapsed={isCollapsed} />
            {renderContent()}
            {renderEmbeddedScripts()}
        </>
    );
    const legacyLayout = (
        <View style={{ flex: 1, alignItems: 'center' }}>
            <TopNavigation {...props} />
            <ScrollView ref={scrollViewRef}>
                {values(state.action.data?.page?.contentItems).map(cmp => renderComponent(cmp))}
                {footer && (
                    <SiteFooter
                        {...footer}
                        {...props}
                        modernLayout={modernLayout}
                        domain={state?.settings?.websiteSettings?.domain}
                        footerRefs={props.siteWrapper?.refs}
                    />
                )}
            </ScrollView>
        </View>
    );
    {
        renderEmbeddedScripts();
    }

    return (
        <Suspense>
            <SafeAreaView
                style={{
                    flex: 1,
                    flexDirection: 'row',
                    height: height,
                    overflow: 'auto',
                    position: 'fixed',
                    width: width
                }}
                onLayout={props.onLayout}
            >
                <Helmet>
                    <meta charset='utf-8' />
                    <title>{state?.action?.data?.page?.title}</title>
                    <meta name='keywords' content={props?.json?.keywords || ''} />
                    <link rel='canonical' href={window?.location?.href} />
                    {/* Google, Bing, Youtube */}
                    <meta name='description' content={state?.action?.data?.page?.metaDescription} />
                    {/* Facebook */}
                    <meta property='og:title' content={state?.action?.data?.page?.title} />
                    <meta property='og:type' content='Website' />
                    <meta property='og:image' content={`https:${state?.action?.data?.page?.image?.cdnImagePath}`} />
                    <meta property='og:url' content={state?.action?.data?.page?.rootPath} />
                    <meta property='og:description' content={state?.action?.data?.page?.metaDescription} />
                    {/* Twitter */}
                    <meta name='twitter:title' content={state?.action?.data?.page?.title} />
                    <meta name='twitter:description' content={state?.action?.data?.page?.metaDescription} />
                    <meta name='twitter:image' content={`https:${state?.action?.data?.page?.image?.cdnImagePath}`} />
                </Helmet>
                {modernLayout && modernizedLayout}
                {!modernLayout && legacyLayout}
            </SafeAreaView>
        </Suspense>
    );
};

export default WebView;
