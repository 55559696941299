import Colors from '../common/colors';

export default {
    ...Colors,
    baseColor:'#fff',
	primaryColor:'#0085c5',
	secondaryColor:'#555',
	tertiaryColor:'#f4f4f4',
    textPrimaryColor:'#337ab7',
    textSecondaryColor:'#fff',
    textBlackColor:'#555',
    textActiveColor:'#d9ac05',
    textInfoColor:'#31708f',
    textSuccessColor:'#3c763d',
    textWarningColor:'#8a6d3b',
    textErrorColor:'#843534',
    textPlaceholderColor: '#8897a2',
    cardGreyBorderColor:'#ddd'

};
