import React, { useContext, useEffect } from 'react';
import { View } from 'react-native';
import { useTheme, Button } from '@rneui/themed';
import { useForm } from 'react-hook-form';
import RegistrationStep1 from './step1';
import RegistrationStep2 from './step2';
import RegistrationStep3 from './step3';
import RegistrationStep4 from './step4';
import RegistrationStep5 from './step5';
import RegistrationContext, { Actions } from '../../../../contexts/registration';
import { useDeviceInfo } from '../../../../hooks';

const Registration = () => {
    const { state, dispatch } = useContext(RegistrationContext);
    const { isMobile } = useDeviceInfo();
    const { theme } = useTheme();

    const {
        clearErrors,
        control,
        formState: { isSubmitting, isSubmitSuccessful, isValid },
        getValues,
        handleSubmit,
        reset,
        setError,
        setValue
    } = useForm({
        mode: 'onTouched'
    });

    useEffect(() => {
        if (isSubmitSuccessful) {
            reset({
                keepDefaultValues: false,
                keepValues: false
            });
        }

        return () =>
            clearErrors(['firstName', 'lastName', 'email', 'confirmEmail', 'country', 'address', 'address2', 'city', 'state', 'zip', 'mobile']);
    }, [clearErrors, isSubmitSuccessful, reset]);

    return (
        <View testID='Registration'>
            {state.activeStep === 1 && <RegistrationStep1 control={control} getValues={getValues} isValid={isValid} setError={setError} />}

            {state.activeStep === 2 && <RegistrationStep2 control={control} getValues={getValues} isValid={isValid} setError={setError} />}

            {state.activeStep === 3 && <RegistrationStep3 control={control} getValues={getValues} isValid={isValid} setError={setError} />}

            {state.activeStep === 4 && <RegistrationStep4 control={control} getValues={getValues} isValid={isValid} setError={setError} />}

            {state.activeStep === 5 && (
                <RegistrationStep5
                    clearErrors={clearErrors}
                    control={control}
                    getValues={getValues}
                    handleSubmit={handleSubmit}
                    isSubmitting={isSubmitting}
                    isValid={isValid}
                    setError={setError}
                    setValue={setValue}
                />
            )}
            {state.activeStep > 1 && (
                <Button
                    containerStyle={{
                        display: state.activeStep > 0 ? 'flex' : 'none',
                        ...theme.appComponents?.common?.Authentication?.Registration?.previousStepButtonContainer
                    }}
                    icon={{
                        color: theme.colors.navy,
                        name: 'arrow-alt-circle-left',
                        size: 16,
                        type: 'font-awesome-5'
                    }}
                    iconContainerStyle={{ overflow: 'visible' }}
                    onPress={() => dispatch({ type: Actions.BACK })}
                    title='Previous Step'
                    titleStyle={theme.appComponents?.common?.Authentication?.previousStepButtonTitle}
                    type='clear'
                />
            )}
        </View>
    );
};

export default Registration;
