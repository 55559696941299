import React                         from 'react';
import { View }                      from 'react-native';
import { useTheme, Button, Text }    from '@rneui/themed';
import { get }                       from 'lodash';
import PropTypes                     from 'prop-types';
import ImageWithOptionalOverlay      from '../common/ImageWithOptionalOverlay';

const Photo = props => 
{
    const { theme }                  = useTheme();
    const title                      = get(props.json, 'title');
    const imageSrc                   = get(props.json, 'image.cdnImagePath');
    const description                = get(props.json, 'description');
    const textColor                  = get(props.json, 'textColor');
    const enableImageOverlay         = get(props.json, 'enableImageOverlay');
    const renderControls             = get(props, 'renderControls');
    const renderNext                 = get(props, 'renderNext');
    const renderPrevious             = get(props, 'renderPrevious');

    return (
        <ImageWithOptionalOverlay alt={title}
                                  containerStyle={{ height:'100%', width:'100%' }}
                                  childContainerStyle={{ height:'100%', width:'100%' }}
                                  enableOverlay={enableImageOverlay}
                                  imagePath={`https:${imageSrc}`}
                                  onPress={props.onPress}
                                  testID='Photo.ImageWithOptionalOverlay'
        >
            {
                renderControls &&
                    <View style={{ 
                              alignItems: 'center',
                              flexDirection: 'row', 
                              justifyContent: 'space-between',
                              marginHorizontal: theme?.margins?.gutter,
                              top: '50%'
                          }}
                    >
                        <Button buttonStyle={{ backgroundColor: 'transparent' }}
                                icon={renderPrevious && 
                                            {
                                                ...theme?.appComponents?.umbraco?.Photo?.leftButtonIcon, 
                                                color: textColor || theme?.appComponents?.umbraco?.Photo?.leftButtonIcon.color,
                                                type: theme?.iconKit
                                            }
                                      }
                                iconContainerStyle={{ margin: 0, padding: 0 }}
                                onPress={() => renderPrevious && props?.onPrevious()}
                                style={{ margin: 0, padding: 0 }}
                                testID='Carousel.Carousel.Item.Previous.Button'
                        />
                        <Button buttonStyle={{ backgroundColor: 'transparent' }}
                                icon={renderNext && 
                                            {
                                                ...theme?.appComponents?.umbraco?.Photo?.rightButtonIcon,
                                                color: textColor || theme?.appComponents?.umbraco?.Photo?.rightButtonIcon.color,
                                                type: theme?.iconKit,
                                            }
                                     }
                                iconContainerStyle={{ margin: 0, padding: 0 }}
                                onPress={() => renderNext && props?.onNext()}
                                testID='Carousel.Carousel.Item.Next.Button'
                        />
                    </View>
            }
            <View style={{ 
                      bottom: theme?.margins?.gutter, 
                      marginHorizontal: theme?.margins?.inset1, 
                      position: 'absolute' 
                  }}
            >
                {
					!!title && title?.length > 0 && 
						<Text numberOfLines={1}
							  style={{
                                  ...theme?.text?.H2,
								  ...theme?.appComponents?.umbraco?.Photo?.title,
								  color: textColor || theme?.appComponents?.umbraco?.Photo?.title?.color ,
							  }}
						>
							{ title }
						</Text>
				}
                {
					!!description && description?.length > 0 && 
						<Text style={{
                                    ...theme?.appComponents.umbraco?.Photo?.description,
                                    color: textColor || theme?.appComponents.umbraco?.Photo?.description.color
                                }}
						>
							{ description }
						</Text>
				}
            </View>
        </ImageWithOptionalOverlay>
    );
};

Photo.defaultProps = {
    containerSize: { height: '100%', width: '100%'},
    controlColor: '#fff',
    onNext: () => {},
    onPress: () => {},
    onPrevious: () => {},
    renderControls: false
};

Photo.propTypes = {
    containerSize: PropTypes.shape({
        height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        width: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    }),
    controlColor: PropTypes.string,
    renderNext: PropTypes.bool,
    renderPrevious: PropTypes.bool,
    onNext: PropTypes.func,
    onPress: PropTypes.func,
    onPrevious: PropTypes.func,
    renderControls: PropTypes.bool,
    json: PropTypes.shape({
        title: PropTypes.string,
        imageSrc: PropTypes.string,
        description: PropTypes.string,
        textColor: PropTypes.string,
        link: PropTypes.shape({
            externalUrl: PropTypes.string,
            internalKey: PropTypes.string
        })
    })
};

export default Photo;
