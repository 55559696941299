import React, { useContext }   from 'react';
import { View }                from 'react-native';
import { useTheme,Icon }       from '@rneui/themed'; 
import { get, values }         from 'lodash';
import { useApi }              from '../../../hooks';
import AppContext              from '../../../contexts/app';

const NavFooter = ({bottomNav}) => 
{
    const {theme}        = useTheme();
    const {fetchPage}    = useApi()
    const { dispatch }   = useContext(AppContext);
    const contentItems   = get(bottomNav.contentItems,'[Bottom Navigation Links].contentItems');
    const iconSize       = 26;

    return (
        <View style={{
                flexDirection:'row',
                height: 50,
                width:'100%',
                alignItems: 'center',
                justifyContent:'space-around',
                backgroundColor: '#4a5a6a'
            }}
        >
            { 
                contentItems &&
                    values(contentItems).map(icon => {
                        const id                 = get(icon, 'id');
                        const text               = get(icon, 'linkText');
                        const fontCharacterClass = get(icon, 'fontCharacterClass');
                        const link               = get(icon, 'link');
                
                        return (
                            <Icon key={id}
                                  name={fontCharacterClass}
                                  size={iconSize}
                                  color={theme?.colors?.white}
                                  type='font-awesome'
                                  containerStyle={{padding:8}}
                                  solid={true}
                                  onPress={async () => await fetchPage(dispatch,link)}
                            />
                        );
                    }) 
            }
        </View>
    );
};

export default NavFooter;
