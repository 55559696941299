import React, { useCallback }     from 'react';
import { Linking }                from 'react-native';
import { useTheme, SocialIcon }   from '@rneui/themed';
import PropTypes                  from 'prop-types';
import Constants                  from '../../common/constants';

const SocialLinks = props => 
{ 
    const { theme } = useTheme(); 
    const { 
        asButton,
        iconColor,
        iconStyle,
        link, 
        size, 
        style,
        type, 
        useBrandColor,
        raised
    }               = props;

    const renderSocialIcon = useCallback(iconType => 
    {
        return (
            <SocialIcon button={asButton} 
                        iconColor={iconColor} //must be a string
                        iconSize={size} 
                        iconStyle={{paddingHorizontal:4,...iconStyle}}
                        iconType={theme?.iconKit || 'font-awesome-5'} 
                        light={true}
                        style={style}
                        type={iconType}
                        raised={raised}
                        onPress={() => link && Linking.openURL(link)}
            />
        );
    }, [ props ]);

    if (type.equals(Constants.SOCIAL_MEDIA_FACEBOOK)) {
        return (
            renderSocialIcon('facebook')
        );
    }
    else if (type.equals(Constants.SOCIAL_MEDIA_INSTAGRAM)) {
        return (
            renderSocialIcon('instagram')
        );
    }
    else if (type.equals(Constants.SOCIAL_MEDIA_YOUTUBE)) {
        return (
            renderSocialIcon('youtube')
        );
    }
    else if (type.equals(Constants.SOCIAL_MEDIA_TWITTER)) {
        return (
            renderSocialIcon('twitter')
        );
    }

	return null;
};

SocialLinks.defaultProps = {
    asButton: true,
	size: 24,
	useBrandColor: true
};

SocialLinks.propTypes = {
	json: PropTypes.shape({
		link: PropTypes.string, 
		size: PropTypes.number,
		type: PropTypes.string,
		useBrandColor: PropTypes.bool, 
		raised: PropTypes.bool, 
		iconColor: PropTypes.string,
        iconStyle: PropTypes.object,
        style:PropTypes.object
	})
};

export default SocialLinks;
