import React from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';

const StyledWebView = props => {
    const html = get(props, 'html');
    const customStyle = get(props, 'customStyle');
    const nodeId = get(props, 'nodeId');

    /*
    1. custom styles override site.css styles
    2. These styles are only applied in the web browser
    */
    const injectStyles = umbracoHtml => {
        return `<section id=${nodeId}
                         style='display:flex;
                                justify-content:center;
                                flex-direction:column;'
                                >
                        <style>${customStyle}</style>
                        ${umbracoHtml}
                </section>
                `;
    };

    return (
        <div
            dangerouslySetInnerHTML={{
                __html: injectStyles(html)
            }}
            className='div-parent'
            style={{
                margin: 0,
                padding: 0,
                justifyContent: 'center',
                flexDirection: 'row'
            }}
        />
    );
};
StyledWebView.defaultProps = {
    customStyle: ''
};

StyledWebView.propTypes = {
    containerStyle: PropTypes.object,
    html: PropTypes.string.isRequired,
    customStyle: PropTypes.string,
    height: PropTypes.number,
    style: PropTypes.object,
    nodeId: PropTypes.string
};

export default StyledWebView;
