import React, { useContext, useEffect, useState } from 'react';
import { Alert, View } from 'react-native';
import { useTheme, Button, Input, Text } from '@rneui/themed';
import { useForm, Controller } from 'react-hook-form';
import PasswordReset from './PasswordReset';
import Loading from '../../common/Loading';
import Utils from '../../../common/utils';
import AppContext from '../../../contexts/app';
import AuthContext, { ActionTypes } from '../../../contexts/auth';
import { useApi, useDeviceInfo } from '../../../hooks';

const Login = () => {
    const { post } = useApi();
    const { state, dispatch } = useContext(AuthContext);
    const { state: appState } = useContext(AppContext);
    const { os } = useDeviceInfo();
    const [showPasswordReset, setShowPasswordReset] = useState(false);
    const { theme } = useTheme();

    const {
        clearErrors,
        control,
        formState: { isSubmitting, isValid },
        getValues,
        handleSubmit,
        reset
    } = useForm({
        mode: 'onBlur'
    });

    const handleLogin = async () => {
        const tokenEndpoint = state.authority?.token_endpoint;

        if (!tokenEndpoint) {
            throw new Error('Unable to login without an authorization authority');
        }

        const email = getValues('email');
        const password = getValues('password');

        const tokenRequestBody = {
            client_id: appState.settings.openIdSettings.thisClient.id,
            client_secret: appState.settings.openIdSettings.thisClient.secret,
            grant_type: 'password',
            password: password,
            scope: 'openid profile',
            username: email
        };

        const tokenResponse = await post(tokenEndpoint, new URLSearchParams(tokenRequestBody), undefined, 'application/x-www-form-urlencoded');

        if (tokenResponse?.access_token) {
            const userInfoEndpoint = state.authority?.userinfo_endpoint;

            if (userInfoEndpoint) {
                const userInfoResponse = await post(userInfoEndpoint, undefined, undefined, undefined, {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    Authorization: `Bearer ${tokenResponse.access_token}`
                });

                if (userInfoResponse) {
                    dispatch({
                        type: ActionTypes.LOGIN,
                        data: {
                            accessToken: tokenResponse.access_token,
                            details: { ...userInfoResponse },
                            email,
                            loggedIn: true,
                            registered: true
                        }
                    });

                    return;
                }
            }
        }

        if (os.equals('web')) {
            window.alert('Login failed. Please try again.');
        } else {
            Alert.alert('Error', 'Login failed. Please try again.');
        }
    };

    useEffect(() => {
        return () => {
            clearErrors(['email', 'password']);
            reset();
        };
    }, [clearErrors, reset]);

    return (
        <View testID='Authentication.Login'>
            <Controller
                control={control}
                defaultValue=''
                name='email'
                render={({ field, fieldState }) => {
                    return (
                        <Input
                            {...field}
                            autoCorrect={false}
                            autoFocus={false}
                            errorMessage={fieldState.error?.message}
                            errorStyle={theme.appComponents?.common?.Authentication?.Login?.errorText}
                            innerRef={field.ref}
                            inputStyle={{ outlineStyle: 'none' }}
                            placeholder='EMAIL'
                            placeholderTextColor={theme?.colors?.grey2}
                            textContentType='emailAddress'
                        />
                    );
                }}
                rules={{
                    required: {
                        message: 'Email is required',
                        value: true
                    },
                    pattern: {
                        message: 'Invalid email',
                        value: Utils.ValidEmailRegExp
                    }
                }}
            />
            <Controller
                control={control}
                defaultValue=''
                name='password'
                render={({ field, fieldState }) => {
                    return (
                        <Input
                            {...field}
                            autoCorrect={false}
                            errorMessage={fieldState.error?.message}
                            errorStyle={theme.appComponents?.common?.Authentication?.Login?.errorText}
                            innerRef={field.ref}
                            inputStyle={{ outlineStyle: 'none' }}
                            maxLength={24}
                            placeholder='PASSWORD'
                            placeholderTextColor={theme?.colors?.grey2}
                            secureTextEntry={true}
                            textContentType='password'
                        />
                    );
                }}
                rules={{
                    required: {
                        message: 'Password is required',
                        value: true
                    }
                }}
            />
            <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
                <Text onPress={() => setShowPasswordReset(true)} style={theme.appComponents?.common?.Authentication?.Login?.alternateActionText}>
                    Forgot Password
                </Text>
            </View>
            <Button
                buttonStyle={{ backgroundColor: theme?.colors?.navy }}
                containerStyle={{ marginVertical: 20 }}
                disabled={!isValid}
                disabledStyle={{
                    backgroundColor: theme.colors?.grey4,
                    borderColor: theme.colors?.black,
                    borderWidth: 0.25
                }}
                title='SIGN IN'
                titleStyle={{ ...theme?.text?.H4, color: theme?.colors?.white }}
                onPress={handleSubmit(handleLogin)}
            />
            <PasswordReset setVisible={setShowPasswordReset} visible={showPasswordReset} />
            {isSubmitting && <Loading />}
        </View>
    );
};

export default Login;
