import React, { useCallback }           from 'react';
import { View }                         from 'react-native';
import { useTheme }              from '@rneui/themed';
import { get, values }                  from 'lodash';
import PropTypes                        from 'prop-types';
import Photo                            from './Photo';
import CornerBlockCTA	                from './CornerBlockCTA';
import CornerBlockWYSIWYG               from './CornerBlockWYSIWYG';
import Utils                            from '../../common/utils';

const CornerBlock = props =>
{
	const { theme }                           = useTheme();
	const backgroundColor                     = get(props.json, 'backgroundColor');
	const textColor                           = get(props.json, 'textColor');
	const contentItems                        = get(props.json, 'contentItems');

	const renderContentItem   = useCallback(contentItem => 
	{
		if (contentItem.nodeTypeAlias.equals('reactCornerBlockCTA')) {
			return (
				<CornerBlockCTA json={contentItem} 
								backgroundColor={Utils.applyColor(backgroundColor)} 
								textColor={Utils.applyColor(textColor)} 
								followContentLink={props.followContentLink} 
								references={props.refs} 
				/>
			);
		}
		else if (contentItem.nodeTypeAlias.equals('reactPhoto')) {
			const enableImageOverlay = get(contentItem, 'enableImageOverlay');

			if (enableImageOverlay) {
				return (
					<Photo json={contentItem} height={470} width={840} />
				);
			}
			else {
				const imagePath = get(contentItem, 'image.cdnImagePath');
				const title     = get(contentItem, 'title');

				return (
					<img alt={title} src={`https:${imagePath}`} style={{flex:1}}/>
				);
			}
		}
		else if (contentItem.nodeTypeAlias.equals('reactCornerBlockWYSIWYG')) {
			return (
				<CornerBlockWYSIWYG json={contentItem} 
				                    backgroundColor={Utils.applyColor(backgroundColor)} 
									textColor={Utils.applyColor(textColor)}
				/>
			);
		}

		return null;
	}, []);

	const renderColumns = useCallback(() =>
	{
		const contentItemsArray = values(contentItems);

		if (contentItemsArray.length === 1 || props.renderSingle) {
			return (
				<View style={{ flex:1 }}>
					{ renderContentItem(contentItemsArray[0]) }
				</View>
			);
		}
		else if (contentItemsArray.length >= 2) {
			return (
				<View style={{ flex: 1, flexDirection: 'row' }}>
					<View style={{ flex: 0.5 }}>
						{ renderContentItem(contentItemsArray[0]) }
					</View>
					<View style={{ flex: 0.5 }}>
						{ renderContentItem(contentItemsArray[1]) }
					</View>
				</View>
			);
		}

		return null;
	});

	if (!contentItems) {
		return null;
	}
	
	return (
		renderColumns()
	);
};

CornerBlock.propTypes = {
	json: PropTypes.shape({
		backgroundColor: PropTypes.string,
		contentItems: PropTypes.object,
		id: PropTypes.string.isRequired,
		textColor: PropTypes.string
	})
};

export default CornerBlock;
