import React, { forwardRef }         from 'react';
import { Input }                     from '@rneui/themed';

const FormInput =React.forwardRef((props, ref)  => 
{
    const { placeholderStyle, style, value, ...rest } = props;
    
    return (
        <Input {...rest} ref={ref} inputStyle={!value ? [style, placeholderStyle] : style} {...props} />
    );
});

export default FormInput;
