import React, { useContext, useState } from 'react';
import { Linking, View } from 'react-native';
import { useTheme, Button, BottomSheet, Icon, ListItem } from '@rneui/themed';
import Constants from 'expo-constants';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import NavigationList from './NavigationList';
import { Authentication } from '../../common';
import { collectAncillaryOptions } from '../../../common/helpers/navigation';
import AppContext, { AppActions, AppEvents } from '../../../contexts/app';
import { useApi, useDeviceInfo } from '../../../hooks';

const Menu = props => {
    const { fetchPage } = useApi();
    const { state, dispatch } = useContext(AppContext);
    const { os } = useDeviceInfo();
    const [showBottomSheet, setShowBottomSheet] = useState(false);
    const [showLogin, setShowLogin] = useState(false);
    const { theme } = useTheme();
    const ancillaryOptions = collectAncillaryOptions(state.siteWrapper?.web);

    const getAncillaryMenus = () => {
        if (
            !os.equals('web') &&
            (Constants.manifest.extra?.environment?.equals('DEV') ||
                Constants.manifest.extra?.environment?.equals('STG'))
        ) {
            return (
                <View
                    key='login'
                    style={theme?.navigation?.mobile?.MenuBar?.rightComponent}
                    testID='Menu.Login'
                >
                    <Icon
                        color={
                            theme?.navigation?.mobile?.MenuBar?.loginIconColor
                        }
                        containerStyle={{
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginRight: 20
                        }}
                        name='person'
                        type='material'
                        onPress={() => setShowLogin(!showLogin)}
                    />
                </View>
            );
        } else if (os.equals('web')) {
            return ancillaryOptions.menus.map(menu => {
                const menuName = get(menu, 'name');

                return (
                    <View
                        key={menuName}
                        style={
                            theme?.navigation?.mobile?.MenuBar?.rightComponent
                        }
                    >
                        <Button
                            containerStyle={{
                                ...theme?.common?.Row,
                                alignItems: 'center',
                                marginRight: 10
                            }}
                            icon={{
                                color: theme?.MenuBar?.iconColor,
                                name: 'angle-down',
                                type: theme?.iconKit || 'font-awesome-5',
                                size: 16
                            }}
                            iconRight={true}
                            iconContainerStyle={{ paddingRight: 2 }}
                            onPress={() => setShowBottomSheet(!showBottomSheet)}
                            title={menuName}
                            titleStyle={
                                theme?.navigation?.mobile?.MenuBar
                                    ?.rightComponent?.text
                            }
                            type='clear'
                        />
                        <BottomSheet
                            isVisible={showBottomSheet}
                            onBackdropPress={() => setShowBottomSheet(false)}
                            testID={`Mobile.MenuBar.AncillaryMenu.BottomSheet.${menuName}`}
                        >
                            {menu.entries.map((child, index) => {
                                const isExternal = get(child, 'isExternal');
                                const linkText = get(child, 'text');
                                const url = get(child, 'url');

                                const navigate = async () => {
                                    isExternal
                                        ? Linking.openURL(url)
                                        : await fetchPage(dispatch, url);
                                    setShowBottomSheet(false);
                                    //toggleNavigation();
                                };

                                return (
                                    <ListItem
                                        key={index}
                                        onPress={navigate}
                                        containerStyle={
                                            theme?.navigation?.mobile?.MenuBar
                                                ?.BottomSheet?.container
                                        }
                                        testID={`Mobile.MenuBar.AncillaryMenu.${menuName}-${linkText}`}
                                    >
                                        <ListItem.Content>
                                            <ListItem.Title
                                                style={
                                                    theme?.navigation?.mobile
                                                        ?.MenuBar?.BottomSheet
                                                        ?.titleStyle
                                                }
                                            >
                                                {linkText}
                                            </ListItem.Title>
                                        </ListItem.Content>
                                    </ListItem>
                                );
                            })}
                            <ListItem
                                key='menu-cancel'
                                containerStyle={
                                    theme?.navigation?.mobile?.MenuBar
                                        ?.BottomSheet?.cancelBtnContainer
                                }
                                onPress={() => setShowBottomSheet(false)}
                            >
                                <ListItem.Content>
                                    <ListItem.Title
                                        style={
                                            theme?.navigation?.mobile?.MenuBar
                                                ?.BottomSheet?.cancelBtnTitle
                                        }
                                    >
                                        Cancel
                                    </ListItem.Title>
                                </ListItem.Content>
                            </ListItem>
                        </BottomSheet>
                    </View>
                );
            });
        }
    };

    const toggleNavigation = forceClose => {
        if (props.isOpen || forceClose) {
            dispatch({
                type: AppActions.HAMBURGER_STATE_CHANGE,
                state: AppEvents.HAMBURGER_STATE_CLOSED
            });
        } else {
            dispatch({
                type: AppActions.HAMBURGER_STATE_CHANGE,
                state: AppEvents.HAMBURGER_STATE_OPEN
            });
        }
    };

    return (
        <>
            <View style={theme?.navigation?.mobile?.MenuBar?.containerStyle}>
                <Button
                    containerStyle={
                        theme?.navigation?.mobile?.MenuBar?.leftComponent
                    }
                    icon={{
                        ...theme?.navigation?.mobile?.MenuBar?.hamburgerIcon,
                        name: props.isOpen ? 'menu-open' : 'menu'
                    }}
                    onPress={() => toggleNavigation()}
                    type='clear'
                />
                {getAncillaryMenus()}
            </View>
            {props.isOpen && (
                <NavigationList ancillaryOptions={ancillaryOptions} />
            )}
            {showLogin && <Authentication {...props} />}
        </>
    );
};

Menu.propTypes = {
    isOpen: PropTypes.bool.isRequired
};

export default Menu;
