import { useCallback, useState } from 'react';

const useComponentDimensions = () => {
    const [ dimensions, setDimensions ] = useState(null);

    const onLayout = useCallback(e => {
        if (e) {
            setDimensions({
                height: e.nativeEvent.layout.height,
                width: e.nativeEvent.layout.width
            });
        }
    }, []);

    return { dimensions, onLayout };
};

export default useComponentDimensions;
