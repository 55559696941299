import React, { useContext, useCallback } from 'react';
import { Linking, View, Pressable } from 'react-native';
import { useTheme, Image, SocialIcon, Text } from '@rneui/themed';
import { get, values } from 'lodash';
import PropTypes from 'prop-types';
import AppContext from '../../contexts/app';
import { useApi, useDeviceInfo } from '../../hooks';
import AppConstants from '../../common/constants';
import Utils from '../../common/utils';
import HorizontalRule from '../common/HorizontalRule';

const Affiliates = props => {
    const { theme } = useTheme();
    const affiliates = get(props, 'contentItems.Affiliates.contentItems');
    const affiliateArray = values(affiliates);
    const handleLinkPress = get(props, 'handleLinkPress');

    if (affiliateArray?.length > 0) {
        const reorderedAffiliateArray = Utils.swapElementsInArray(affiliateArray, 1, 2);

        return (
            <>
                {theme?.appComponents?.umbraco?.SiteFooter?.horizontalLineColor && (
                    <HorizontalRule lineColor={theme?.appComponents?.umbraco?.SiteFooter?.horizontalLineColor} />
                )}
                <View style={theme?.appComponents?.umbraco?.SiteFooter?.affiliatesContainer} testID='reactSiteFooter.Affiliates'>
                    <View style={theme?.appComponents?.umbraco?.SiteFooter?.affiliatesLeftCol}>
                        {reorderedAffiliateArray
                            .filter((_, index) => index % 2 === 0)
                            .map((affiliate, index) => (
                                <Affiliate
                                    key={index}
                                    index={index}
                                    handleLinkPress={handleLinkPress}
                                    link={affiliate.link}
                                    linkText={affiliate.linkText}
                                    imageUrl={affiliate.linkImage.cdnImagePath}
                                />
                            ))}
                    </View>
                    <View style={theme?.appComponents?.umbraco?.SiteFooter?.affiliatesRightCol}>
                        {reorderedAffiliateArray
                            .filter((_, index) => index % 2 !== 0)
                            .map((affiliate, index) => (
                                <Affiliate
                                    key={index}
                                    index={index}
                                    handleLinkPress={handleLinkPress}
                                    link={affiliate.link}
                                    linkText={affiliate.linkText}
                                    imageUrl={affiliate.linkImage.cdnImagePath}
                                />
                            ))}
                    </View>
                </View>
            </>
        );
    }

    return null;
};
const Affiliate = ({ index, handleLinkPress, link, linkText, imageUrl }) => {
    const { theme } = useTheme();

    return (
        <View key={index} style={theme?.appComponents?.umbraco?.SiteFooter?.affiliateContainer} testID='reactSiteFooter.Affiliate'>
            <Image
                onPress={() => handleLinkPress(link)}
                source={{
                    uri: `https:${imageUrl}`
                }}
                style={theme?.appComponents?.umbraco?.SiteFooter?.affiliateImage}
                resizeMode='contain'
            />
            <Text onPress={() => handleLinkPress(link)} style={theme?.appComponents?.umbraco?.SiteFooter?.affiliateText}>
                {linkText}
            </Text>
        </View>
    );
};

const Email = ({ email, domain }) => {
    const { theme } = useTheme();

    const getSubjectDomain = () => {
        if (domain.toLowerCase().includes('cornerstone')) {
            return 'SA Cornerstone';
        }
        if (domain.toLowerCase().includes('difference')) {
            return 'Difference Media';
        }
        return 'General Inquiry';
    };

    return (
        <Text
            testID='reactSiteFooter.Email'
            numberOfLines={1}
            onPress={async () => await Linking.openURL(`mailto:${email}?subject=${getSubjectDomain()}%20Website%20Connect`)}
            style={theme?.appComponents?.umbraco?.SiteFooter?.locationEmail}
        >
            {email}
        </Text>
    );
};

const Legal = props => {
    const { theme } = useTheme();
    const legalText = get(props, 'legalText');
    const privacyPolicyLink = get(props, 'privacyPolicyLink');

    if (!legalText) {
        return null;
    }

    return (
        <>
            {theme?.appComponents?.umbraco?.SiteFooter?.horizontalLineColor && (
                <HorizontalRule lineColor={theme?.appComponents?.umbraco?.SiteFooter?.horizontalLineColor} />
            )}
            <View style={theme?.appComponents?.umbraco?.SiteFooter?.legalContainer} testID='reactSiteFooter.Legal'>
                <Text numberOfLines={1} style={theme?.appComponents?.umbraco?.SiteFooter?.legalText}>
                    {legalText}
                </Text>
                <Text
                    onPress={async () =>
                        await props.followContentLink(
                            privacyPolicyLink.isExternal,
                            privacyPolicyLink.internalKey,
                            privacyPolicyLink.externalUrl,
                            props.footerRefs
                        )
                    }
                    style={theme?.appComponents?.umbraco?.SiteFooter?.privacyLink}
                >
                    {'Privacy Policy'} and {'Terms of Use'}
                </Text>
            </View>
        </>
    );
};

const Location = props => {
    const { theme } = useTheme();
    const address = get(props, 'address');
    const phoneNumber = get(props, 'phoneNumber');
    const modernLayout = get(props, 'modernLayout');

    return (
        <>
            {theme?.appComponents?.umbraco?.SiteFooter?.horizontalLineColor && (
                <HorizontalRule lineColor={theme?.appComponents?.umbraco?.SiteFooter?.horizontalLineColor} />
            )}
            <View style={theme?.appComponents?.umbraco?.SiteFooter?.locationContainer} testID='reactSiteFooter.Location'>
                <View style={theme?.appComponents?.umbraco?.SiteFooter?.locationAddressCol}>
                    <Text
                        onPress={() => Linking.openURL(AppConstants.GOOGLE_MAPS_URL)}
                        style={theme?.appComponents?.umbraco?.SiteFooter?.locationAddress}
                    >
                        {address.replace(', ', ',\n')}
                    </Text>
                </View>
                <View style={theme?.appComponents?.umbraco?.SiteFooter?.locationPhoneEmailCol}>
                    {!modernLayout && <Text style={theme?.appComponents?.umbraco?.SiteFooter?.contactUs}>Contact Us</Text>}

                    <Text
                        numberOfLines={1}
                        onPress={() => Linking.openURL(`tel:${phoneNumber.replace(/\D/g, '')}`)}
                        style={theme?.appComponents?.umbraco?.SiteFooter?.locationPhoneNumber}
                    >
                        {phoneNumber}
                    </Text>
                    <Email email={props.email} domain={props.domain} />
                </View>
            </View>
        </>
    );
};

const Socials = props => {
    const { theme } = useTheme();
    const { isMobile } = useDeviceInfo();
    const facebookLink = get(props, 'facebookLink.externalUrl');
    const instagramLink = get(props, 'instagramLink.externalUrl');
    const twitterLink = get(props, 'twitterLink.externalUrl');
    const youtubeLink = get(props, 'youtubeLink.externalUrl');
    const modernLayout = get(props, 'modernLayout');

    const themeIconStyle = theme?.appComponents?.umbraco?.SiteFooter?.socialIconStyle;

    const renderIcon = (type, link, color) => (
        <SocialIcon
            testID='reactSiteFooter.SocialIcon'
            iconSize={theme?.appComponents?.umbraco?.SiteFooter?.socialIconSize}
            light={true}
            raised={false}
            iconType={theme?.iconKit || 'font-awesome'}
            onPress={async () => await Linking.openURL(link)}
            type={type}
            iconColor=''
            style={theme?.appComponents?.umbraco?.SiteFooter?.socialIconViewStyle}
            iconStyle={themeIconStyle ? themeIconStyle : { color: color, padding: 1 }}
        />
    );

    return (
        <View style={theme?.appComponents?.umbraco?.SiteFooter?.socialsContainer} testID='reactSiteFooter.Socials'>
            {!modernLayout && isMobile && (
                <View style={{ width: '100%' }}>
                    <Text style={theme?.appComponents?.umbraco?.SiteFooter?.socialMediaTitle}>Social Media</Text>
                </View>
            )}
            {!!facebookLink &&
                renderIcon(theme?.appComponents?.umbraco?.SiteFooter?.socialIconTypeFacebook, facebookLink, theme.colors.facebook)}
            {!!twitterLink && renderIcon(theme?.appComponents?.umbraco?.SiteFooter?.socialIconTypeTwitter, twitterLink, theme.colors.twitter)}
            {!!instagramLink &&
                renderIcon(theme?.appComponents?.umbraco?.SiteFooter?.socialIconTypeInstagram, instagramLink, theme.colors.instagram)}
            {!!youtubeLink && renderIcon(theme?.appComponents?.umbraco?.SiteFooter?.socialIconTypeYoutube, youtubeLink, theme.colors.youtube)}
        </View>
    );
};

const TopLinks = ({ topLinks, handleLinkPress }) => {
    const { theme } = useTheme();

    if (!topLinks) {
        return null;
    }

    return (
        <View style={theme?.appComponents?.umbraco?.SiteFooter?.topLinksContainer} testID='reactSiteFooter.TopLinks'>
            {values(topLinks).map(topLink => {
                const link = get(topLink, 'link');
                const linkText = get(topLink, 'linkText');
                return (
                    <Pressable key={linkText} style={{ marginRight: 2 }} onPress={() => handleLinkPress(link, linkText)}>
                        <Text numberOfLines={1} style={theme?.appComponents?.umbraco?.SiteFooter?.topLink}>
                            {linkText}
                        </Text>
                    </Pressable>
                );
            })}
        </View>
    );
};

const SiteFooter = props => {
    const { fetchPage } = useApi();
    const { theme } = useTheme();
    const modernLayout = get(props, 'modernLayout');
    const logoImageUrl = get(props?.siteWrapper?.contentItems['Primary Navigation'], 'image.cdnImagePath');
    const { dispatch } = useContext(AppContext);
    const topLinks = get(props.contentItems, 'Top Links.contentItems');

    const handleLinkPress = useCallback(
        async (link, text) => {
            //TODO Investigate
            // const supportedTopLinkUrl = await Linking.canOpenURL(link)

            let supportedUrl = AppConstants.VALID_URI_SCHEMES.find(schema => schema === link.split(':')[0]);
            if (supportedUrl) {
                if (supportedUrl === 'mailto' && text) {
                    return await Linking.openURL(`${link}?subject=${text}%20Website%20Connect`);
                }
                await Linking.openURL(link);
            }

            if (link.startsWith('/')) {
                await fetchPage(dispatch, link);
            }
        },
        [dispatch, fetchPage]
    );

    return (
        <View style={theme?.appComponents?.umbraco?.SiteFooter?.container} testID='reactSiteFooter'>
            <View style={theme?.appComponents?.umbraco?.SiteFooter?.containerRow}>
                {!modernLayout && logoImageUrl?.length > 0 && (
                    <View
                        style={{
                            backgroundColor: 'transparent',
                            width: '100%',
                            paddingBottom: 10
                        }}
                    >
                        <Image
                            source={{ uri: `https:${logoImageUrl}` }}
                            alt='SiteFooter Image Logo'
                            style={{
                                height: 60,
                                width: 200,
                                resizeMode: 'contain'
                            }}
                        />
                    </View>
                )}

                {theme?.appComponents?.umbraco?.SiteFooter?.horizontalLineColor && (
                    <HorizontalRule lineColor={theme?.appComponents?.umbraco?.SiteFooter?.horizontalLineColor} />
                )}
                <View style={theme?.appComponents?.umbraco?.SiteFooter?.contactContainer}>
                    <View style={theme?.appComponents?.umbraco?.SiteFooter?.topLinkSocialsContainer}>
                        <TopLinks handleLinkPress={handleLinkPress} topLinks={topLinks} />
                        <Socials {...props} />
                    </View>
                    <Location {...props} />
                </View>
                <Affiliates {...props} handleLinkPress={handleLinkPress} />
                <Legal {...props} />
            </View>
        </View>
    );
};

SiteFooter.propTypes = {
    isMobile: PropTypes.bool,
    address: PropTypes.string,
    contentItems: PropTypes.shape({
        Affiliates: PropTypes.shape({
            contentItems: PropTypes.object
        }),
        'Top Links': PropTypes.shape({
            contentItems: PropTypes.object
        })
    }),
    email: PropTypes.string,
    facebookLink: PropTypes.shape({
        externalUrl: PropTypes.string
    }),
    legalText: PropTypes.string,
    phoneNumber: PropTypes.string,
    twitterLink: PropTypes.shape({
        externalUrl: PropTypes.string
    }),
    youtubeLink: PropTypes.shape({
        externalUrl: PropTypes.string
    })
};

export default SiteFooter;
