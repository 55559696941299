import { useEffect, useRef } from 'react';

const usIsMounted = () => 
{
    const mountedRef = useRef(false);

    useEffect(() => {
        mountedRef.current = true;

        return () => mountedRef.current = false;
    });

    return mountedRef.current;
};

export default usIsMounted;
