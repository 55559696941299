/**
 * String extensions
 */
String.prototype.equals = function (value, caseSensitive = false)
{
	if (typeof value !== 'string')
	{
		return false;
	}

	const string = this.toString();
	const compareResult = string.localeCompare(value, undefined, {
		case: caseSensitive
	});

	return compareResult === 0;
};

String.prototype.toBoolean = function ()
{
	const value = this.toString();

	if (value.length > 0)
	{
		if (value.equals('true') || value.equals('yes') || value.equals('1'))
		{
			return true;
		}
	}

	return false;
};

String.prototype.isEmpty = function ()
{
	const value = this.toString();
	return (!value || value.length === 0 );
};

String.prototype.charCount = function (char)
{
	const value   = this.toString();
	let charCount = 0;

	for (let i = 0; i < value.length; i++) 
	{
		if (value.charAt(i) === char) 
		{
			charCount++;
		}
	}

	return charCount;
};

String.prototype.initCaps = function() {
	const value = this.toString();

	return (value.charAt(0).toUpperCase() + value.slice(1));
};

String.prototype.formatCurrency = function(locale = 'en-US', currency = 'USD')
{
	let value = this.toString();

	if (Intl && Intl.NumberFormat) {
		const formatter = new Intl.NumberFormat('en-US' || locale, {
			style: 'currency',
			currency: currency || 'USD'
		});

		value = formatter.format(value);
	}

	return value;
};

if (typeof String.prototype.replaceAll !== 'function')
{
	String.prototype.replaceAll = function (match, replacement)
	{
		const value = this.toString();
		function escapeRegExp(string) {
			return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
		}

		return value.replace(new RegExp(escapeRegExp(match), 'g'), () => replacement);
	};
}

/**
 * Number extensions
 */
Number.prototype.formatCurrency = function(locale = 'en-US', currency = 'USD')
{
	let value = this.valueOf();
	
	if (Intl && Intl.NumberFormat) {
		const formatter = new Intl.NumberFormat(locale, {
			style: 'currency',
			currency: currency
		});
	
			
		value = formatter.format(value);
	}

	return value;
};

/**
 * Array extenstions
 */
Array.prototype.equals = function (array) {
    if (!array){
		return false;
	}
    
    if (array === this) 
	{
		return true;
	}

    if (this.length != array.length)
    {
		return false;
	}   

    for (var i = 0, l=this.length; i < l; i++) 
	{
        if (this[i] instanceof Array && array[i] instanceof Array) {
            if (!this[i].equals(array[i]))
            {
				return false;
			}
        }           
        else if (this[i] != array[i]) 
		{ 
            return false;   
        }           
    }       
    return true;
}
// Hide method from for-in loops
Object.defineProperty(Array.prototype, "equals", { enumerable: false });
