import { Dimensions, StyleSheet, PixelRatio, Platform } from 'react-native';
import Colors from './colors';

//* General Design Practice
/*
    heading should be 24-26 points
    body text 16-18 points

    72.7 pixels in a point x 3 inches width = 218.
    Jimmy's ruler = 220

    GENERAL MATRIX
    H1 = 36pt - 9 = 27pt is equal to 36px in the browser 
    H2 = 30pt - 7 = 23pt is equal to 30px in the browser 
    H3 = 24pt - 6 = 18pt is equal to 24px in the browser 
    H4 = 18pt - 4 = 14pt is equal to 18px in the browser 
    H5 = 14pt - 3 = 11pt is equal to 14px in the browser 
    H6 = 12pt - 2 = 10pt is equal to 12px in the browser 
    */
/*


*/

export const dynamicFontSize = fontSize => {
    const { width } = Dimensions.get('window');

    if (!fontSize) {
        return null;
    }

    /* 
        Default width is where there is no scaling only at widths greater or less than, set this to 1440

        scaleFactor controls how fast the font scales according to window width increase or decrease

        maxScale = set to 4 and the scale factor should never go higher than this, will use this as a guard
        
        minScale = set to 0.5 and the scale factor should never go lower than this, will use this as a guard

        1. To get the scale value use this formula 1+ scaleFactor * (width - defaultWidth ) / defaultWidth

        2. next take the scale value * font size to render the new scaled fontSize
        
    */

    // VARIABLES
    const defaultWidth = 1440;
    let scaleFactor = 0.3;
    const maxScale = 4;
    const minScale = 0.5;

    // SCALE FORMULA
    let scale = 1 + (scaleFactor * (width - defaultWidth)) / defaultWidth;

    // GUARDS
    if (scale > maxScale) {
        scale = maxScale;
    }

    if (scale < minScale) {
        scale = minScale;
    }

    if (width < defaultWidth) {
        // FOR DEVICES
        if (PixelRatio.get() >= 2 && (Platform.OS.equals('ios') || Platform.OS.equals('android'))) {
            return Math.floor(scale * fontSize);
        }
        return Math.floor(scale * fontSize) + 'pt';
    }
    // HOLD ORIGINAL PASSED IN FONTSIZE AT WIDTHS GREATER THAN 1440
    return fontSize + 'pt';
};

export const dynamicMarginSize = num => {
    const { width } = Dimensions.get('window');
    const isMobile = width <= 480;
    const isTablet = width >= 480 && width <= 991;

    if (!num) {
        return null;
    }

    if (isMobile || isTablet) {
        if (PixelRatio.get() >= 2 && (Platform.OS.equals('ios') || Platform.OS.equals('android'))) {
            return num / 2;
        }
    }
    if (isMobile) {
        return num / 2 + 'pt';
    }
    return num + 'pt';
};

export const dynamicPaddingSize = num => {
    const isMobile = width <= 480;
    const isTablet = width >= 480 && width <= 991;
    const { width } = Dimensions.get('window');

    if (!num) {
        return null;
    }

    if (isMobile || isTablet) {
        if (PixelRatio.get() === 2 && (Platform.OS.equals('ios') || Platform.OS.equals('android'))) {
            return num / 2;
        }
    }
    if (isMobile) {
        return num / 2 + 'pt';
    }
    return num + 'pt';
};

export const dynamicFontFamily = font => {
    const { width } = Dimensions.get('window');
    const isMobile = width <= 480;

    if (font === 'GothamBook' && isMobile) {
        return 'BarlowRegular';
    } else if (font === 'GothamMedium' && isMobile) {
        return 'BarlowMedium';
    }
    return font;
};

export const dynamicLineHeight = (lineHeightNumber, fontSize) => {
    /*
    https://www.w3.org/TR/css-fonts-4/#propdef-font-size
    https://www.w3.org/WAI/WCAG21/Understanding/text-spacing.html
    NOTE: In CSS, authors often specify line-height as a multiple of the font-size. 
    Since the font-size-adjust property affects the used value of font-size, authors should take care setting the line height when font-size-adjust is used. 
    Setting the line height too tightly can result in overlapping lines of text in this situation.
    */
    if (!lineHeightNumber) {
        return null;
    }

    let sizedLineHeight;
    if (typeof fontSize === 'string' && fontSize !== undefined) {
        const sizedFont = fontSize.length === 4 ? parseInt(fontSize.slice(0, 2)) : parseInt(fontSize.slice(0, 1));

        if (lineHeightNumber < sizedFont * 1.5 || lineHeightNumber > sizedFont * 1.5) {
            sizedLineHeight = Math.floor(sizedFont * 1.5);

            if (PixelRatio.get() >= 2 && (Platform.OS.equals('android') || Platform.OS.equals('ios'))) {
                return sizedLineHeight;
            }
            return sizedLineHeight + 'pt';
        } else {
            return lineHeightNumber + 'pt';
        }
    }

    // We need to handle the case If a static number passed in and not a dynamic Font value
    if (typeof fontSize === 'number' && fontSize !== undefined) {
        if (lineHeightNumber < fontSize * 1.5 || lineHeightNumber > fontSize * 1.5) {
            if (PixelRatio.get() >= 2 && (Platform.OS.equals('android') || Platform.OS.equals('ios'))) {
                return fontSize * 1.5;
            }
            return fontSize * 1.5 + 'pt';
        } else {
            return lineHeightNumber + 'pt';
        }
    }
};

export const dynamicLetterSpacing = fontSize => {
    if (typeof fontSize === 'string' && fontSize !== undefined) {
        const fontNumber = fontSize.length === 4 ? parseInt(fontSize.slice(0, 2)) : parseInt(fontSize.slice(0, 1));
        return (-fontNumber * 0.012).toFixed(3) + 'pt';
    }
    return 0;
};
const Col = {
    flexDirection: 'column',
    flexBasis: 0,
    flexGrow: 1,
    flexShrink: 0
};

const HorizontalRule = {
    borderBottomColor: Colors.black,
    borderBottomWidth: StyleSheet.hairlineWidth
};

const LightHorizontalRule = {
    borderBottomColor: '#dde4e9',
    borderBottomWidth: StyleSheet.hairlineWidth
};

const RoundedButton = {
    buttonStyle: {
        borderRadius: 50,
        height: 50,
        width: 50
    },
    containerStyle: {
        marginBottom: 5,
        marginLeft: 5,
        marginRight: 5,
        marginTop: 5
    }
};

const Row = {
    alignSelf: 'stretch',
    flexDirection: 'row',
    flexShrink: 1,
    flexWrap: 'wrap',
    paddingLeft: 0,
    paddingRight: 0
};

export default {
    Col,
    Colors,
    HorizontalRule,
    LightHorizontalRule,
    RoundedButton,
    Row
};
