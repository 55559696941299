import { Dimensions, Platform, StyleSheet } from 'react-native';
import ThemeColors from './colors';
import { getMargins } from './margins';
import { getText } from './text';
import { dynamicFontFamily, dynamicFontSize, dynamicLetterSpacing, dynamicLineHeight, dynamicMarginSize } from '../common';

export const getComponents = () => {
    const { width } = Dimensions.get('window');
    const isNarrow = width <= 220;
    const isMobile = width <= 480;
    const isTablet = width >= 480 && width <= 991;
    const isDesktopOrLaptop = width >= 991;
    const ThemeText = getText();
    const ThemeMargins = getMargins();

    return {
        wrapper: {
            marginBottom: 40,
            width: '100%'
        },
        common: {
            BlueButton: {
                borderRadius: 0,
                borderBottomWidth: 3,
                borderBottomColor: ThemeColors.black,
                paddingHorizontal: 26,
                fontSize: 16,
                height: 46,
                lineHeight: 46,
                outline: 'none',
                whiteSpace: 'nowrap',
                backgroundColor: ThemeColors.primaryColor,
                color: ThemeColors.baseColor,
                fontWeight: 600,
                textTransform: 'uppercase'
            },
            GoldButton: {
                outline: 'none',
                whiteSpace: 'nowrap',
                margin: 5,
                paddingHorizontal: 22,
                fontSize: 14,
                height: 40,
                lineHeight: 40,
                backgroundColor: ThemeColors.textActiveColor,
                color: ThemeColors.baseColor,
                fontWeight: 600,
                textTransform: 'uppercase',
                border: 'none',
                borderRadius: 0,
                borderBottomColor: ThemeColors.black,
                borderBottomWidth: 3
            },
            Dropdown: {
                placeholderStyle: {
                    color: ThemeColors.primaryColor,
                    fontFamily: 'GothamBook',
                    fontSize: 16
                },
                webContainer: {
                    backgroundColor: ThemeColors?.baseColor,
                    borderColor: ThemeColors?.textBlackColor,
                    borderBottomLeftRadius: 15,
                    borderBottomRightRadius: 15,
                    borderWidth: StyleSheet.hairlineWidth,
                    top: 30,
                    right: 1,
                    position: 'absolute',
                    elevation: 4,
                    shadowColor: ThemeColors?.textBlackColor,
                    shadowOffset: { width: -6, height: 6 },
                    shadowRadius: 8,
                    shadowOpacity: 0.35
                }
            },
            Loading: {
                Container: {
                    alignItems: 'center',
                    backgroundColor: 'rgba (255,255,255, 0.5)',
                    bottom: 0,
                    flex: 1,
                    justifyContent: 'center',
                    left: 0,
                    padding: 10,
                    position: 'absolute',
                    right: 0,
                    top: 0
                },
                ActivityIndicator: {
                    color: ThemeColors.primaryColor
                }
            },
            WebDateTimePicker: {
                dateCellStyle: {
                    alignItems: 'center',
                    borderColor: ThemeColors?.textPlaceholderColor,
                    borderWidth: 0.75,
                    justifyContent: 'center'
                },
                isTodaysDate: {
                    backgroundColor: ThemeColors?.textPrimaryColor,
                    color: ThemeColors?.textSecondaryColor
                },
                textStyle: {
                    fontFamily: 'GothamBook',
                    color: ThemeColors.textBlackColor,
                    fontSize: 16,
                    marginBottom: 0,
                    lineHeight: 18
                },
                leftBtnIcon: {
                    color: ThemeColors?.textPrimaryColor,
                    name: 'chevron-left',
                    size: 16,
                    type: 'font-awesome-5'
                },
                rightBtnIcon: {
                    color: ThemeColors?.textPrimaryColor,
                    name: 'chevron-right',
                    size: 16,
                    type: 'font-awesome-5'
                },
                monthYear: {
                    color: ThemeColors?.textPrimaryColor,
                    fontFamily: 'GothamBook'
                },
                month: {
                    fontFamily: 'GothamBook',
                    color: ThemeColors?.textBlackColor,
                    fontSize: 16,
                    marginBottom: 0,
                    lineHeight: 18
                }
            }
        },
        umbraco: {
            LiveService: {
                watchNowContainer: {
                    alignItems: 'center',
                    flex: 1,
                    justifyContent: 'center',
                    marginBottom: 10
                }
            },
            Accordion: {
                blockQuote: ThemeColors?.primaryColor,
                title: {
                    ...ThemeText.H5,
                    marginBottom: 18,
                    marginLeft: 30
                },
                ListItem: {
                    title: {
                        ...ThemeText?.H5,
                        fontSize: 22,
                        marginVertical: 10,
                        marginLeft: 45
                    },
                    containerStyle: {
                        borderBottomColor: ThemeColors?.textPlaceholderColor,
                        borderBottomWidth: 0.75,
                        borderTopColor: ThemeColors?.textPlaceholderColor,
                        borderTopWidth: 0.75,
                        justifyContent: 'space-between'
                    }
                }
            },
            AddToCalendar: {
                buttonStyle: {
                    color: ThemeColors?.baseColor,
                    borderColor: ThemeColors?.secondaryColor
                },
                iconColor: ThemeColors?.textPrimaryColor,
                titleStyle: {
                    color: ThemeColors?.textPrimaryColor,
                    fontFamily: 'GothamBook',
                    fontSize: 15
                }
            },
            AvatarPlusText: {
                description: {
                    ...ThemeText?.Paragraph,
                    fontFamily: 'GothamBook',
                    lineHeight: 22,
                    marginHorizontal: 0,
                    marginTop: 10
                },
                titleSubText: {
                    fontFamily: 'GothamBook',
                    color: ThemeColors.textPlaceholderColor,
                    fontSize: 16,
                    marginBottom: 0,
                    lineHeight: 18
                }
            },

            AvatarPlusTextWithSlider: {
                buttonIconRight: {
                    color: ThemeColors?.textPlaceholderColor,
                    name: 'chevron-right-circle-outline',
                    size: 30,
                    type: 'material-community'
                },
                buttonIconLeft: {
                    color: ThemeColors?.textPlaceholderColor,
                    name: 'chevron-left-circle-outline',
                    size: 30,
                    type: 'material-community'
                },
                container: {
                    flex: 1
                },
                paginationDot: {
                    width: 10,
                    height: 10,
                    borderRadius: 5,
                    backgroundColor: ThemeColors?.baseColor,
                    borderColor: ThemeColors?.primaryColor,
                    borderWidth: 0.25
                },
                paginationInactiveDot: {
                    backgroundColor: ThemeColors?.cardGreyBorderColor,
                    borderColor: ThemeColors?.cardGreyBorderColor,
                    width: 10,
                    height: 10,
                    borderRadius: 5
                },
                title: {
                    fontFamily: 'GothamBook',
                    fontSize: 20,
                    marginBottom: 10,
                    marginLeft: 40
                }
            },

            CornerBlockWYSIWYG: {
                container: {
                    backgroundColor: ThemeColors.primaryColor
                },
                title: {
                    color: ThemeColors.textSecondaryColor,
                    fontFamily: 'GothamBook',
                    fontSize: 24,
                    letterSpacing: 0,
                    lineHeight: 30,
                    paddingTop: 20,
                    paddingHorizontal: 10,
                    paddingBottom: 20,
                    marginTop: 10
                }
            },

            CornerBlockCTA: {
                buttonTitle: {
                    fontFamily: 'GothamBook',
                    fontSize: 18
                }
            },

            Cta: {
                background: {
                    position: 'absolute',
                    left: 0,
                    height: '100%',
                    right: 0,
                    top: 0,
                    zIndex: -1
                },
                btnTitle: {
                    fontSize: Platform.isPad || (Platform.OS === 'web' && !isMobile) ? 18 : 14,
                    fontFamily: 'GothamBook'
                },
                btnTextColor: ThemeColors?.textSecondaryColor,
                desktopCtaContainer: {
                    alignItems: 'center',
                    flexDirection: 'row',
                    height: 300
                },
                desktopCtaHeadline: {
                    ...ThemeText.H3,
                    fontFamily: 'GothamBook',
                    fontSize: 32,
                    color: ThemeColors?.textSecondaryColor
                },
                mobileCtaContainer: {
                    flex: 1,
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                    marginVertical: 40
                },
                mobileCtaHeadline: {
                    textAlign: 'center',
                    paddingHorizontal: 15,
                    paddingBottom: 5,
                    paddingTop: 30,
                    fontFamily: 'GothamBook',
                    color: ThemeColors?.textSecondaryColor
                },
                linearGradient: {
                    firstColor: ThemeColors?.textPrimaryColor,
                    secondColor: ThemeColors?.primaryColor
                }
            },
            CtaContainer: {
                containerItem: {
                    alignContent: 'center',
                    borderBottomWidth: StyleSheet.hairlineWidth,
                    flexDirection: 'row',
                    justifyContent: 'space-between'
                }
            },

            Event: {
                Cta: {
                    backgroundColor: ThemeColors.primaryColor
                },
                CtaTitle: {
                    fontFamily: 'GothamBook',
                    fontSize: 15,
                    color: ThemeColors?.textSecondaryColor
                },
                Details: {
                    content: {
                        fontFamily: 'GothamBook',
                        color: ThemeColors.textPlaceholderColor,
                        fontSize: 16,
                        marginBottom: 0,
                        lineHeight: 18
                    },
                    dataRow: {
                        flexDirection: 'row',
                        justifyContent: 'space-evenly',
                        marginHorizontal: ThemeMargins.gutter,
                        marginVertical: 10
                    },
                    label: {
                        color: ThemeColors.textBlackColor,
                        fontFamily: 'GothamBook',
                        fontSize: 18
                    },
                    link: {
                        fontFamily: 'GothamBook',
                        color: ThemeColors.textPrimaryColor,
                        fontSize: 16,
                        marginBottom: 0,
                        lineHeight: 18
                    },
                    text: {
                        fontFamily: 'FrankRuhlLibre',
                        fontSize: 18,
                        lineHeight: 24
                    }
                },
                Header: {
                    bottomContainer: {
                        backgroundColor: ThemeColors?.tertiaryColor,
                        flexDirection: 'row',
                        paddingVertical: 20,
                        paddingHorizontal: ThemeMargins?.gutter
                    }
                }
            },
            EventCalendar: {
                Body: {
                    hourGuideColumn: {
                        alignItems: 'center',
                        backgroundColor: ThemeColors?.baseColor,
                        borderBottomColor: ThemeColors?.grey100,
                        borderBottomWidth: 0.45,
                        borderRightColor: ThemeColors?.grey100,
                        borderRightWidth: 0.45,
                        justifyContent: 'center',
                        width: 75,
                        zIndex: 1000
                    },
                    hourGuideColumnText: {
                        color: ThemeColors?.textBlackColor,
                        fontFamily: 'GothamBook',
                        fontSize: 16
                    },
                    nowIndicator: {
                        position: 'absolute',
                        zIndex: 10000,
                        backgroundColor: ThemeColors?.primaryColor,
                        height: 2,
                        width: '100%'
                    }
                },
                CommonStyles: {
                    dateCell: {
                        borderWidth: 1,
                        borderColor: ThemeColors?.grey100,
                        borderRightWidth: 0,
                        borderTopWidth: 0.5,
                        backgroundColor: ThemeColors?.baseColor
                    },
                    guideText: {
                        color: ThemeColors?.black,
                        fontSize: 16,
                        textAlign: 'center'
                    },
                    hourGuide: {
                        backgroundColor: ThemeColors?.baseColor,
                        width: 50,
                        zIndex: 1000
                    },
                    isToday: ThemeColors?.textPrimaryColor,
                    eventCell: {
                        backgroundColor: ThemeColors?.primaryColor,
                        borderRadius: 3,
                        elevation: 2,
                        end: 3,
                        minWidth: '33%',
                        overflow: 'hidden',
                        padding: 4,
                        position: 'absolute',
                        shadowColor: ThemeColors?.textBlackColor,
                        shadowOffset: { width: 0, height: 1 },
                        shadowOpacity: 0.2,
                        shadowRadius: 1,
                        start: 3,
                        zIndex: 100
                    },
                    eventTitle: {
                        color: ThemeColors?.textSecondaryColor,
                        fontFamily: 'GothamBook',
                        fontSize: 12
                    }
                },
                Event: {
                    eventTime: {
                        color: ThemeColors?.white,
                        fontFamily: 'GothamBook',
                        fontSize: 12
                    }
                },
                Header: {
                    arrowIndicatorMobile: {
                        fontFamily: 'GothamBook',
                        fontSize: 24
                    },
                    container: {
                        borderBottomColor: ThemeColors?.grey100,
                        borderBottomWidth: 0.25,
                        flexDirection: 'row'
                    },
                    dayText: {
                        color: ThemeColors?.grey2,
                        fontFamily: 'GothamBook',
                        fontSize: 20,
                        marginTop: 4,
                        textAlign: 'center'
                    },
                    dateText: {
                        color: ThemeColors?.black,
                        fontFamily: 'GothamBook',
                        fontSize: 30,
                        marginBottom: 4,
                        marginRight: 8,
                        textAlign: 'center'
                    },
                    hourGuide: {
                        backgroundColor: '#fff',
                        width: 50,
                        zIndex: 1000
                    },
                    hourGuideColumn: {
                        alignItems: 'center',
                        backgroundColor: ThemeColors?.white,
                        borderBottomColor: ThemeColors?.grey100,
                        borderBottomWidth: 0.45,
                        borderRightColor: ThemeColors?.grey100,
                        borderRightWidth: 0.45,
                        borderTopColor: ThemeColors?.grey100,
                        borderTopWidth: 0.45,
                        justifyContent: 'center',
                        width: 75,
                        height: '100%',
                        zIndex: 1000
                    },
                    hourGuideSpacer: {
                        borderBottomColor: ThemeColors?.grey100,
                        borderBottomWidth: 0.45
                    },
                    isToday: ThemeColors?.aliceLightBlue,
                    mobileContainer: {
                        alignItems: 'center',
                        backgroundColor: ThemeColors?.grey3,
                        flexDirection: 'row',
                        justifyContent: 'space-around',
                        paddingVertical: 10
                    },
                    wrapperItem: {
                        borderRightColor: ThemeColors?.grey100,
                        borderRightWidth: 0.45,
                        borderLeftColor: ThemeColors?.grey100,
                        borderLeftWidth: 0.45,
                        borderTopColor: ThemeColors?.grey100,
                        borderTopWidth: 0.45,
                        flex: 1,
                        paddingTop: 2,
                        backgroundColor: ThemeColors?.white
                    }
                },
                ToolBar: {
                    btnContainerStyle: {
                        backgroundColor: ThemeColors?.primaryColor,
                        flexDirection: 'column'
                    },
                    btnTitleStyle: {
                        color: ThemeColors?.textSecondaryColor
                    },
                    btnIconStyle: {
                        backgroundColor: 'transparent',
                        marginVertical: 2
                    },
                    leftIconBtn: {
                        color: ThemeColors?.primaryColor,
                        name: 'chevron-left-circle-outline',
                        size: 30,
                        type: 'material-community'
                    },
                    rightIconBtn: {
                        color: ThemeColors?.primaryColor,
                        name: 'chevron-right-circle-outline',
                        size: 30,
                        type: 'material-community'
                    },
                    selectDateBtnStyle: {
                        backgroundColor: ThemeColors?.tertiaryColor
                    },
                    selectDateBtnContainer: {
                        borderColor: ThemeColors?.primaryColor,
                        borderWidth: StyleSheet.hairlineWidth,
                        flexDirection: 'column'
                    },
                    selectDateBtnIcon: {
                        color: ThemeColors?.primaryColor,
                        name: 'calendar-alt',
                        size: 12,
                        style: { marginRight: 2 },
                        type: 'font-awesome-5'
                    },
                    selectDateBtnTitleStyle: {
                        color: ThemeColors?.primaryColor
                    },
                    filterBtnStyle: {
                        backgroundColor: ThemeColors?.tertiaryColor
                    },
                    filterBtnContainer: {
                        borderColor: ThemeColors?.primaryColor,
                        borderWidth: StyleSheet.hairlineWidth,
                        flexDirection: 'column'
                    },
                    filterBtnIcon: {
                        color: ThemeColors?.primaryColor,
                        name: 'filter',
                        size: 12,
                        type: 'font-awesome-5'
                    },
                    filterBtnTitleStyle: {
                        color: ThemeColors?.primaryColor
                    },
                    filterCloseIcon: {
                        color: ThemeColors?.textPlaceholderColor,
                        name: 'times',
                        size: 18,
                        type: 'font-awesome-5'
                    },
                    filterApplyBtnStyle: {
                        backgroundColor: ThemeColors?.textPrimaryColor
                    },
                    filterApplyBtnTitleStyle: {
                        color: ThemeColors?.textSecondaryColor
                    },
                    checkBoxColor: ThemeColors?.primaryColor,
                    checkBoxTitle: ThemeColors?.textBlackColor
                }
            },
            EventsGrid: {
                controlButtonContainerStyle: {
                    margin: 0
                },
                calendarText: {
                    color: ThemeColors?.textPrimaryColor,
                    fontSize: 16,
                    marginLeft: 10
                },
                calendarIcon: {
                    color: ThemeColors?.primaryColor,
                    name: 'calendar-alt',
                    size: 18,
                    type: 'font-awesome-5'
                },
                backBtnIcon: {
                    color: ThemeColors?.primaryColor,
                    name: 'chevron-back-circle-outline',
                    size: 24,
                    type: 'ionicon'
                },
                forwardBtnIcon: {
                    color: ThemeColors?.textPrimaryColor,
                    name: 'chevron-forward-circle-outline',
                    size: 24,
                    type: 'ionicon'
                },
                eventDateBubble: {
                    alignItems: 'center',
                    backgroundColor: ThemeColors?.baseColor,
                    borderColor: ThemeColors?.textBlackColor,
                    borderRadius: isMobile ? 8 : 10,
                    height: isMobile ? 45 : 50,
                    justifyContent: 'center',
                    position: 'absolute',
                    width: isMobile ? 45 : 50
                },
                eventDay: {
                    fontFamily: 'GothamBook',
                    fontSize: 20
                },
                eventDetails: {
                    lineHeight: 24
                },
                eventMonth: {
                    fontFamily: 'GothamBook',
                    fontSize: 18,
                    marginTop: 0.75,
                    textTransform: 'uppercase',
                    color: ThemeColors?.textBlackColor
                },
                eventText: {
                    color: ThemeColors?.textSecondaryColor,
                    fontFamily: 'GothamBook',
                    fontSize: 14,
                    marginRight: 3,
                    marginBottom: 8
                },
                eventTitle: {
                    color: ThemeColors?.textSecondaryColor,
                    fontFamily: 'GothamBook',
                    fontSize: 18,
                    textTransform: 'capitalize',
                    marginBottom: 8
                }
            },

            FullWidthImage: {
                containerWithTitleOverlayDesktop: {
                    flex: 1,
                    minWidth: width
                },
                imageContainerStyle: {
                    height: width / 1.8
                },
                description: {
                    ...ThemeText?.H4,
                    color: ThemeColors?.textSecondaryColor,
                    marginHorizontal: ThemeMargins?.gutter
                },
                heading: {
                    ...ThemeText?.ComponentTitle,
                    color: ThemeColors?.textSecondaryColor,
                    marginHorizontal: ThemeMargins?.gutter
                },
                arrowIndicatorTitle: {
                    color: ThemeColors.textBlackColor,
                    fontFamily: 'GothamBook',
                    fontSize: 12
                },
                container: {
                    width: isMobile ? width : width,
                    height: isMobile ? width / 1.3 : width / 1.6
                },
                textContentContainer: {
                    flexDirection: 'row',
                    backgroundColor: ThemeColors?.primaryColor
                },
                title: {
                    ...ThemeText?.ComponentTitle,
                    color: ThemeColors?.textSecondaryColor,
                    marginHorizontal: 0
                },
                textTitleDescriptionView: {
                    flex: 1,
                    marginHorizontal: 0
                }
            },

            Giving: {
                FormContent: {
                    errorStyle: {
                        color: ThemeColors?.textErrorColor,
                        fontFamily: 'GothamBook',
                        fontSize: Platform.OS.equals('web') && !isMobile ? '14pt' : 12
                    },
                    inputStyle: {
                        fontFamily: 'GothamBook',
                        fontSize: Platform.OS.equals('web') && !isMobile ? '18pt' : 12,
                        outlineStyle: Platform.OS.equals('web') ? 'none' : undefined,
                        width: '100%'
                    },
                    smallInputStyle: {
                        fontFamily: 'GothamBook',
                        fontSize: Platform.OS.equals('web') && !isMobile ? '14pt' : 12,
                        width: '100%',
                        outlineStyle: Platform.OS.equals('web') ? 'none' : undefined
                    },
                    validSmallInputStyle: {
                        borderColor: ThemeColors?.textSuccessColor,
                        borderWidth: 0.35,
                        fontFamily: 'GothamBook',
                        fontSize: Platform.OS.equals('web') && !isMobile ? '14pt' : 12,
                        width: '100%',
                        outlineStyle: Platform.OS.equals('web') ? 'none' : undefined,
                        paddingLeft: 10
                    },
                    placeholderStyle: {
                        color: ThemeColors?.textBlackColor,
                        fontFamily: 'GothamBook',
                        fontSize: Platform.OS.equals('web') && !isMobile ? '14pt' : 42
                    }
                }
            },

            HowToWatch: {
                activeOption: {
                    backgroundColor: ThemeColors?.primaryColor
                },
                imageIcon: {
                    height: 75,
                    marginBottom: 10,
                    width: '100%'
                },
                inactiveOption: {
                    backgroundColor: ThemeColors?.tertiaryColor
                },
                nativeSvgIcon: {
                    color: ThemeColors?.textSecondaryColor
                },
                option: {
                    borderBottomWidth: 0,
                    borderLeftWidth: 0,
                    borderRightWidth: 0,
                    borderTopWidth: 0,
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginHorizontal: 2,
                    marginVertical: 0
                },
                optionTitleActive: {
                    fontSize: isMobile ? dynamicFontSize(14) : dynamicFontSize(18),
                    fontFamily: 'GothamBook',
                    color: ThemeColors?.textSecondaryColor,
                    textAlign: 'center',
                    marginTop: isMobile ? 0 : 15
                },
                optionTitleInactive: {
                    fontSize: isMobile ? dynamicFontSize(14) : dynamicFontSize(18),
                    fontFamily: 'GothamBook',
                    color: ThemeColors?.textPrimaryColor,
                    textAlign: 'center',
                    marginTop: isMobile ? 0 : 15
                },
                optionsContainer: {
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-around',
                    marginTop: 6
                },
                webSvgIcon: {
                    color: ThemeColors?.textSecondaryColor,
                    height: '100%',
                    marginVertical: 10,
                    width: '100%'
                },
                Broadcast: {
                    AccordionListItem: {
                        container: {
                            borderBottomColor: ThemeColors.secondaryColor,
                            borderBottomWidth: 0.75,
                            cursor: Platform.OS === 'web' ? 'pointer' : undefined,
                            flex: 1,
                            justifyContent: 'space-between',
                            marginHorizontal: 50,
                            marginVertical: 20
                        },
                        header: {
                            borderBottomColor: ThemeColors?.secondaryColor,
                            borderBottomWidth: 0.75,
                            justifyContent: 'center',
                            paddingBottom: 30,
                            paddingTop: 10,
                            marginHorizontal: 5
                        }
                    },
                    textStyle: {
                        color: ThemeColors?.textBlackColor,
                        marginBottom: 5
                    },
                    TabItemTitle: {
                        fontFamily: dynamicFontFamily('GothamBook'),
                        fontSize: dynamicFontSize(16),
                        marginBottom: 0,
                        lineHeight: dynamicLineHeight(18)
                    },
                    activeTabItemTitle: {
                        color: ThemeColors?.textPrimaryColor
                    },
                    inactiveTabItemTitle: {
                        color: ThemeColors?.textBlackColor
                    },
                    description: {
                        color: ThemeColors.textPrimaryColor
                    },
                    chevronColorStyle: {
                        color: ThemeColors?.primaryColor
                    }
                }
            },
            IconPlusHeading: {
                container: {
                    alignContent: 'center',
                    flex: 1,
                    flexDirection: 'row'
                },
                iconClassContainer: {
                    alignContent: 'center',
                    height: 60,
                    justifyContent: 'center',
                    marginRight: 20,
                    width: 60
                },
                iconImgContainer: {
                    alignContent: 'center',
                    height: 60,
                    justifyContent: 'center',
                    marginRight: 20,
                    width: 60
                },
                headline: {
                    fontFamily: 'GothamBook',
                    overflow: 'break-word',
                    textAlign: 'center'
                }
            },
            Job: {
                detailsLabel: {
                    fontFamily: 'GothamBook',
                    fontSize: Platform.isPad || (Platform.OS === 'web' && !isMobile) ? 21 : 16,
                    textAlign: 'left'
                },
                detailsValue: {
                    fontFamily: 'FrankRuhlLibre',
                    fontSize: Platform.isPad || (Platform.OS === 'web' && !isMobile) ? 24 : 16,
                    lineHeight: Platform.isPad || (Platform.OS === 'web' && !isMobile) ? 24 * 1.25 : 16 * 1.25,
                    color: Platform.isPad || (Platform.OS === 'web' && !isMobile) ? ThemeColors?.textBlackColor : ThemeColors?.black,
                    paddingLeft: 5
                },
                detailsValueActionable: {
                    fontFamily: 'FrankRuhlLibre',
                    fontSize: Platform.isPad || (Platform.OS === 'web' && !isMobile) ? 24 : 16,
                    lineHeight: Platform.isPad || (Platform.OS === 'web' && !isMobile) ? 24 * 1.25 : 16 * 1.25,
                    color: ThemeColors?.textPrimaryColor,
                    paddingLeft: 10,
                    textDecorationLine: 'underline'
                },
                headerContainer: {
                    flexDirection: 'row',
                    backgroundColor: ThemeColors?.tertiaryColor,
                    paddingLeft: ThemeMargins.gutter,
                    paddingVertical: 20,
                    width: '100%',
                    flexWrap: 'wrap',
                    marginBottom: 10
                },
                descrTitle: {
                    ...ThemeText?.H4,
                    marginTop: 20,
                    marginBottom: 4,
                    fontSize: Platform.isPad || (Platform.OS === 'web' && !isMobile) ? 27 : 18,
                    fontFamily: 'GothamMedium'
                },
                jobTitle: {
                    ...ThemeText?.H1,
                    fontFamily: 'GothamBook',
                    fontSize: Platform.isPad || (Platform.OS === 'web' && !isMobile) ? 48 : 24,
                    lineHeight: 64,
                    color: ThemeColors?.textSecondaryColor
                },
                btnBackTitle: {
                    color: ThemeColors?.textSecondaryColor,
                    fontSize: Platform.isPad || (Platform.OS === 'web' && !isMobile) ? 16 : 11,
                    fontFamily: 'GothamBook'
                },
                departmentText: {
                    ...ThemeText?.H5,
                    width: '100%',
                    fontSize: Platform.isPad || (Platform.OS === 'web' && !isMobile) ? 22 : 18,
                    fontFamily: Platform.isPad || (Platform.OS === 'web' && !isMobile) ? 'GothamBook' : 'GothamMedium',
                    marginBottom: Platform.isPad || (Platform.OS === 'web' && !isMobile) ? 28 : 16
                },
                btnApplyNow: {
                    backgroundColor: ThemeColors?.primaryColor,
                    color: ThemeColors?.baseColor,
                    padding: 8,
                    fontFamily: 'GothamBook'
                },
                btnApplyStyle: {
                    backgroundColor: ThemeColors?.primaryColor
                },
                btnApplyTitle: {
                    fontFamily: 'GothamBook',
                    backgroundColor: ThemeColors?.primaryColor,
                    fontSize: Platform.isPad || (Platform.OS === 'web' && !isMobile) ? 18 : 11
                }
            },
            JobContainer: {
                headingTitle: {
                    ...ThemeText?.H3,
                    fontSize: 32,
                    paddingHorizontal: 30
                },
                departmentText: {
                    ...ThemeText?.H6,
                    textTransform: 'uppercase',
                    fontSize: dynamicFontSize(14),
                    fontFamily: dynamicFontFamily('GothamBook'),
                    marginBottom: 10,
                    color: ThemeColors?.textBlackColor
                },
                jobTitle: {
                    color: ThemeColors?.textPrimaryColor,
                    fontSize: dynamicFontSize(16),
                    fontFamily: dynamicFontFamily('GothamBook')
                },
                organization: {
                    fontSize: dynamicFontSize(16),
                    fontFamily: dynamicFontFamily('GothamBook')
                }
            },
            ListItemContainer: {
                container: {
                    alignItems: 'center',
                    flex: 1,
                    justifyContent: 'center',
                    paddingHorizontal: 5,
                    paddingVertical: 5,
                    color: ThemeColors.primaryColor
                },
                item: {
                    alignItems: 'center',
                    flexDirection: 'row',
                    marginHorizontal: ThemeMargins.gutter,
                    marginVertical: ThemeMargins.gutter
                },
                itemDescription: {
                    ...ThemeText?.Paragraph,
                    marginLeft: 0,
                    marginVertical: 5
                },
                itemWithBorder: {
                    alignItems: 'center',
                    borderBottomColor: ThemeColors?.textBlackColor,
                    borderBottomWidth: 0.75,
                    flexDirection: 'row',
                    marginHorizontal: ThemeMargins?.gutter,
                    marginVertical: ThemeMargins?.gutter
                },
                viewMoreBtnStyle: {
                    backgroundColor: ThemeColors?.baseColor
                },
                viewMoreBtnTitle: {
                    color: ThemeColors?.primaryColor,
                    fontFamily: 'GothamBook',
                    fontSize: 16,
                    paddingTop: 5
                },
                viewMoreIcon: {
                    color: ThemeColors?.primaryColor,
                    containerStyle: { paddingHorizontal: 5 },
                    type: 'font-awesome-5'
                }
            },
            Ministry: {
                ctaButtonTitle: {
                    color: ThemeColors?.textSecondaryColor,
                    fontFamily: 'GothamMedium',
                    fontSize: 14,
                    lineHeight: 21,
                    letterSpacing: 0.608
                },
                ctaButtonIcon: {
                    color: ThemeColors?.textSecondaryColor,
                    name: 'chevron-circle-right',
                    size: 18
                },
                ctaHeading: {
                    ...ThemeText?.H5,
                    color: ThemeColors?.textSecondaryColor,
                    fontFamily: 'GothamBook',
                    fontSize: 22
                },
                ctaOptionalBar: {
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: 'rgba(21, 61, 110, 0.5)',
                    flexDirection: 'row',
                    position: 'absolute',
                    height: 75,
                    top: 0,
                    left: 0,
                    right: 0,
                    flex: 1,
                    paddingHorizontal: ThemeMargins.gutter
                },
                nameContainer: {
                    alignItems: 'center',
                    bottom: 0,
                    flexDirection: 'row',
                    position: 'absolute',
                    width: '100%',
                    paddingLeft: ThemeMargins.gutter
                },
                ministryName: {
                    ...ThemeText?.H1,
                    color: ThemeColors?.textSecondaryColor
                }
            },
            MinistryGrid: {
                descriptionContainer: {
                    alignContent: 'center',
                    justifyContent: 'center',
                    marginHorizontal: ThemeMargins.gutter,
                    marginVertical: ThemeMargins.gutter,
                    flex: 1
                },
                description: {
                    ...ThemeText.Paragraph,
                    marginHorizontal: 0,
                    lineHeight: dynamicLineHeight(26)
                },
                ministryContainer: {
                    flexDirection: isMobile ? 'column' : 'row',
                    borderBottomColor: ThemeColors?.secondaryColor,
                    borderBottomWidth: StyleSheet.hairlineWidth
                },
                ministryGridContainer: {
                    borderTopColor: ThemeColors?.secondaryColor,
                    borderTopWidth: StyleSheet.hairlineWidth,
                    flex: 1
                },
                visitButtonIcon: {
                    color: ThemeColors.textPrimaryColor,
                    name: 'chevron-circle-right',
                    size: 20,
                    container: {
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        marginHorizontal: ThemeMargins.inset1
                    }
                },
                visitButtonTitle: isMobile
                    ? { ...ThemeText.H3, color: ThemeColors?.textPrimaryColor }
                    : { ...ThemeText.H5, color: ThemeColors?.textPrimaryColor }
            },
            NameAcquisition: {
                errorText: {
                    color: ThemeText?.textErrorColor,
                    fontFamily: 'GothamBook',
                    fontSize: 16,
                    paddingTop: 10,
                    textAlign: 'center'
                },
                small: {
                    ...ThemeText.ParagraphSmall,
                    color: ThemeColors.textSecondaryColor,
                    fontSize: 12,
                    textAlign: 'center'
                },
                placeholderStyle: {
                    fontFamily: 'GothamBook',
                    fontSize: 16,
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center',
                    borderRadius: 4
                },
                placeholderTextColor: ThemeColors?.textPlaceholderColor,
                errorTitle: {
                    ...ThemeText.H5,
                    fontSize: 26,
                    paddingVertical: 20,
                    textAlign: 'center'
                },
                errorBtnStyle: {
                    color: ThemeColors.primaryColor,
                    fontFamily: 'GothamBook',
                    fontSize: 20,
                    backgroundColor: ThemeColors?.primaryColor,
                    width: 80,
                    height: 40
                },
                inputStyle: {
                    width: '25%',
                    padding: 10,
                    color: ThemeColors?.textBlackColor
                },
                inputContainerStyle: {
                    backgroundColor: 'white',
                    borderRadius: 4,
                    alignItems: 'center'
                },
                buttonDisabledStyle: {
                    opacity: 0.5,
                    borderColor: ThemeColors?.textPlaceholderColor,
                    borderWidth: 0.75,
                    backgroundColor: ThemeColors?.textPlaceholderColor
                },
                buttonStyle: {
                    borderWidth: 0.75,
                    borderColor: ThemeColors?.textSecondaryColor
                },
                buttonTitleStyle: {
                    color: ThemeColors?.textSecondaryColor,
                    fontFamily: 'GothamBook',
                    fontSize: 16
                },
                formContainer: {
                    alignContent: 'center',
                    flex: 1,
                    justifyContent: 'center',
                    paddingHorizontal: ThemeMargins?.gutter,
                    backgroundColor: ThemeColors?.primaryColor
                },
                overlayStyle: {
                    boxShadow: '0,0,0,0',
                    borderColor: ThemeColors?.textPlaceholderColor,
                    borderWidth: 2,
                    width: isMobile ? width : width / 2
                },
                requestOverlayStyle: {
                    backgroundColor: ThemeColors?.baseColor,
                    borderWidth: 0,
                    justifyContent: 'center',
                    marginHorizontal: 20,
                    marginVertical: 5
                },
                requestButtonOverlayTitle: {
                    fontFamily: 'GothamBook',
                    fontSize: 16
                }
            },
            NavSearchIcon: ThemeColors?.primaryColor,
            NavSearchIconContainer: {
                borderLeftColor: ThemeColors?.textPlaceholderColor,
                borderLeftWidth: StyleSheet.hairlineWidth,
                paddingLeft: 5
            },
            NewsArticleCard: {
                date: {
                    ...ThemeText?.ParagraphSmall
                },
                title: {
                    ...ThemeText.H3,
                    width: '100%'
                },
                author: {
                    color: ThemeColors?.textBlackColor,
                    fontFamily: 'GothamBook',
                    fontSize: 16
                },
                contentContainer: {
                    borderColor: ThemeColors?.cardGreyBorderColor,
                    borderWidth: StyleSheet.hairlineWidth,
                    flex: 1,
                    flexDirection: 'row'
                },
                category: {
                    ...ThemeText?.H6
                }
            },
            NewsArticleGrid: {
                placeholderStyle: {
                    color: ThemeColors?.textPlaceholderColor,
                    fontSize: 16,
                    fontFamily: 'GothamBook',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    padding: 2
                },
                dropdownItemTextColor: ThemeColors?.textPrimaryColor
            },
            NewsArticle: {
                tagTextStyle: {
                    ...ThemeText?.SmallText,
                    color: ThemeColors?.textPrimaryColor
                },
                tagLabelTextStyle: {
                    color: ThemeColors?.textPrimaryColor,
                    fontFamily: 'GothamBook',
                    fontSize: 16,
                    marginBottom: 0,
                    lineHeight: 18,
                    marginRight: 5
                },
                authorDate: {
                    color: ThemeColors?.textBlackColor,
                    fontFamily: 'GothamBook',
                    fontSize: 16,
                    marginBottom: 0,
                    lineHeight: 18
                },
                buttonIcon: {
                    color: ThemeColors?.textPrimaryColor,
                    name: 'chevron-circle-right',
                    size: isMobile ? 16 : 32
                },
                buttonTitle: {
                    fontFamily: 'GothamBook',
                    color: ThemeColors.textPrimaryColor,
                    fontSize: 16,
                    marginBottom: 0,
                    lineHeight: 18
                },
                buttonStyle: {
                    backgroundColor: ThemeColors?.baseColor,
                    borderColor: ThemeColors.textPrimaryColor,
                    borderWidth: 2,
                    borderRadius: 10
                }
            },
            Pagination: {
                activeBackgroundColor: ThemeColors?.textSecondaryColor,
                activeBorderColor: ThemeColors?.textPrimaryColor,
                backgroundColor: ThemeColors.textPlaceholderColor,
                borderColor: ThemeColors.textPlaceholderColor
            },
            Photo: {
                description: {
                    color: ThemeColors?.textSecondaryColor,
                    fontFamily: 'GothamBook',
                    fontSize: 16,
                    letterSpacing: 0.8,
                    lineHeight: 24
                },
                title: {
                    color: ThemeColors?.textSecondaryColor,
                    textAlign: 'left'
                },

                leftButtonIcon: {
                    color: ThemeColors?.textSecondaryColor,
                    name: 'chevron-left',
                    size: 40
                },
                rightButtonIcon: {
                    color: ThemeColors?.textSecondaryColor,
                    name: 'chevron-right',
                    size: 40
                }
            },
            PhotoGallery: {
                photoBtn: {
                    borderRadius: 6,
                    borderWidth: 0.5,
                    height: 10,
                    marginHorizontal: 5,
                    marginVertical: 5,
                    width: 10,
                    backgroundColor: ThemeColors.textPlaceholderColor,
                    borderColor: ThemeColors.textPrimaryColor
                }
            },
            PhotoGrid: {
                overlayTitle: {
                    ...ThemeText.H3,
                    color: ThemeColors?.textSecondaryColor,
                    width: '100%'
                },
                overlayDescription: {
                    ...ThemeText?.ParagraphSmall,
                    color: ThemeColors?.textSecondaryColor
                }
            },
            ServiceBlock: {
                desktopContainer: {
                    flexDirection: 'row',
                    maxHeight: 225,
                    minHeight: 225,
                    marginHorizontal: ThemeMargins?.gutter,
                    marginTop: 20
                },
                desktopText: {
                    ...ThemeText?.Paragraph,
                    marginVertical: 0,
                    marginHorizontal: 0,
                    fontFamily: 'GothamBook',
                    color: ThemeColors?.textPlaceholderColor,
                    paddingRight: 4
                },
                desktopDateTime: {
                    ...ThemeText?.Paragraph,
                    marginVertical: 0,
                    marginHorizontal: 0,
                    fontFamily: 'GothamBook',
                    color: ThemeColors?.textPlaceholderColor,
                    borderLeftColor: ThemeColors?.cardGreyBorderColor,
                    borderLeftWidth: 0.75,
                    paddingLeft: 4,
                    width: '60%'
                },
                container: {
                    flexDirection: 'row',
                    borderBottomColor: ThemeColors?.cardGreyBorderColor,
                    marginTop: 10,
                    borderBottomWidth: 1,
                    minHeight: 225,
                    maxHeight: 225
                },
                text: {
                    ...ThemeText?.Paragraph,
                    marginVertical: 0,
                    marginHorizontal: 0,
                    fontFamily: 'GothamBook',
                    marginLeft: 1,
                    color: ThemeColors?.textPrimaryColor,
                    paddingRight: isMobile ? 0 : 4
                },
                dateTime: {
                    ...ThemeText?.Paragraph,
                    marginVertical: 0,
                    marginHorizontal: 0,
                    fontFamily: 'GothamBook',
                    color: ThemeColors?.textPlaceholderColor,
                    borderLeftColor: isMobile ? undefined : ThemeColors?.cardGreyBorderColor,
                    borderLeftWidth: isMobile ? undefined : 0.75,
                    paddingLeft: isMobile ? 0 : 4
                }
            },
            ServiceContainer: {
                dropdownTextColor: ThemeColors?.textPrimaryColor,
                dropdownIconColor: ThemeColors?.secondaryColor,
                placeholderStyle: {
                    ...ThemeText?.Paragraph,
                    color: ThemeColors?.textPlaceholderColor,
                    marginHorizontal: 0,
                    fontFamily: 'GothamBook',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    padding: 2,
                    marginVertical: 0
                }
            },
            SiteFooter: {
                contactUs: {
                    ...ThemeText?.Paragraph,
                    marginTop: 0,
                    marginHorizontal: 0,
                    fontFamily: 'GothamMedium',
                    color: ThemeColors?.textSecondaryColor,
                    flexWrap: 'wrap',
                    marginBottom: 5
                },
                container: {
                    backgroundColor: ThemeColors?.primaryColor,
                    flex: 1,
                    alignItems: 'center'
                },
                containerRow: {
                    backgroundColor: ThemeColors?.primaryColor,
                    width: '100%',
                    flexWrap: 'wrap',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    paddingHorizontal: isMobile ? '5%' : isTablet ? '10%' : '15%'
                },
                contactContainer: {
                    width: '100%',
                    flexDirection: isMobile ? 'column-reverse' : 'row-reverse',
                    justifyContent: 'center'
                },
                affiliatesContainer: {
                    width: '100%',
                    marginVertical: ThemeMargins?.gutter,
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    flexGrow: 0.5
                },
                affiliatesLeftCol: {
                    width: isMobile ? '33%' : undefined
                },
                affiliatesRightCol: {
                    width: '33%'
                },
                affiliateContainer: {
                    alignItems: 'center',
                    flexDirection: 'row',
                    flex: 1,
                    marginBottom: 12
                },
                affiliateImage: {
                    height: Platform.OS === 'web' && !isMobile ? 50 : 25,
                    width: Platform.OS === 'web' && !isMobile ? 50 : 25,
                    color: ThemeColors?.textSecondaryColor,
                    marginRight: isMobile ? 2 : 5
                },
                affiliateText: {
                    ...ThemeText?.Paragraph,
                    marginVertical: 0,
                    marginHorizontal: 0,
                    fontFamily: 'GothamMedium',
                    color: ThemeColors?.textSecondaryColor,
                    flex: Platform.OS === 'web' ? 1 : undefined,
                    flexShrink: Platform.OS === 'web' ? 1 : undefined,
                    fontSize: isNarrow ? 8 : isMobile ? dynamicFontSize(12.5) : dynamicFontSize(14),
                    lineHeight: isMobile ? 18 : dynamicLineHeight(19, dynamicFontSize(14)),
                    letterSpacing: isMobile ? dynamicLetterSpacing(12.5) : dynamicLetterSpacing(14),
                    padding: isMobile ? 3 : 0
                },
                horizontalLineColor: undefined,
                legalContainer: {
                    alignItems: 'center',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    width: '100%',
                    justifyContent: 'center',
                    marginBottom: ThemeMargins?.gutter
                },
                legalText: {
                    ...ThemeText?.ParagraphSmall,
                    marginVertical: 0,
                    marginHorizontal: 0,
                    fontFamily: 'GothamBook',
                    color: ThemeColors?.textSecondaryColor
                },
                locationAddress: {
                    ...ThemeText?.Paragraph,
                    marginVertical: 0,
                    marginHorizontal: 0,
                    fontFamily: 'GothamBook',
                    color: ThemeColors?.textSecondaryColor,
                    flex: 0.5,
                    justifyContent: 'flex-start'
                },
                locationAddressCol: {},
                locationContainer: {
                    flex: 1,
                    flexWrap: 'wrap',
                    justifyContent: 'flex-end',
                    flexDirection: 'column-reverse'
                },
                locationEmail: {
                    ...ThemeText?.Paragraph,
                    marginVertical: 0,
                    marginHorizontal: 0,
                    fontFamily: 'GothamBook',
                    color: ThemeColors?.textSecondaryColor,
                    paddingBottom: 3
                },
                locationPhoneEmailCol: {},
                locationPhoneNumber: {
                    ...ThemeText?.Paragraph,
                    marginVertical: 0,
                    marginHorizontal: 0,
                    fontFamily: 'GothamBook',
                    color: ThemeColors?.textSecondaryColor,
                    paddingBottom: 3
                },
                privacyLink: {
                    ...ThemeText?.ParagraphSmall,
                    marginVertical: 0,
                    marginHorizontal: 0,
                    fontFamily: 'GothamBook',
                    color: ThemeColors?.textSecondaryColor,
                    marginLeft: 5,
                    textDecorationLine: 'underline'
                },
                socialsContainer: {
                    flexDirection: 'row',
                    flex: 1,
                    justifyContent: 'flex-start',
                    flexWrap: 'wrap'
                },
                socialMediaTitle: {
                    ...ThemeText?.Paragraph,
                    marginHorizontal: 0,
                    paddingRight: 4,
                    marginBottom: 2,
                    marginTop: 15,
                    fontFamily: 'GothamMedium',
                    color: ThemeColors?.textSecondaryColor
                },
                socialIconSize: isDesktopOrLaptop ? 32 : 24,
                socialIconStyle: {
                    color: ThemeColors?.textSecondaryColor,
                    padding: 1,
                    margin: 0
                },
                socialIconTypeFacebook: 'facebook',
                socialIconTypeTwitter: 'twitter',
                socialIconTypeInstagram: 'instagram',
                socialIconTypeYoutube: 'youtube',
                socialIconViewStyle: {
                    backgroundColor: ThemeColors?.transparent,
                    marginLeft: isMobile ? -5 : -12,
                    width: isMobile ? 40 : 55,
                    height: isMobile ? 40 : 55,
                    marginRight: 5
                },
                topLinksContainer: { width: '100%' },
                topLinkSocialsContainer: {
                    alignItems: 'flex-start',
                    marginTop: isMobile ? ThemeMargins?.inset1 : 0,
                    width: isMobile ? '100%' : '33%'
                },
                topLink: {
                    ...ThemeText?.Paragraph,
                    marginTop: 0,
                    marginHorizontal: 0,
                    marginBottom: 5,
                    fontFamily: 'GothamMedium',
                    color: ThemeColors?.textSecondaryColor,
                    textAlign: 'left',
                    width: '100%'
                }
            },
            SocialPostContainer: {
                instagramParagraph: {
                    ...ThemeText?.Paragraph,
                    marginVertical: 0,
                    fontFamily: 'GothamBook',
                    marginHorizontal: ThemeMargins.gutter,
                    textAlign: 'center',
                    color: ThemeColors?.textPlaceholderColor
                },
                socialPostHandle: {
                    fontFamily: 'GothamBook',
                    color: ThemeColors?.textSecondaryColor,
                    fontSize: 20,
                    lineHeight: 24,
                    textAlign: 'center'
                },
                postPlusImageHandleMobile: {
                    color: ThemeColors?.textPlaceholderColor,
                    fontFamily: 'GothamBook',
                    fontSize: 18,
                    paddingLeft: 10
                },
                postPlusImageHandle: {
                    color: ThemeColors?.textPlaceholderColor,
                    fontFamily: 'GothamBook',
                    fontSize: 16,
                    paddingLeft: 10
                },
                socialParagraph: {
                    color: ThemeColors?.textSecondaryColor,
                    fontSize: dynamicFontSize(24),
                    fontFamily: 'GothamBook',
                    lineHeight: dynamicLineHeight(24),
                    marginBottom: 15,
                    marginHorizontal: ThemeMargins?.gutter,
                    textAlign: 'center'
                }
            },
            TabbedAlternatingContainer: {
                activeTabName: {
                    color: ThemeColors?.textPrimaryColor
                },
                activeTabUnderline: {
                    borderBottomColor: ThemeColors?.textPrimaryColor,
                    borderBottomWidth: 2
                },
                inactiveTabName: {
                    color: ThemeColors?.textBlackColor
                },
                scrollViewStyle: {
                    width: isMobile ? '100%' : '50%',
                    height: 300,
                    backgroundColor: ThemeColors?.tertiaryColor
                },
                tabbedTitle: {
                    color: ThemeColors.textPlaceholderColor,
                    fontFamily: dynamicFontFamily('GothamBook'),
                    fontSize: dynamicFontSize(20),
                    lineHeight: dynamicLineHeight(28),
                    marginBottom: 2,
                    letterSpacing: -1
                },
                title: {
                    color: ThemeColors.textBlackColor,
                    fontFamily: dynamicFontFamily('GothamBook'),
                    fontSize: dynamicFontSize(36),
                    letterSpacing: 0,
                    lineHeight: dynamicLineHeight(48),
                    marginBottom: 40,
                    marginLeft: 20
                },
                heading: {
                    color: ThemeColors.textBlackColor,
                    fontFamily: dynamicFontFamily('GothamBook'),
                    fontSize: dynamicFontSize(17),
                    letterSpacing: 0,
                    lineHeight: dynamicLineHeight(23),
                    textAlign: isMobile ? 'center' : 'left',
                    marginHorizontal: ThemeMargins.gutter,
                    marginTop: ThemeMargins.gutter
                },
                description: {
                    color: ThemeColors.textPlaceholderColor,
                    fontSize: dynamicFontSize(16),
                    fontFamily: 'GothamBook',
                    lineHeight: 24,
                    marginHorizontal: ThemeMargins.gutter,
                    marginBottom: ThemeMargins.gutter
                },
                btnContainer: {
                    alignItems: isMobile ? 'center' : 'flex-start',
                    marginBottom: ThemeMargins.gutter,
                    marginHorizontal: ThemeMargins.gutter
                },
                btnTitleStyle: {
                    color: ThemeColors?.textPlaceholderColor,
                    fontSize: dynamicFontSize(16),
                    lineHeight: null,
                    marginHorizontal: 40,
                    fontFamily: 'GothamBook'
                }
            },
            TabbedContainer: {
                desktopContainer: {
                    alignItems: 'center',
                    flexDirection: 'row',
                    flex: 1,
                    justifyContent: 'center',
                    marginHorizontal: ThemeMargins?.gutterSmall
                },
                dropdown: {
                    backgroundColor: 'transparent',
                    borderBottomLeftRadius: 0,
                    borderBottomRightRadius: 0,
                    borderBottomWidth: 0,
                    borderLeftWidth: 0,
                    borderRightWidth: 0,
                    borderTopWidth: 0,
                    width: '100%'
                },
                dropdownPlaceholder: {
                    ...ThemeText?.Paragraph,
                    marginVertical: 0,
                    marginHorizontal: 0,
                    fontFamily: 'GothamBook',
                    color: ThemeColors?.textBlackColor
                },
                dropdownTextColor: ThemeColors?.textBlackColor,
                dropdownIconColor: ThemeColors?.textSecondaryColor,
                horizontalLineColor: ThemeColors?.baseColor,
                imageOverlayTitle: {
                    ...ThemeText?.H3,
                    color: ThemeColors.textBlackColor
                },
                overlay: {
                    bottom: 0,
                    height: '100%',
                    left: 0,
                    position: 'absolute',
                    right: 0,
                    width: '100%'
                },
                textWrapper: {
                    display: 'flex',
                    flexDirection: 'column',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    height: '100%',
                    width: '100%'
                },
                title: {
                    ...ThemeText?.Paragraph,
                    marginVertical: 10,
                    marginHorizontal: 0,
                    width: '100%',
                    fontFamily: 'GothamBook',
                    color: ThemeColors?.textBlackColor
                }
            },
            TabbedContainerItem: {
                container: {
                    flex: 0.5,
                    justifyContent: 'center',
                    alignItems: 'center',
                    pointerEvents: 'auto'
                },
                desktopContainer: {
                    flexDirection: 'row',
                    width: '100%',
                    flexWrap: 'wrap',
                    paddingVertical: ThemeMargins?.gutter,
                    alignItems: 'center'
                },
                headline: {
                    ...ThemeText?.H3,
                    color: ThemeColors.textBlackColor
                },
                btnStyle: {
                    borderColor: ThemeColors?.textSecondaryColor,
                    borderWidth: 0.75,
                    padding: 4,
                    width: isTablet ? '25%' : '50%'
                },
                btnTitle: {
                    ...ThemeText?.Paragraph,
                    marginVertical: 0,
                    marginHorizontal: 0,
                    fontFamily: 'GothamBook',
                    color: ThemeColors?.textSecondaryColor
                },
                headlineMobile: {
                    ...ThemeText?.Paragraph,
                    marginVertical: 0,
                    marginHorizontal: 0,
                    fontFamily: 'GothamBook',
                    color: ThemeColors?.textSecondaryColor
                },
                verticalBtnStyle: {
                    borderColor: ThemeColors?.baseColor,
                    borderWidth: 0.75,
                    width: isTablet ? '33%' : '50%'
                }
            },
            TextPlusImageWithLink: {
                btnContainerStyle: {
                    marginTop: 15,
                    padding: 0
                },
                btnIcon: undefined,
                btnStyle: {
                    backgroundColor: ThemeColors?.primaryColor,
                    shadowColor: ThemeColors?.textBlackColor,
                    shadowOpacity: 0.3,
                    shadowRadius: 15,
                    shadowOffset: { width: 2, height: 3 },
                    maxWidth: '50%',
                    flexDirection: 'column',
                    paddingHorizontal: 0
                },
                btnTitleStyle: {
                    ...ThemeText?.Paragraph,
                    marginHorizontal: 0,
                    fontFamily: 'GothamBook',
                    padding: 0,
                    color: ThemeColors?.textSecondaryColor,
                    fontWeight: 900,
                    textTransform: 'uppercase'
                },
                btnType: 'solid',
                card: {
                    flexDirection: 'row',
                    flex: 1,
                    flexWrap: 'wrap',
                    justifyContent: 'center',
                    marginHorizontal: isDesktopOrLaptop ? '15%' : '10%',
                    marginBottom: ThemeMargins?.gutter
                },
                contentContainer: {
                    flex: 1,
                    justifyContent: 'center',
                    paddingLeft: ThemeMargins?.gutter
                },
                description: {
                    ...ThemeText?.RichTextParagraph,
                    color: ThemeColors.textPlaceholderColor
                },
                desktopContainer: {
                    alignItems: 'center',
                    flex: 1
                },
                heading: {
                    ...ThemeText?.H3,
                    marginLeft: 0,
                    marginRight: 0,
                    marginBottom: 10,
                    fontWeight: 900
                },
                imageContainer: {
                    width: isDesktopOrLaptop ? width * 0.3 : 300,
                    height: isDesktopOrLaptop ? width * 0.3 : 300
                },
                imageContainerStyle: {
                    width: '100%',
                    height: '100%',
                    flexShrink: 2
                },
                imageStyle: {
                    height: '100%',
                    width: '100%',
                    aspectRatio: 1
                },
                mobileBtnContainerStyle: {
                    alignItems: 'center',
                    minWidth: '100%',
                    marginVertical: dynamicMarginSize(10)
                },
                mobileBtnStyle: {
                    textAlign: 'center',
                    backgroundColor: ThemeColors?.primaryColor,
                    shadowColor: ThemeColors?.textBlackColor,
                    shadowOpacity: 0.3,
                    shadowRadius: 15,
                    shadowOffset: { width: 2, height: 3 },
                    width: width / 3
                },
                mobileContainer: {
                    flex: 1,
                    width: width,
                    alignItems: 'center',
                    paddingHorizontal: isTablet && width < 650 ? '10%' : '5%',
                    marginBottom: ThemeMargins?.gutter
                },
                mobileDescription: {
                    ...ThemeText?.Paragraph,
                    marginHorizontal: 0,
                    fontFamily: 'GothamBook',
                    color: ThemeColors.textPlaceholderColor,
                    letterSpacing: 0
                },
                mobileHeading: {
                    ...ThemeText?.H3,
                    fontWeight: 900,
                    paddingVertical: 0,
                    margin: 0
                },
                mobileImageContainer: {
                    width: '100%',
                    height: '100%',
                    flexShrink: 2
                },
                mobileImageStyle: {
                    height: '100%',
                    width: '100%',
                    aspectRatio: 1
                },
                mobileTextContentContainer: {
                    width: '100%',
                    marginTop: 10
                },
                // No scroll for DM
                scrollContentContainer: undefined,
                scrollStyle: undefined,
                scrollContentView: undefined
            },
            TopNavLinkText: {
                linkText: {
                    color: ThemeColors?.primaryColor,
                    fontWeight: 600,
                    fontSize: 14,
                    fontFamily: 'GothamMedium',
                    lineHeight: 20,
                    letterSpacing: -0.2
                },
                activeLink: {
                    textShadowColor: '',
                    textShadowOffset: { height: 0, width: 0 },
                    textShadowRadius: 0,
                    textShadowOpacity: 0
                },
                hovered: {
                    color: ThemeColors?.textBlackColor,
                    fontWeight: 600,
                    fontSize: 14,
                    fontFamily: 'GothamMedium',
                    lineHeight: 20,
                    letterSpacing: -0.2
                },
                subMenu: {
                    position: 'absolute',
                    top: 51,
                    right: 50,
                    borderColor: ThemeColors?.textBlackColor,
                    borderWidth: 0.5,
                    borderRadius: 3,
                    paddingHorizontal: 20,
                    paddingVertical: 5,
                    justifyContent: 'center',
                    backgroundColor: ThemeColors?.baseColor,
                    zIndex: 999999,
                    shadowColor: ThemeColors?.textBlackColor,
                    shadowOffset: { height: 4, width: -4 },
                    shadowRadius: 6,
                    shadowOpacity: 0.25
                },
                subMenuIconColor: ThemeColors?.primaryColor,
                subMenuText: {
                    color: ThemeColors?.textBlackColor,
                    fontWeight: 600,
                    fontSize: 14,
                    fontFamily: 'GothamMedium',
                    lineHeight: 20,
                    letterSpacing: -0.2
                }
            },
            TwoColumnWYSIWYG: {
                container: {
                    justifyContent: 'center',
                    flexWrap: 'wrap',
                    flex: 1,
                    flexDirection: 'row'
                },
                contentContainer: {
                    flex: 1,
                    marginHorizontal: isMobile ? '5%' : isTablet ? '10%' : '15%'
                },
                leftCol: {
                    flex: 1,
                    alignItems: 'center'
                },
                leftColMarginRight: ThemeMargins?.inset3,
                oneCol: {
                    flex: 1,
                    alignItems: 'center',
                    flexWrap: 'wrap'
                },
                rightCol: {
                    flex: 1,
                    alignItems: 'center'
                },
                title: {
                    ...ThemeText?.ComponentTitle,
                    marginBottom: 25,
                    margin: 0,
                    padding: 0,
                    textTransform: 'uppercase',
                    fontWeight: 900
                }
            }
        }
    };
};
