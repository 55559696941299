import Constants                from 'expo-constants';
import { get, isEmpty, values } from 'lodash';

export const AncillaryIconMappings = {
	'give now': {
		name: 'sprout',
		type: 'material-community'
	},	
	visit: {
		name: 'cross',
		type: 'material-community'
	},
	watch: {
		name: 'youtube',
		type: 'material-community'
	}
};

export const collectAncillaryOptions = siteWrapper => {
	const ancillary  = get(siteWrapper?.contentItems, 'Ancillary Navigation' );
	const options    = {
		logo: undefined,
		links: [],
		menus: []
	};

	if (ancillary) {
		const icon  = get(ancillary, 'logo.cdnImagePath');

		if (icon) {
			options.logo = `https:${icon}`;
		}

		const links = get(ancillary.contentItems, 'Links');

		if (links){
			values(links.contentItems).forEach(link => {
				const isExternal = link.link.startsWith('http');
				
				options.links.push({
					text: link.linkText, 
					url: link.link,
					isExternal
				});
			});
		}

		const menus = get(ancillary.contentItems, 'Menus');

		if (menus) {
			values(menus.contentItems).forEach(menu => {
				const name    = menu.linkText;
				const entries = [];

				values(menu.contentItems).forEach(menuEntry => {
					const isExternal = menuEntry.link.startsWith('http');

					entries.push({
						name, 
						text: menuEntry.linkText, 
						url: menuEntry.link,
						isExternal
					});
				});

				options.menus.push({
					entries,
					name
				});
			});
		}
	}

	return options;
};

export const findActive = (path, depth) =>{
	if (path?.equals('/')) {
		return '/';
	}
	else {
		let activePath;

		if (path?.lastIndexOf('/') === path?.length - 1) {
			activePath = path?.slice(1, -1);
		}
		else {
			activePath = path?.replace('/', '');
		}

		if (activePath) {
			const parts = activePath.split('/');

			if (Array.isArray(parts)) {
				if (depth && depth <= parts.length) {
					return parts[ depth ];
				}
				else {
					return parts[ 0 ];
				}
			}
		}
	}

	return undefined;
};

export const parseNavigation = (data, siteSettings) => {
	if (!data || isEmpty(data)) {
		return null;
	}

	const primary                = [];
	const secondary              = {};
	const tertiary               = {};
	let autoPopulateTertiary     = false;

	Object.keys(data).forEach(key => {
		const entry = data[key];

		if (entry.rootPath?.equals('/') || entry.navigationOnly) {
			primary.push(entry);
		}
		else if (entry.rootPath?.substring(1, entry.rootPath.length).split('/').length === 1) {
			primary.push(entry);
		}
		else if (!entry.navigationOnly) {
			if (entry.rootPath?.charCount('/') > 1) {
				const path   = entry.rootPath?.slice(1).split('/');
				const parent = path[0];
				
				if (!secondary.hasOwnProperty(parent)) {
					secondary[parent] = [ entry ];
				}
				else {
					secondary[path[0]].push(entry);
				}

				secondary[path[0]].sort((a, b) => a.sortOrder - b.sortOrder);
			}
		}
	});

	if (siteSettings?.environment?.equals('Staging') || siteSettings?.environment?.equals('Development')) {
		primary.push({
			id: '999999',
			title: 'Test',
			menuItemText: 'Test',
			openInNewPage: false,
			hasAncillaryNavigation: true,
			doNotIncludeInNavigation: false,
			navigationOnly: false,
			rootPath: '/test',
			sortOrder: 15,
		})
	}

	primary.sort((a, b)   => a.sortOrder - b.sortOrder);

	return {
		autoPopulateTertiary,
		primary,
		secondary,
		tertiary
	};
};
