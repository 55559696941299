import React                        from 'react';
import { View }                     from 'react-native';
import { useTheme, Button, Text }   from '@rneui/themed';
import PropTypes                    from 'prop-types';

const UnsupportedPage = props => 
{
    const { theme } = useTheme();

    return (
        <View style={{ alignItems: 'center', flex: 1, justifyContent: 'center'  }}>
            <Text style={{ ...theme?.text?.H2, color: theme?.colors?.red, paddingBottom: 20 }}>
                Unfortunately this page is not supported in this environment!
            </Text>
            <Button buttonStyle={{ backfaceColor: theme?.colors?.Red }}
                    onPress={props.goBack}
                    title='Go Back'
                    titleStyle={theme?.text?.White}
            />
        </View>
    );
};

UnsupportedPage.propTypes = {
    goBack: PropTypes.func.isRequired
}

export default React.memo(UnsupportedPage);
