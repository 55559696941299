import { Platform } from 'react-native';
import Constants from 'expo-constants';
import Axios from 'axios';
import { setupCache } from 'axios-cache-adapter';
import * as ExpoFileLogger from 'expo-file-logger';
import { get as _Get, values } from 'lodash';
import AppConstants from '../common/constants';
import { AppActions, AppStatus } from '../contexts/app';

ExpoFileLogger.log('debug', 'Intializing API cache');

const cache = setupCache({
    debug: Constants.manifest.extra?.enableApiLogging !== 'false',
    exclude: { query: false },
    maxAge: 15 * 60 * 1000
});

const axios = Axios.create({
    adapter: cache.adapter,
    baseURL: Constants.manifest.extra?.baseUrl
});

const fetchDomainItemByKey = async (domain, key) => {
    if (!domain || !key) {
        return null;
    }

    ExpoFileLogger.log('debug', `Fetching domain[${domain}] item by key[${key}]`);

    try {
        const response = await axios.get(`${AppConstants.REACT_APP_DOMAIN_ITEM_BY_KEY_BASE}?domain=${domain}&key=${key}`);

        if (response) {
            ExpoFileLogger.log('debug', `Successfully fetched domain[${domain}] item by key[${key}]`);

            return response.data;
        }
    } catch (e) {
        ExpoFileLogger.log('error', `Error fetching domain item with key ${key} - ${e.message}`);
    }

    return null;
};

const fetchFeaturedService = async (campus, speaker) => {
    let endpoint = AppConstants.REACT_APP_FEATURED_SERVICE_BASE;

    if (campus) {
        endpoint += `?campus=${campus}`;
    }

    if (speaker) {
        if (endpoint.indexOf('campus') !== -1) {
            endpoint += `&speaker=${speaker}`;
        } else {
            endpoint += `?speaker=${speaker}`;
        }
    }

    ExpoFileLogger.log('debug', `Fetching featured service from [${endpoint}]`);

    try {
        const response = await axios.get(endpoint);

        if (response) {
            ExpoFileLogger.log('debug', `Successfully fetched featured service from [${endpoint}]`);

            return response.data;
        }
    } catch (e) {
        ExpoFileLogger.log('error', `Error fetching featured service - ${e.message}`);
    }

    return null;
};

const fetchLiveService = async () => {
    ExpoFileLogger.log('debug', 'Fetching live service');
    try {
        const response = await axios.get(AppConstants.REACT_APP_LIVE_SERVICE_BASE);

        if (response) {
            ExpoFileLogger.log('debug', 'Successfully fetched live service');

            return response.data;
        }
    } catch (e) {
        ExpoFileLogger.log('error', `Error fetching live service - ${e.message}`);
    }

    return null;
};

const fetchPage = async (dispatch, path) => {
    const endpoint = AppConstants.REACT_APP_PAGE_BASE + path;

    dispatch({
        type: AppActions.API_STATE_CHANGE,
        status: AppStatus.LOADING
    });

    ExpoFileLogger.log('debug', `Fetching page [${endpoint}]`);

    try {
        const response = await axios.get(endpoint);

        if (response.data) {
            dispatch({
                data: response.data,
                href: path,
                status: AppStatus.READY,
                type: AppActions.API_REQUEST
            });

            ExpoFileLogger.log('debug', `Successfully fetched page [${path}]`);

            return response.data;
        }
    } catch (e) {
        ExpoFileLogger.log('error', `API encountered and error @ endpoint [${endpoint}] with error - [${e.message}]`);

        dispatch({
            status: AppStatus.FAILED,
            type: AppActions.API_REQUEST
        });

        return e;
    }

    return null;
};

const fetchStartupData = async startPage => {
    const tmpGlobals = {};

    ExpoFileLogger.log('debug', 'Fetching startup data');

    try {
        const settings = await axios.get(AppConstants.REACT_APP_SETTINGS_BASE);
        const subscriptions = await axios.get(AppConstants.REACT_APP_SUBSCRIPTION_INFO);

        settings.data.subscriptions = subscriptions.data;
        tmpGlobals.settings = settings.data;

        try {
            const countries = await axios.get(`${Constants.manifest?.extra?.apiUrl}${AppConstants.REACT_APP_GET_COUNTRIES_BASE}`);

            if (countries.data) {
                tmpGlobals.countries = countries.data;
            }
        } catch (er) {
            ExpoFileLogger.log('error', `Error loading countries: ${er.message}`);
        }

        const startupData = await axios.get(AppConstants.REACT_APP_STARTUP_DATA_BASE);

        if (startupData.data) {
            tmpGlobals.domain = { haivisionAdPolicy: startupData.data.haivisionAdPolicy };

            const siteWrapperWeb = _Get(startupData.data, 'siteWrapperWeb');
            const siteWrapperApp = _Get(startupData.data, 'siteWrapperApp');

            if (siteWrapperWeb || siteWrapperApp) {
                tmpGlobals.siteWrapper = {
                    web: siteWrapperWeb,
                    native: { ...siteWrapperWeb, ...siteWrapperApp }
                };
            }

            const domainRedirects = _Get(startupData.data, 'redirects');

            if (domainRedirects) {
                const redirects = {};

                values(domainRedirects.contentItems).forEach(redirect => {
                    redirects[redirect.legacyUrl] = redirect;
                });

                tmpGlobals.redirects = redirects;
            }

            const domainVanities = _Get(startupData.data, 'vanityUrls');

            if (domainVanities) {
                const vanities = {};

                values(domainVanities.contentItems).forEach(async vanity => {
                    const isExternal = vanity.redirectTo.startsWith('http');

                    vanities[vanity.nodeName.toLowerCase()] = {
                        internal: !isExternal,
                        url: vanity.redirectTo
                    };
                });

                tmpGlobals.vanityUrls = vanities;
            }
        }
    } catch (e) {
        ExpoFileLogger.log('error', `Error loading global settings: ${e.message}`);
    }

    try {
        const navigationResponse = await axios.get(AppConstants.REACT_APP_NAVIGATION_BASE);

        if (navigationResponse.data) {
            tmpGlobals.navigation = navigationResponse.data;
        }
    } catch (e) {
        ExpoFileLogger.log('error', `Error loading navigation: [${e.message}]`);
    }

    try {
        let endpoint = `${Constants.manifest.extra.baseUrl}${AppConstants.REACT_APP_PAGE_BASE}${startPage}`;

        if (Platform.OS.equals('web')) {
            if (tmpGlobals?.redirects?.hasOwnProperty(window.location.pathname)) {
                endpoint = `${Constants.manifest.extra.baseUrl}${AppConstants.REACT_APP_PAGE_BASE}${
                    tmpGlobals.redirects[window.location.pathname].redirectTo
                }`;
            }

            let vanityUrl = _Get(tmpGlobals.vanityUrls, window.location.pathname.slice(1).toLowerCase());

            if (vanityUrl?.internal) {
                endpoint = `${Constants.manifest.extra.baseUrl}${AppConstants.REACT_APP_PAGE_BASE}${vanityUrl.url}`;
            }
        }

        ExpoFileLogger.log('debug', `Fetching startup page[${endpoint}]`);

        const response = await axios.get(endpoint);

        if (response?.data) {
            tmpGlobals.activePage = response.data;
        }
    } catch (e) {
        ExpoFileLogger.log('error', `Error pre-loading start page: [${e.message}]`);
    }

    return tmpGlobals;
};

const fetchVcmsContent = async haivisionPublicId => {
    ExpoFileLogger.log('debug', `Fetching VCMS content for PID[${haivisionPublicId}]`);

    try {
        const response = await axios.get(`${AppConstants.REACT_APP_VCMS_MEDIA_BASE}${haivisionPublicId}`);

        if (response) {
            ExpoFileLogger.log('debug', `Successfully fetched VCMS content for PID[${haivisionPublicId}]`);
            return response.data;
        }
    } catch (e) {
        ExpoFileLogger.log('error', `Error fetching VCMS content for PID[${haivisionPublicId}] - [${e.message}]`);
    }

    return null;
};

const post = async (endpoint, body, onSuccess, contentType = 'multipart/form-data', extraHeaders) => {
    ExpoFileLogger.log('debug', `Posting to [${endpoint}]`);

    let data = body;

    if (body && contentType?.equals('multipart/form-data')) {
        data = new FormData();
        Object.keys(body).forEach(key => data.append(key, body[key]));
    }

    try {
        const response = await axios.post(endpoint, data, {
            headers: {
                'Content-Type': contentType,
                ...extraHeaders
            }
        });

        if (response) {
            ExpoFileLogger.log('debug', `Successfully posted to [${endpoint}]`);

            if (onSuccess) {
                onSuccess(response.data);
            }
            return response.data;
        }
    } catch (e) {
        ExpoFileLogger.log('error', `Error posting to [${endpoint}] - [${e.message}]`);
    }

    return null;
};

const postSync = (endpoint, body, contentType = 'multipart/form-data') => {
    ExpoFileLogger.log('debug', `Posting syncrhonously to [${endpoint}]`);

    let data = body;

    if (contentType.equals('multipart/form-data')) {
        data = new FormData();
        Object.keys(body).forEach(key => data.append(key, body[key]));
    }

    return axios.post(endpoint, data, {
        headers: {
            'Content-Type': contentType
        }
    });
};

const get = async endpoint => {
    ExpoFileLogger.log('debug', `Retrieveing from [${endpoint}]`);

    try {
        const response = await axios.get(endpoint);

        if (response) {
            ExpoFileLogger.log('debug', `Successfully retrieved from [${endpoint}]`);

            return response.data;
        }
    } catch (e) {
        ExpoFileLogger.log('error', `Error retrieving from [${endpoint}] - [${e.message}]`);
    }

    return null;
};

const fetch = async (endpoint, body, method = 'GET', headers, contentType = 'application/json') => {
    if (!endpoint) {
        return;
    }

    ExpoFileLogger.log('debug', `Fetching from [${endpoint}]`);

    try {
        const response = await window.fetch(endpoint, {
            method,
            headers: {
                ...headers,
                'Content-Type': contentType
            },
            body: body && JSON.stringify(body)
        });

        if (response?.ok) {
            return await response.json();
        }
    } catch (e) {
        ExpoFileLogger.log('error', `Error fetching from [${endpoint}] - [${e.message}]`);
    }
};

const useApi = () => {
    return {
        fetch,
        fetchDomainItemByKey,
        fetchFeaturedService,
        fetchLiveService,
        fetchPage,
        fetchStartupData,
        fetchVcmsContent,
        get,
        post,
        postSync
    };
};

export default useApi;
