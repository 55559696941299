import { useEffect }       from 'react';
import { get }             from 'lodash';
import * as ExpoFileLogger from'expo-file-logger';

const LoggedComponent = props => {
    const cmpName = get(props, 'name');
    
    useEffect(() => {
        ExpoFileLogger.log(
            'debug', 
            `Component [${cmpName}] was mounted`
        );

        return () =>  
            ExpoFileLogger.log(
                'debug', 
                `Component [${cmpName}] was unmounted`
            );
    }, []);

    return (
        props.children
    );
};

export default LoggedComponent;
