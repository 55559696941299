import React                           from'react';
import { View }                        from 'react-native';
import { useTheme, Text }              from '@rneui/themed';
import { get }                         from 'lodash';
import PropTypes                       from 'prop-types';
import SocialLinks                     from './SocialLinks';
import { StyledWebView }               from '../common';
import { useDeviceInfo }               from '../../hooks';
import { default as AppConstants }     from '../../common/constants';

const STYLED_WEB_VIEW_ID = 'cornerBlockWYSIWYG';

const CornerBlockWYSIWYG = props => 
{
    const {os}                              = useDeviceInfo();
    const { theme }                         = useTheme();
    const title                             = get(props.json, 'title');
    const useHorizontalRule                 = get(props.json, 'useHorizontalRule');
	const horizontalRuleColor               = get(props.json, 'horizontalRuleColor');
	const facebookLink                      = get(props.json, 'facebookLink.externalUrl');
	const instagramLink                     = get(props.json, 'instagramLink.externalUrl');
	const twitterLink                       = get(props.json, 'twitterLink.externalUrl');
	const text                              = get(props.json, 'text');
    const backgroundColor                   = get(props,'backgroundColor');
    const textColor                         = get(props,'textColor');

    if (!text) {
	    return null;
    };

    const nativeStyles= { 
        p: { 
            color: textColor, 
            fontFamily: 'GothamBook', 
            fontSize: 20, 
            lineHeight: 25 
        } 
    };
    const customWebStyles=`#${STYLED_WEB_VIEW_ID} p{
                                                      margin:10pt ${theme?.margins?.gutter}pt !important;
                                                      color:${textColor} !important;
                                                      font-family:GothamBook !important;
                                                      font-size:16pt !important;
                                                      line-height:20pt !important;
                                                      padding:0pt !important;
                                                    }`;

    return (
        <View style={{backgroundColor:backgroundColor,flex:1}}>
				{
					!!title &&
						<View style={{justifyContent: 'center', flexDirection:'row'}}>
							<Text numberOfLines={1} style={{...theme?.appComponents?.umbraco?.CornerBlockWYSIWYG?.title, color: textColor}}>
                                { title }
                            </Text>
						</View>
				}
				{
					useHorizontalRule &&
						<View style={{ 
                                  ...theme?.common?.HorizontalRule, 
                                  alignSelf:'center', 
                                  width:'75%',
                                  opacity: 0.5, 
                                  borderBottomColor: horizontalRuleColor || '#ffffff'
                              }} 
                        />
				}
                <View style={{
                              flexDirection:'row',
                              justifyContent:'center',
                              alignItems:'center',
                              }}>
                    <StyledWebView html={text}
                                   customStyle={os.equals('web')? customWebStyles:nativeStyles}
                                   nodeId={STYLED_WEB_VIEW_ID}
                                   testID='CornerBlockWYSIWYG.StyledWebView'
                                />
                </View>
     
 				<View style={{
                              flexDirection:'row',
                              justifyContent: 'center', 
                              alignItems:'center',
                              backgroundColor:'inherit',
                              marginVertical: 0,
                              }}
                              testID='CornerBlockWYSIWYG.SocialLinks.View'
                              >
					{
						!!facebookLink && 
							<SocialLinks link={facebookLink} 
                                         type={AppConstants.SOCIAL_MEDIA_FACEBOOK} 
                                         style={{backgroundColor:backgroundColor,}} 
                                         size={38} 
                                         iconStyle={{paddingHorizontal:5,color:theme?.colors?.white}} 
                            />
					}
					{
						!!instagramLink &&
							<SocialLinks link={instagramLink} 
                                         type={AppConstants.SOCIAL_MEDIA_INSTAGRAM} 
                                         style={{backgroundColor:backgroundColor}} 
                                         size={38} 
                                         iconStyle={{paddingHorizontal:5,color:theme?.colors?.white}} />
					}							
					{
						!!twitterLink && 
							<SocialLinks link={twitterLink} 
                                         type={AppConstants.SOCIAL_MEDIA_TWITTER} 
                                         style={{backgroundColor:backgroundColor}} 
                                         size={38} 
                                         iconStyle={{paddingHorizontal:5,color:theme?.colors?.white}}
                            />
					}
				</View>
		</View>
    );
};

CornerBlockWYSIWYG.propTypes = {
    json: PropTypes.shape({
        facebookLink: PropTypes.object,
        horizontalRuleColor: PropTypes.string,
        instagramLink: PropTypes.object,
        text: PropTypes.string,
        title: PropTypes.string,
        twitterLink: PropTypes.object,
        useHorizontalRule: PropTypes.bool
    })
};

export default CornerBlockWYSIWYG;
