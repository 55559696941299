import { StyleSheet } from 'react-native';
import ThemeColors from './colors';
import { dynamicFontSize, dynamicFontFamily } from '../common';
import { getText } from './text';

export const getNavigation = () => {
    const ThemeText = getText();

    return {
        mobile: {
            WhereIndicator: {
                button: {
                    color: ThemeColors.white
                },
                buttonStyle: {
                    borderColor: ThemeColors?.white,
                    borderRadius: 2
                },
                buttonContainerStyle: {
                    marginLeft: 5,
                    shadowRadius: 8,
                    shadowColor: ThemeColors?.white,
                    shadowOffset: { width: 3, height: 4 },
                    shadowOpacity: 0.2
                },
                container: {
                    alignItems: 'center',
                    backgroundColor: ThemeColors.black,
                    flexDirection: 'row',
                    flexGrow: 3,
                    height: 50,
                    justifyContent: 'space-between'
                },
                searchIcon: {
                    color: ThemeColors?.white,
                    size: 28,
                    type: 'material-community'
                },
                text: {
                    color: ThemeColors.white,
                    fontFamily: dynamicFontFamily('GothamBook'),
                    fontSize: dynamicFontSize(18),
                    justifyContent: 'center',
                    marginVertical: 10
                },
                titleStyle: {
                    color: ThemeColors?.white,
                    fontFamily: 'GothamBook',
                    fontSize: 16,
                    marginBottom: 0,
                    lineHeight: 18
                }
            },
            MenuBar: {
                containerStyle: {
                    backgroundColor: ThemeColors.white,
                    borderBottomColor: ThemeColors.grey2,
                    borderBottomWidth: StyleSheet.hairlineWidth,
                    height: 50,
                    flexDirection: 'row',
                    justifyContent: 'space-between'
                },
                hamburgerIcon: {
                    color: ThemeColors?.red,
                    size: 30,
                    type: 'material-community'
                },
                leftComponent: {
                    alignItems: 'center',
                    backgroundColor: 'transparent',
                    height: 50,
                    justifyContent: 'flex-start'
                },
                logo: {
                    justifyContent: 'center',
                    source: require('../../../assets/images/logo.png'),
                    type: 'image'
                },
                loginIconColor: ThemeColors?.red,
                BottomSheet: {
                    container: {
                        backgroundColor: ThemeColors?.white,
                        borderBottomColor: ThemeColors?.secondary,
                        borderBottomWidth: 0.75
                    },
                    titleStyle: {
                        color: ThemeColors?.primary
                    },
                    cancelBtnContainer: {
                        backgroundColor: ThemeColors?.puce
                    },
                    cancelBtnTitle: {
                        color: ThemeColors?.white
                    }
                },
                rightComponent: {
                    alignContent: 'center',
                    backgroundColor: ThemeColors.transparent,
                    flexDirection: 'row',
                    height: 50,
                    justifyContent: 'center',
                    text: {
                        color: ThemeColors.navy,
                        fontFamily: 'GothamBook',
                        fontSize: 14,
                        marginRight: 10
                    }
                }
            },
            NavigationList: {
                ancillaryOptionIcon: {
                    color: ThemeColors?.white,
                    size: 25,
                    name: 'info',
                    type: 'font-awesome-5'
                },
                ancillaryText: {
                    alignContent: 'center',
                    fontFamily: dynamicFontFamily('GothamMedium'),
                    fontSize: dynamicFontSize(24),
                    margin: 5,
                    color: ThemeColors?.white
                },
                breadcrumbContainer: {
                    alignItems: 'center',
                    backgroundColor: 'black',
                    flexDirection: 'row',
                    height: 50
                },
                chevron: {
                    marginRight: 10,
                    borderColor: ThemeColors.primary,
                    color: ThemeColors?.primary
                },
                chevronColor: ThemeColors?.navy,
                container: {
                    alignContent: 'center',
                    backgroundColor: ThemeColors?.navy,
                    flexDirection: 'row',
                    justifyContent: 'space-around',
                    height: 75,
                    width: '100%'
                },
                text: {
                    color: ThemeColors.grey2,
                    alignContent: 'center',
                    fontFamily: dynamicFontFamily('GothamMedium'),
                    fontSize: dynamicFontSize(24)
                }
            }
        },
        web: {
            Ancillary: {
                searchIconStyle: {
                    borderLeftColor: ThemeColors?.grey3,
                    borderLeftWidth: StyleSheet.hairlineWidth,
                    paddingLeft: 5
                },
                liveButton: {
                    backgroundColor: ThemeColors?.red,
                    marginLeft: 2,
                    paddingHorizontal: 2,
                    paddingVertical: 2,
                    position: 'relative'
                },
                link: {
                    title: {
                        ...ThemeText.Navy,
                        fontFamily: 'GothamMedium'
                    }
                },
                dropdown: {
                    placeholderStyle: {
                        ...ThemeText.Navy,
                        fontFamily: 'GothamMedium',
                        marginRight: 10
                    },
                    container: {
                        borderRadius: 0,
                        borderWidth: 0,
                        flexDirection: 'row',
                        justifyContent: 'center',
                        marginRight: 10
                    }
                },
                headerContainer: {
                    backgroundColor: 'transparent',
                    borderBottomColor: ThemeColors?.grey3,
                    borderBottomWidth: StyleSheet.hairlineWidth,
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    width: '100%'
                },
                leftContainer: {
                    flexDirection: 'row',
                    justifyContent: 'space-evenly'
                },
                rightContainer: {
                    alignItems: 'center',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    marginRight: 10
                }
            },
            Primary: {
                container: {
                    backgroundColor: '#000',
                    justifyContent: 'flex-end',
                    overflow: 'hidden'
                },
                listItemTitle: {
                    fontFamily: 'GothamBook',
                    color: 'white',
                    fontSize: 16,
                    lineHeight: 18,
                    marginBottom: 0,
                    marginRight: 10
                },
                listItemIcon: {
                    color: ThemeColors.white,
                    name: 'caret-left',
                    size: 20,
                    type: 'font-awesome-5'
                },
                logo: {
                    height: 140,
                    marginTop: 20,
                    width: 240
                }
            },
            Secondary: {}
        }
    };
};
