import ThemeColors from './colors';
import { dynamicFontSize, dynamicFontFamily, dynamicLineHeight, dynamicMarginSize, dynamicLetterSpacing } from '../common';

export const getText = () => {
    return {
        General: {
            fontFamily: 'GothamBook',
            fontSize: 18,
            letterSpacing: 0,
            lineHeight: 20
        },
        ComponentTitle: {
            color: ThemeColors.black,
            fontFamily: dynamicFontFamily('GothamBook'),
            fontSize: dynamicFontSize(36),
            marginLeft: dynamicMarginSize(30),
            lineHeight: dynamicLineHeight(48, dynamicFontSize(36))
        },
        SmallText: {
            color: ThemeColors.black,
            fontFamily: 'GothamBook',
            fontSize: 12
        },
        H1: {
            color: ThemeColors.black,
            fontFamily: dynamicFontFamily('GothamBook'),
            fontSize: dynamicFontSize(36),
            letterSpacing: 0,
            lineHeight: dynamicLineHeight(48, dynamicFontSize(36))
        },

        H2: {
            color: ThemeColors.black,
            fontFamily: dynamicFontFamily('GothamBook'),
            fontSize: dynamicFontSize(27),
            letterSpacing: 0,
            lineHeight: dynamicLineHeight(36, dynamicFontSize(27))
        },

        H3: {
            color: ThemeColors.black,
            fontFamily: dynamicFontFamily('GothamBook'),
            fontSize: dynamicFontSize(24),
            letterSpacing: 0,
            lineHeight: dynamicLineHeight(30, dynamicFontSize(24))
        },

        H4: {
            color: ThemeColors.black,
            fontFamily: dynamicFontFamily('GothamBook'),
            fontSize: dynamicFontSize(18),
            letterSpacing: 0,
            lineHeight: dynamicLineHeight(28, dynamicFontSize(18))
        },

        H5: {
            color: ThemeColors.black,
            fontFamily: dynamicFontFamily('GothamBook'),
            fontSize: dynamicFontSize(14),
            letterSpacing: 0,
            lineHeight: dynamicLineHeight(23, dynamicFontSize(14))
        },

        H6: {
            color: ThemeColors.black,
            fontFamily: dynamicFontFamily('GothamBook'),
            fontSize: dynamicFontSize(11),
            letterSpacing: 2,
            lineHeight: dynamicLineHeight(15, dynamicFontSize(11)),
            textTransform: 'uppercase'
        },
        Paragraph: {
            color: ThemeColors.grey,
            fontFamily: dynamicFontFamily('GothamBook'),
            fontSize: dynamicFontSize(16),
            lineHeight: dynamicLineHeight(18, dynamicFontSize(16)),
            letterSpacing: dynamicLetterSpacing(dynamicFontSize(16)),
            marginHorizontal: 40
        },
        RichTextParagraph: {
            color: ThemeColors.grey,
            fontFamily: dynamicFontFamily('FrankRuhlLibre'),
            fontSize: dynamicFontSize(18),
            lineHeight: dynamicLineHeight(22, dynamicFontSize(18)),
            letterSpacing: dynamicLetterSpacing(dynamicFontSize(18)),
            marginVertical: dynamicMarginSize(10),
            textAlign: 'left'
        },
        ParagraphSmall: {
            color: ThemeColors.grey2,
            fontFamily: dynamicFontFamily('GothamBook'),
            fontSize: dynamicFontSize(12),
            lineHeight: dynamicLineHeight(18, dynamicFontSize(12)),
            letterSpacing: dynamicLetterSpacing(dynamicFontSize(12)),
            margin: 0
        },

        Small: {
            fontFamily: 'GothamBook',
            fontSize: 14,
            lineHeight: 18
        },

        White: {
            fontFamily: 'GothamBook',
            color: 'white',
            fontSize: 16,
            marginBottom: 0,
            lineHeight: 18
        },

        Black: {
            fontFamily: 'GothamBook',
            color: ThemeColors.black,
            fontSize: 16,
            marginBottom: 0,
            lineHeight: 18
        },

        Grey1: {
            fontFamily: 'GothamBook',
            color: ThemeColors.grey,
            fontSize: 16,
            marginBottom: 0,
            lineHeight: 18
        },

        Grey2: {
            fontFamily: 'GothamBook',
            color: ThemeColors.grey2,
            fontSize: 16,
            marginBottom: 0,
            lineHeight: 18
        },

        Grey3: {
            fontFamily: 'GothamBook',
            color: ThemeColors.grey3,
            fontSize: 16,
            marginBottom: 0,
            lineHeight: 18
        },

        Grey4: {
            fontFamily: 'GothamBook',
            color: ThemeColors.grey4,
            fontSize: 16,
            marginBottom: 0,
            lineHeight: 18
        },

        Navy: {
            fontFamily: 'GothamBook',
            color: ThemeColors.navy,
            fontSize: 16,
            marginBottom: 0,
            lineHeight: 18
        },

        NavyDark: {
            fontFamily: 'GothamBook',
            color: ThemeColors.navyDark,
            fontSize: 16,
            marginBottom: 0,
            lineHeight: 18
        },

        Red: {
            fontFamily: 'GothamBook',
            color: ThemeColors.red,
            fontSize: 16,
            marginBottom: 0,
            lineHeight: 18
        }
    };
};
