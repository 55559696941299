import Colors from '../common/colors';

export default {
    transparent: 'transparent',
    primary:     '#2a6592',
    white:       '#FFFFFF',
    black:       '#000000',
    grey: '#7f868b',
    red: '#a61a2b',
    puce: '#6b0410',
    navy: '#153d6e',
    navyDark: '#162b4a',
    ...Colors
};
