import { dynamicMarginSize } from '../common';

export const getMargins = () => {
    return {
        gutterSmall: dynamicMarginSize(15),
        gutter: dynamicMarginSize(30),
        inset1: dynamicMarginSize(30 + 15),
        inset2: dynamicMarginSize(30 + 15 + 15),
        inset3: dynamicMarginSize(30 + 15 + 15 + 15),
        inset4: dynamicMarginSize(30 + 15 + 15 + 15 + 15)
    };
};
