import React, { useCallback }         from 'react';
import { View }                       from 'react-native';
import { useTheme, Button, Text }     from '@rneui/themed';
import { StyledWebView }              from '../common';
import { get }                        from 'lodash';
import { useDeviceInfo }              from '../../hooks';
import PropTypes                      from 'prop-types';

const STYLED_WEB_VIEW_ID = 'cornerBlockCta'; 

const CornerBlockCTA = props =>
{
	const {os}               = useDeviceInfo();
	const { theme }          = useTheme();
	const backgroundColor    = get(props, 'backgroundColor');
	const textColor          = get(props, 'textColor');
	const description        = get(props.json, 'cta1Description');
	const title              = get(props.json, 'title');
	const buttonText1        = get(props.json, 'cta1ButtonText');
	const externalLink1      = get(props.json, 'cta1Link.externalUrl');
	const internalLink1      = get(props.json, 'cta1Link.internalKey');
	const isExternal1        = get(props.json, 'cta1Link.isExternal');
	const buttonText2        = get(props.json, 'cta2ButtonText');
	const externalLink2      = get(props.json, 'cta2Link.externalUrl');
	const internalLink2      = get(props.json, 'cta2Link.internalKey');
	const isExternal2        = get(props.json, 'cta2Link.isExternal');
	const buttonText3        = get(props.json, 'cta3ButtonText');
	const externalLink3      = get(props.json, 'cta3Link.externalUrl');
	const internalLink3      = get(props.json, 'cta3Link.internalKey');
	const isExternal3        = get(props.json, 'cta3Link.isExternal');

	const nativeStyles={
		p:{
			fontFamily: 'GothamBook',
			fontSize: 18,
			lineHeight: 20,
			marginHorizontal: theme?.margins?.gutter,
			color: textColor
		}
	};
	const customWebStyles = `#${STYLED_WEB_VIEW_ID} p{
														margin:16pt ${theme?.margins?.gutter}pt !important;
														color:${textColor} !important;
														font-family:GothamBook !important;
														font-size:16pt !important;
														line-height:20pt !important;
														padding:0pt !important;
													} `;
	
	const renderCta = useCallback((buttonText, isExternal, internalKey, externalUrl) => {
        if (!buttonText) {
            return null;
        }

        return (
            <Button containerStyle={{ marginVertical: description.isEmpty() ? 10 : undefined, ...theme?.common?.Row}}
                    buttonStyle={{ borderColor: textColor, width: 200, marginVertical: 5 }}
					onPress={async () => await props.followContentLink(isExternal, internalKey, externalUrl)} 
                    title={buttonText}
                    titleStyle={{color: textColor, ...theme?.appComponents?.umbraco?.CornerBlockCTA?.buttonTitle}}
					type='outline'
            />
        );
	}, []);

	return (
		<View style={{
			      alignItems: 'center',
				  alignContent: 'space-between',
			      backgroundColor: backgroundColor, 
				  flex: 1
			   }} 
			   testID={`cornerBlockCTA`}
		>
			<View style={{ marginVertical: 25 }}>
				{
					!!title &&
						<View className={theme?.common?.Row }>
							<Text numberOfLines={1} h3={true} h3Style={{ ...theme?.text?.H3, color: textColor }}>
								{ title }
							</Text>
						</View>
				}
				{
					!!description && buttonText2.length === 0 && buttonText3.length === 0 &&
					<StyledWebView html= {description} 
								   customStyle={os.equals('web') ? customWebStyles : nativeStyles}
								   nodeId={STYLED_WEB_VIEW_ID}
								   testID='CornerBlockCTA.StyledWebView'
								   />
				}
			</View>
			<View style={{justifyContent: 'space-between'}}>
				{ renderCta(buttonText1, isExternal1, internalLink1, externalLink1) }

				{ renderCta(buttonText2, isExternal2, internalLink2, externalLink2) }

				{ renderCta(buttonText3, isExternal3, internalLink3, externalLink3) }
			</View>	
		</View>
	);
};

CornerBlockCTA.propTypes = {
	backgroundColor: PropTypes.string,
	json: PropTypes.shape({
			buttonText    : PropTypes.string,
			title         : PropTypes.string,
			description   : PropTypes.string,
			externalURL   : PropTypes.string,
			internalURL   : PropTypes.string,
			buttonText2   : PropTypes.string,
			externalURL2  : PropTypes.string,
			internalURL2   : PropTypes.string,
			buttonText3   : PropTypes.string,
			externalURL3  : PropTypes.string,
			internalURL3   : PropTypes.string
	}).isRequired,
	textColor: PropTypes.string
};

 export  default CornerBlockCTA;
