import React, { useMemo }                                from 'react';
import { ImageBackground, Pressable, View }              from 'react-native';
import { Image, Skeleton }                               from '@rneui/themed';
import { LinearGradient }                                from 'expo-linear-gradient';
import PropTypes                                         from 'prop-types';
import { useDeviceInfo }                                 from '../../hooks';

const ImageWithOptionalOverlay = props => 
{
    const { isMobile } = useDeviceInfo();

    const linearGradient = useMemo(() => 
        <LinearGradient colors={props.gradientColors} 
                        locations={props.gradientLocations}
                        style={{ 
                            bottom: 0,
                            left: 0,
                            height: '100%',
                            position: 'absolute',
                            right: 0,
                            top: 0,
                            width: '100%'
                        }}
        />
    , [ props.gradientColors, props.gradientLocations ]);

    return (
        <View style={{ flex:1, alignItems:'center' }}
              testID={props.testID}
        >
            <Image ImageComponent={ImageBackground}
                   Component={Pressable}
                   PlaceholderContent={
                        props.imagePath?.length > 0 && 
                            <Skeleton animation='pulse' 
                                      circle={false} 
                                      height={ props.imageHeight || isMobile ? 250 : 600 } 
                                      width='100%'
                            />
                    }
                    alt={props.alt}
                    childrenContainerStyle={props.childContainerStyle}
                    containerStyle={{ height: props.imageHeight? props.imageHeight:(isMobile ? 250 : 600),
                                                                 ...props.containerStyle,
                                                                 width:'100%',
                                                                }}
                    resizeMode={props.resizeMode}
                    onPress={props.onPress}
                    pressableProps={{
                        disabled: props.disableOnPress,
                        delayInPress: 300
                    }}
                    source={{ uri: `${props.imagePath}?height=${props.imageHeight ? props.imageHeight : (isMobile ? 250 : 600)}`}}
                    style={{
                         height: '100%', 
                         width: '100%',
                    }}
                    transition={true}
                    transitionDuration={420}
            >
                { props.enableOverlay && linearGradient }
                { props.children }
            </Image>       
        </View>
    );
};

ImageWithOptionalOverlay.defaultProps = {
    disableOnPress:    false,
    enableOverlay:     true,
    gradientColors:    ['rgba(23,30,37,0)', 'rgba(6,9,11,.6)'],
    gradientLocations: [0.10, 0.99],
    onPress:           null,
    testID:            'ImageWithOptionalOverlay'
};

ImageWithOptionalOverlay.propTypes = {
    props:PropTypes.shape({
    alt:                 PropTypes.string,
    childContainerStyle: PropTypes.object,
    containerStyle:      PropTypes.object,
    disableOnPress:      PropTypes.bool,
    enableOverlay:       PropTypes.bool,
    gradientColors:      PropTypes.arrayOf(PropTypes.string),
    gradientLocations:   PropTypes.arrayOf(PropTypes.number),
    imageHeight:         PropTypes.number,
    imagePath:           PropTypes.string.isRequired,
    onPress:             PropTypes.func,
    testID:              PropTypes.string
    })
};

export default ImageWithOptionalOverlay;
