import React                       from 'react';
import { Item as LinkPanelItem }   from './components/umbraco/LinkPanel';
import { Item as ListItem }        from './components/umbraco/ListItemContainer';

const ComponentMapping =
{
    reactAccordion:                      React.lazy(() => import('./components/umbraco/Accordion')),
    reactAppLinkContainer:               React.lazy(() => import('./components/umbraco/AppLinkContainer')),
    reactAvatarPlusText:                 React.lazy(() => import('./components/umbraco/AvatarPlusText')),
    reactAvatarPlusTextSlider:           React.lazy(() => import('./components/umbraco/AvatarPlusTextSlider')),
    reactCalendar:                       React.lazy(() => import('./components/umbraco/EventCalendar/EventCalendar')),
    reactCarousel:                       React.lazy(() => import('./components/umbraco/Carousel')),
    reactCornerBlock:                    React.lazy(() => import('./components/umbraco/CornerBlock')),
	reactCornerBlockCTA:                 React.lazy(() => import('./components/umbraco/CornerBlockCTA')),
	reactCornerBlockWYSIWYG:             React.lazy(() => import('./components/umbraco/CornerBlockWYSIWYG')),
    reactCta:                            React.lazy(() => import('./components/umbraco/Cta')),
    reactCtaContainer:                   React.lazy(() => import('./components/umbraco/CtaContainer')),
    reactEmailSubscriptionForm:          React.lazy(() => import('./components/umbraco/EmailSubscriptionForm')),
    reactEmbeddedScript:                 React.lazy(() => import('./components/umbraco/EmbeddedScript')),
    reactEvent:                          React.lazy(() => import('./components/umbraco/Event')),
    reactEventsGrid:                     React.lazy(() => import('./components/umbraco/EventsGrid')),
    reactEventsScreen:                   React.lazy(() => import('./components/umbraco/EventScreen/EventScreen')),
    reactFeaturedService:                React.lazy(() => import('./components/umbraco/FeaturedService')),
    reactFooter:                         React.lazy(() => import('./components/umbraco/SiteFooter')),
    reactFormStackForm:                  React.lazy(() => import('./components/umbraco/FormStackForm')),
    reactFullWidthImage:                 React.lazy(() => import('./components/umbraco/FullWidthImage')),
    reactGiving:                         React.lazy(() => import('./components/umbraco/Giving')),
    reactHowToWatchContainer:            React.lazy(() => import('./components/umbraco/HowToWatch')),
    reactIconPlusHeading:                React.lazy(() => import('./components/umbraco/IconPlusHeading')),
    reactImageWithDescription:           React.lazy(() => import('./components/umbraco/ImageWithDescription')),
    reactJob:                            React.lazy(() => import('./components/umbraco/Job')),
    reactJobsContainer:                  React.lazy(() => import('./components/umbraco/JobsContainer')),
    reactLinkPanel:                      React.lazy(() => import('./components/umbraco/LinkPanel')),
	reactLinkPanelItem:                  LinkPanelItem,
    reactListItem:                       ListItem,
    reactListItemContainer:              React.lazy(() => import('./components/umbraco/ListItemContainer')),
    reactMinistry:                       React.lazy(() => import('./components/umbraco/Ministry')),
    reactMinistryGrid:                   React.lazy(() => import('./components/umbraco/MinistryGrid')),
    reactNameAcquisition:                React.lazy(() => import('./components/umbraco/NameAcquisition')),
    reactNewsArticle:                    React.lazy(() => import('./components/umbraco/NewsArticle')),
    reactNewsArticleCard:                React.lazy(() => import('./components/umbraco/NewsArticleCard')),
    reactNewsArticlesGrid:               React.lazy(() => import('./components/umbraco/NewsArticleGrid')),
    reactNewsArticleRelated:             React.lazy(() => import('./components/umbraco/NewsArticleRelated')),
    reactNextLiveService:                React.lazy(() => import('./components/umbraco/LiveService/LiveService')),
    reactPhoto:                          React.lazy(() => import('./components/umbraco/Photo')),
    reactPhotoGallery:                   React.lazy(() => import('./components/umbraco/PhotoGallery')),
    reactPhotoGrid:                      React.lazy(() => import('./components/umbraco/PhotoGrid')),
    reactService:                        React.lazy(() => import('./components/umbraco/Service')),
    reactServiceContainer:               React.lazy(() => import('./components/umbraco/ServiceContainer')),
	reactSocialLinks:                    React.lazy(() => import('./components/umbraco/SocialLinks')),
    reactSocialLinksContainerWithCTA:    React.lazy(() => import('./components/umbraco/SocialLinksContainerWithCTA')),
    reactSocialPostContainer:            React.lazy(() => import('./components/umbraco/SocialPostContainer')),
    reactSpacer:                         React.lazy(() => import('./components/umbraco/Spacer')),
    reactTabbedAlternatingContainer:     React.lazy(() => import('./components/umbraco/TabbedAlternatingContainer')),
    reactTabbedContainer:                React.lazy(() => import('./components/umbraco/TabbedContainer')),
    reactTextPlusImage:                  React.lazy(() => import('./components/umbraco/TextPlusImage')),
    reactTextPlusImageWithLink:          React.lazy(() => import('./components/umbraco/TextPlusImageWithLink')),
    reactTwoColumnWYSIWYG:               React.lazy(() => import('./components/umbraco/TwoColumnWYSIWYG')),
    reactVideoPlayer:                    React.lazy(() => import('./components/umbraco/VideoPlayer')),
    reactWYSIWYG:                        React.lazy(() => import('./components/umbraco/WYSIWYG'))
};

export default ComponentMapping;
