import React, {useState, useContext } from 'react';
import { useTheme, Text, Image } from '@rneui/themed';
import { View, Pressable } from 'react-native';
import NavSearchIcon from './NavSearchIcon';
import { isEmpty, get } from 'lodash';
import { useApi } from '../../../hooks';
import AppContext, { AppActions } from '../../../contexts/app';
import { findActive } from '../../../common/helpers/navigation';

const TopNavLinkText = ({title, onPress, isActive}) => {
    const { theme } = useTheme();
    const [hover, setHover] = useState();
    // const [isOpen, setIsOpen] = useState(false);

    return (
        <View  style={{
                        margin:4,
                        alignItems:'center',
                        zIndex: 999999
                     }}
                testID='TopNavLinkText.Web'>
                <Pressable 
                        onPress={onPress}
                        testID='TopNavLinkText.Text.Web'
                        onHoverIn={() => setHover(title)}
                        onHoverOut={()=> setHover(null)}
                        >
                        <Text style={[title === hover ? theme?.appComponents?.umbraco?.TopNavLinkText?.hovered
                                                      : theme?.appComponents?.umbraco?.TopNavLinkText?.linkText,
                                                       isActive && theme?.appComponents?.umbraco?.TopNavLinkText?.activeLink
                                     ]}
                                     >
                            {title}
                        </Text>
                </Pressable>


                 {/*
                //         This is a basic setup for the submenu for each link if needed
                //         <Icon
                //         name={isOpen? 'caret-up': 'caret-down'}
                //         type='font-awesome'
                           color={theme?.appComponents?.umbraco?.TopNavLinkText?.subMenuIconColor}
                //         size={16}
                //         onPress={()=> setIsOpen(!isOpen)}
                //         containerStyle={{ marginLeft:4,padding:2}}/>

                //     {isOpen && 
                //         <View style={[theme?.appComponents?.umbraco?.TopNavLinkText?.subMenu, {height:testItems.length*25}]}>
                //             {testItems.map(subItem => (
                //             <Text key={subItem} style={theme?.appComponents?.umbraco?.TopNavLinkText?.subMenuText}>{subItem}</Text>
                //             ))}
                //         </View>
                //     } 
                */}
        </View>

    );
};

const TopNavigation = props => {

    const { fetchPage }         = useApi();
    const { state, dispatch }   = useContext(AppContext);
    const logoUrl               = get(props?.siteWrapper?.contentItems['Primary Navigation'],'logo.cdnImagePath')

    if (!isEmpty(state.navigation?.primary)) {
        const active = findActive(
            state.action?.href || window.location.pathname
        );

   

        return (
            <View style={{
                            width: '100%',
                            height: 80,
                            justifyContent: 'center',
                            flexDirection: 'row',
                            alignItems: 'center',
                            borderWidth: 1,
                            borderColor: '#e7e7e7',
                        }}
                   testId='TopNavigation.Web'>
                <View style={{ flex:0.5 }}>
                    <Image source={logoUrl?.length > 0 ? {uri:`https:${logoUrl}`}: undefined}
                        alt={'header logo'} 
                        style={{height:50,width:50,resizeMode:'contain',marginLeft:15}}
                            /> 
                </View>
                <View style={{flex:0.5, justifyContent:'center'}}>
                    <View style={{flexDirection:'row', justifyContent:'flex-end', alignItems:'center'}}>
                        {state?.navigation?.primary.map(linkItem => {
                            if (linkItem.doNotIncludeInNavigation) {
                                return null;
                            }
                            const id          = get(linkItem, 'id')
                            const title       = get(linkItem, 'title');
                            const rootPath    = get(linkItem, 'rootPath');
                            const isActive    = rootPath.equals(active) || rootPath.length > 1 && rootPath.replaceAll('/', '').toLowerCase().equals(active);

                            const onPress = async (e) => {
                                e.preventDefault();
                                if (!linkItem.navigationOnly){
                                    await fetchPage(dispatch, linkItem.rootPath);
                                }
                                else{
                                    dispatch({
                                        href: linkItem.rootPath,
                                        data: { ...linkItem },
                                        type: AppActions.NAVIGATE
                                    });
                                }
                            };
                            
                            return <TopNavLinkText key={id}
                                                onPress={onPress}
                                                isActive={isActive}
                                                title={title}
                                                    {...props}
                                                    />
                        })}
                    </View>
                </View>
                <View style={{marginHorizontal:10}}>
                    {/* Will need to add the search bar and results here see ancillary  */}
                    <NavSearchIcon />
                </View>
            </View>
        );
    }
};
export default TopNavigation;
