import Colors from '../common/colors';

export default {
    ...Colors,
    grey: '#7f868b',
    grey2: '#8897a2',
    grey3: '#dde4e9',
    grey4: '#f7f8f9',
    golden: '#c1a638',
    golden2: '#a38e3a',
    red: '#a61a2b',
    red2: '#ed1c24',
    red3: '#fa0000',
    red4: '#fe0000',
    puce: '#6b0410',
    navy: '#153d6e',
    aliceLightBlue: '#eaf6ff',
    lightNavy: '#276abc',
    navyDark: '#162b4a', // end of cornerstone
    base: '#25274d',
    primary: '#162b4a',
    secondary: '#153d6e',
    tertiary: '#2e9cca'
};
