import { StyleSheet }    from 'react-native';
import Common       from '../common';
import ThemeColors       from './colors';
import { getText }       from './text';

export default () => {
    return {
        colors: ThemeColors,
        common: Common,
        iconColor: '#153d6e',
        iconKit: 'font-awesome-5',
        text: getText(),
        MenuBar: {
            containerStyle: {
                backgroundColor: ThemeColors.white,
                borderBottomColor: ThemeColors.navyDark,
                borderBottomWidth: StyleSheet.hairlineWidth
            },
            leftComponent: {
                backgroundColor: ThemeColors.primary,
                text: {
                    color: ThemeColors.white,
                    fontSize: 18
                }
            },
            logo: {
                color: ThemeColors.navy,
                source: 'cross',
                type: 'icon'
            },
        }
    };
};
