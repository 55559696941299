import React, { useContext , useCallback}                 from 'react';
import AppContext, { AppActions, AppEvents }              from '../../../contexts/app';
import { useTheme, Icon }                                 from '@rneui/themed';

const NavSearchIcon = () => {
    const { state, dispatch }   = useContext(AppContext);
    const { theme }             = useTheme();
   
   
    const onSearchPress = useCallback(() => {
        let searchState = AppEvents.SEARCH_DIALOGUE_STATE_CLOSED;

        if (state?.search?.state?.equals(AppEvents.SEARCH_DIALOGUE_STATE_CLOSED))
        {
            searchState = AppEvents.SEARCH_DIALOGUE_STATE_OPEN;
        }

        dispatch({
            state: searchState,
            type: AppActions.SEARCH_STATE_CHANGE
        });
    }, [state?.search?.state]);

    if (!state?.search?.state) {
        return null;
    }

    const isOpen = state.search.state === AppEvents.SEARCH_DIALOGUE_STATE_OPEN;

    return (
        <Icon
            color={theme?.appComponents?.umbraco?.NavSearchIcon}
            containerStyle={theme?.appComponents?.umbraco?.NavSearchIconContainer}
            name={isOpen ? 'times' : 'search'}
            onPress={onSearchPress}
            size={16}
            style={{ paddingHorizontal: 10 }}
            type={theme?.iconKit || 'font-awesome-5'}
            testID='NavSearchIcon.Web'
        />
    );
};
export default NavSearchIcon;
