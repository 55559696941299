import React, { useCallback, useContext, useEffect, useRef, Suspense } from 'react';
import { FlatList, SafeAreaView } from 'react-native';
import Animated, { FadeIn, FadeOut } from 'react-native-reanimated';
import { useTheme } from '@rneui/themed';
import * as ExpoFileLogger from 'expo-file-logger';
import * as SplashScreen from 'expo-splash-screen';
import { get, values } from 'lodash';
import PropTypes from 'prop-types';
import { MenuBar } from '../components/navigation/mobile';
import { Loading, LoggedComponent, SearchResults, UnsupportedPage } from '../components/common';
import SiteFooter from '../components/umbraco/SiteFooter';
import AppContext, { AppEvents, AppStatus } from '../contexts/app';
import { useDeviceInfo } from '../hooks';
import NativeSiteWrapper from '../components/umbraco/HomeScreen/NativeSiteWrapper';

const MobileView = props => {
    const { state } = useContext(AppContext);
    const { height, width, isNative } = useDeviceInfo();
    const flatListRef = useRef();
    const { theme } = useTheme();
    const contentItems = get(state.action, 'data.page.contentItems');
    const footer = get(props.siteWrapper?.contentItems, 'Footer');
    const isMenuOpen = state.hamburger?.state?.equals(AppEvents.HAMBURGER_STATE_OPEN);

    const scrollToIndex = useCallback(() => {
        flatListRef?.current?.measureInWindow((_, y) => {
            flatListRef?.current?.scrollTo({
                animated: true,
                x: 0,
                y: y
            });
        });
    }, [flatListRef]);

    const renderComponent = useCallback(
        ({ item, index }) => {
            const documentType = item.nodeTypeAlias;

            try {
                const mappedType = props.componentMapping[documentType];

                if (!item.isSiteFooter && mappedType) {
                    const UmbracoComponent = mappedType;

                    return (
                        <Animated.View entering={FadeIn} exiting={FadeOut} key={item.id} style={{ marginBottom: 20 }}>
                            <LoggedComponent name={documentType}>
                                <UmbracoComponent
                                    {...props}
                                    index={index}
                                    json={item}
                                    references={state.action?.data?.references}
                                    scrollTo={scrollToIndex}
                                    testID={documentType}
                                />
                            </LoggedComponent>
                        </Animated.View>
                    );
                } else if (item.isSiteFooter) {
                    return (
                        <SiteFooter
                            followContentLink={props.followContentLink}
                            footerRefs={props.siteWrapper.refs}
                            isSiteFooter={true}
                            key={footer.id}
                            {...footer}
                            siteWrapper={props.siteWrapper}
                            domain={state?.settings?.websiteSettings?.domain}
                            modernLayout={props.siteWrapper.modernLayout}
                        />
                    );
                }
            } catch (e) {
                ExpoFileLogger.log(
                    'error',
                    `Error rendering View: [${state?.action?.data?.page.title}] Component [${documentType}] - ${e.message}`
                );
            }
        },
        [footer, props, scrollToIndex, state.action?.data?.page.title, state.action?.data?.references, state?.settings?.websiteSettings?.domain]
    );

    const renderContent = () => {
        if (state.search?.term && state.search?.event?.equals(AppEvents.SEARCH_QUERY_SUBMITTED)) {
            ExpoFileLogger.log('debug', 'Rendering SearchResults');

            return <SearchResults {...props} />;
        } else if (!isMenuOpen && contentItems) {
            ExpoFileLogger.log('debug', `Rendering View [${state?.action?.data?.page.title}]`);

            if (state.action?.data?.page?.developmentOnly && state.settings?.websiteSettings?.environment?.equals('Production')) {
                return <UnsupportedPage {...props} />;
            } else {
                const children = values(state.action?.data?.page?.contentItems);

                if (footer) {
                    if (isNative) {
                        if (state?.action?.href && !state?.action?.href?.equals('/app')) {
                            children.push({ isSiteFooter: true });
                        }
                    } else {
                        children.push({ isSiteFooter: true });
                    }
                }

                return <FlatList data={children} initialNumToRender={5} ref={flatListRef} renderItem={renderComponent} />;
            }
        }
    };

    useEffect(() => {
        flatListRef?.current?.scrollToOffset({ animated: true, offset: 0 });
    }, [contentItems]);

    useEffect(() => {
        ExpoFileLogger.log('debug', 'Hiding splash screen');

        (async () => {
            await SplashScreen.hideAsync();
        })();
    }, []);

    if (isNative) {
        const topNav = get(props.siteWrapper.contentItems, 'Native Top Navigation');
        const bottomNav = get(props.siteWrapper.contentItems, 'Native Bottom Navigation');

        return (
            <Suspense fallback={<Loading />}>
                <SafeAreaView onLayout={props.onLayout} style={{ height, width }}>
                    <NativeSiteWrapper topNav={topNav} bottomNav={bottomNav} {...props}>
                        {renderContent()}
                    </NativeSiteWrapper>
                    {state.action.state.status.equals(AppStatus.LOADING) && <Loading color={theme?.colors?.red2} />}
                </SafeAreaView>
            </Suspense>
        );
    } else {
        return (
            <Suspense fallback={<Loading />}>
                <SafeAreaView onLayout={props.onLayout} style={{ height, width }}>
                    <MenuBar isOpen={isMenuOpen} />
                    {renderContent()}
                    {state.action.state.status.equals(AppStatus.LOADING) && <Loading color={theme?.colors?.red2} />}
                </SafeAreaView>
            </Suspense>
        );
    }
};

MobileView.propTypes = {
    componentMapping: PropTypes.object.isRequired,
    followContentLink: PropTypes.func.isRequired,
    onLayout: PropTypes.func
};

export default React.memo(MobileView);
