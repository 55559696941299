import React, { useReducer } from 'react';
import { Platform } from 'react-native';
import * as ExpoFileLogger from 'expo-file-logger';
import * as moment from 'moment';
import Constants from '../common/constants';
import Utils from '../common/utils';

const AppActions = Object.freeze({
    ANCILLARY_STATE_CHANGE: 'ANCILLARY_STATE_CHANGE',
    ANCILLARY_MOBILE_MENU_INDEX_CHANGE: 'ANCILLARY_MOBILE_MENU_INDEX_CHANGE',
    API_STATE_CHANGE: 'API_STATE_CHANGE',
    API_REQUEST: 'API_REQUEST',
    CALENDAR_STATE_CHANGE: 'CALENDAR_STATE_CHANGE',
    HAMBURGER_STATE_CHANGE: 'HAMBURGER_STATE_CHANGE',
    MOBILE_STATE_CHANGE: 'MOBILE_STATE_CHANGE',
    NAVIGATE: 'NAVIGATE',
    NAVIGATE_TO_PARENT: 'NAVIGATE_TO_PARENT',
    SEARCH_STATE_CHANGE: 'SEARCH_STATE_CHANGE',
    WATCH_FEATURED_SERVICE: 'WATCH_FEATURED_SERVICE',
    WATCH_LIVE_SERVICE: 'WATCH_LIVE_SERVICE'
});

const AppEvents = Object.freeze({
    ANCILLARY_MOBILE_MENU_CLOSED: 'ANCILLARY_MOBILE_MENU_CLOSED',
    ANCILLARY_MOBILE_MENU_OPEN: 'ANCILLARY_MOBILE_MENU_OPEN',
    API_FETCH_PAGE: 'API_FETCH_TYPE_PAGE',
    API_RESULT: 'API_RESULT',
    API_STATUS_CHANGED: 'API_STATUS_CHANGED',
    CALENDAR_DATEPICKER_STATE_CHANGE: 'CALENDAR_DATEPICKER_STATE_CHANGE',
    CALENDAR_FILTER_STATE_CHANGE: 'CALENDAR_FILTER_STATE_CHANGE',
    CALENDER_DROPDOWN_STATE_OPEN: 'CALENDER_DROPDOWN_STATE_OPEN',
    CALENDER_DROPDOWN_STATE_CLOSED: 'CALENDER_DROPDOWN_STATE_CLOSED',
    CALENDAR_SELECT_DATE: 'CALENDAR_SELECT_DATE',
    HAMBURGER_STATE_CHANGE: 'HAMBURGER_STATE_CHANGE',
    HAMBURGER_STATE_OPEN: 'HAMBURGER_STATE_OPEN',
    HAMBURGER_STATE_CLOSED: 'HAMBURGER_STATE_CLOSED',
    IS_MOBILE: 'IS_MOBILE_STATE_CHANGE',
    NAVIGATE: 'NAVIGATE',
    NAVIGATE_TO_PARENT: 'NAVIGATE_TO_PARENT',
    SEARCH_DIALOGUE_STATE_CHANGE: 'SEARCH_DIALOGUE_STATE_CHANGE',
    SEARCH_DIALOGUE_STATE_OPEN: 'SEARCH_DIALOGUE_STATE_OPEN',
    SEARCH_DIALOGUE_STATE_CLOSED: 'SEARCH_DIALOGUE_STATE_CLOSED',
    SEARCH_QUERY_CLOSED: 'SEARCH_QUERY_CLOSED',
    SEARCH_QUERY_SUBMITTED: 'SEARCH_QUERY_SUBMITTED'
});

const AppStatus = Object.freeze({
    LOADING: 'API_STATUS_LOADING',
    FAILED: 'API_STATUS_FAILED',
    READY: 'API_STATUS_READY'
});

const AppReducer = (previousState, action) => {
    const { data, href, index, type } = action;

    ExpoFileLogger.log('debug', `Received dispatch action: type=[${type}], href=[${href}]`);

    if (type.equals(AppActions.ANCILLARY_STATE_CHANGE)) {
        if (action.event.equals(AppEvents.ANCILLARY_MOBILE_MENU_INDEX_CHANGE)) {
            const ancillary = {
                index: action.index,
                state: action.event || AppEvents.ANCILLARY_MOBILE_MENU_INDEX_CHANGE
            };
            let event = AppEvents.ANCILLARY_MOBILE_MENU_CLOSE;

            if (index && index > -1) {
                event = AppEvents.ANCILLARY_MOBILE_MENU_OPEN;
            }

            ancillary.event = event;

            return {
                ...previousState,
                ...ancillary
            };
        }
    } else if (type.equals(AppActions.API_REQUEST)) {
        if (Platform.OS.equals('web')) {
            Utils.setCursor('pointer');
        }

        if (!action.status.equals(AppStatus.FAILED)) {
            previousState.action.previousHref = previousState.action.href;
            previousState.action.href = href;
            previousState.action.state.status = AppStatus.READY;

            if (data) {
                previousState.action.data = data;
            }
        }

        return {
            ...previousState
        };
    } else if (type.equals(AppActions.API_STATE_CHANGE)) {
        if (action.status.equals(AppStatus.LOADING)) {
            if (Platform.OS.equals('web')) {
                Utils.setCursor('wait');
            }

            previousState.action.state.status = AppStatus.LOADING;
        } else {
            previousState.action.state.status = AppStatus.READY;
        }

        return {
            ...previousState
        };
    } else if (type.equals(AppActions.CALENDAR_STATE_CHANGE)) {
        if (previousState) {
            if (!previousState.datePicker) {
                previousState.datePicker = {
                    state: AppEvents.CALENDER_DROPDOWN_STATE_CLOSED
                };
            }

            if (!previousState.filter) {
                previousState.filter = {
                    state: AppEvents.CALENDER_DROPDOWN_STATE_CLOSED
                };
            }

            if (!previousState.calendarSelectedDate) {
                previousState.calendarSelectedDate = {
                    date: moment().startOf('day').toDate()
                };
            }
        }

        if (action.event.equals(AppEvents.CALENDAR_DATEPICKER_STATE_CHANGE)) {
            previousState.datePicker.state = action.state;
        } else if (action.event.equals(AppEvents.CALENDAR_FILTER_STATE_CHANGE)) {
            previousState.filter.state = action.state;
        } else if (action.event.equals(AppEvents.CALENDAR_SELECT_DATE)) {
            previousState.calendarSelectedDate.state = action.state;
        }

        return {
            ...previousState
        };
    } else if (type.equals(AppActions.HAMBURGER_STATE_CHANGE)) {
        return {
            ...previousState,
            hamburger: {
                state: action.state
            }
        };
    } else if (type.equals(AppActions.NAVIGATE)) {
        previousState.action = {
            ...previousState.action,
            data: {
                page: data.page || data,
                references: data.references || previousState.action.data.references
            },
            href: href,
            state: {
                status: 'API_STATUS_READY'
            }
        };

        return { ...previousState };
    } else if (type.equals(AppActions.NAVIGATE_TO_PARENT)) {
        previousState.action.href = href;

        if (global?.cache) {
            const cached = global.cache.get(`${Constants.REACT_APP_PAGE_BASE}${previousState.action.href}`);

            if (cached?.page || cached?.data) {
                previousState.action.data = cached.page || cached.data;
            }

            window.href = previousState.action.href;
        } else {
            window.href = previousState.action.href;
        }

        return {
            ...previousState
        };
    } else if (type.equals(AppActions.SEARCH_STATE_CHANGE)) {
        if (action.state === undefined) {
            action.state = AppEvents.SEARCH_DIALOGUE_STATE_CLOSED;
        }

        let term;

        if (action.event) {
            if (action.event.equals(AppEvents.SEARCH_QUERY_SUBMITTED)) {
                term = data;
                action.state = AppEvents.SEARCH_DIALOGUE_STATE_CLOSED;
            } else if (action.event.equals(AppEvents.SEARCH_QUERY_CLOSED)) {
                term = undefined;
            }
        }

        return {
            ...previousState,
            search: {
                state: action.state,
                event: action.event,
                term: term
            }
        };
    } else if (type.equals(AppActions.WATCH_FEATURED_SERVICE)) {
        previousState.action.href = `${Constants.REACT_APP_INTERNAL_REDIRECT_FEATURED}?source=${data.service.key}`;
        previousState.action.data = {
            page: data.page,
            service: data.service && !data.service.properties ? (data.service.properties = data.service) : data.service
        };

        return {
            ...previousState
        };
    } else if (type.equals(AppActions.WATCH_LIVE_SERVICE)) {
        previousState.action.href = '/watch';
        previousState.action.data = {
            page: data.page
        };

        return {
            ...previousState
        };
    }

    return {
        ...previousState
    };
};

const initialState = {
    ancillary: {
        index: -1,
        state: AppEvents.ANCILLARY_MOBILE_MENU_CLOSED
    },
    action: {
        data: null,
        event: null,
        href: '/',
        previousHref: '/',
        state: {
            status: 'API_STATUS_READY'
        },
        type: null
    },
    calendar: null,
    countries: [],
    hamburger: {
        state: AppEvents.HAMBURGER_STATE_CLOSED
    },
    navigation: null,
    search: {
        state: AppEvents.SEARCH_DIALOGUE_STATE_CLOSED
    }
};

const AppContext = React.createContext();

const AppContextProvider = props => {
    const [state, dispatch] = useReducer(AppReducer, initialState);

    if (props && props.globals) {
        state.countries = props.globals.countries;
        state.domain = props.globals.domain;
        state.redirects = props.globals.redirects;
        state.settings = props.globals.settings;
        state.siteWrapper = props.globals.siteWrapper;
        state.subscriptionSettings = props.globals.subscriptionSettings;
        state.templates = props.globals.templates;
        state.vanityUrls = props.globals.vanityUrls;

        if (props.globals.navigation && !state.navigation) {
            state.navigation = props.globals.navigation;
        }

        if (props.globals.activePage && !state.action.data) {
            state.action.data = props.globals.activePage;
            state.action.href = props.globals.activePage.rootPath;
        }
    }

    return <AppContext.Provider value={{ state, dispatch }}>{props.children}</AppContext.Provider>;
};

export { AppActions, AppContextProvider, AppEvents, AppStatus };

export default AppContext;
