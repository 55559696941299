import { StyleSheet } from 'react-native';
import ThemeColors from './colors';
import { dynamicFontSize, dynamicFontFamily } from '../common';
import { getText } from './text';

export const getNavigation = () => {
    const ThemeText = getText();

    return {
        mobile: {
            WhereIndicator: {
                button: {
                    color: ThemeColors.textSecondaryColor
                },
                buttonStyle: {
                    borderColor: ThemeColors?.textSecondaryColor,
                    borderRadius: 2
                },
                buttonContainerStyle: {
                    marginLeft: 5,
                    shadowRadius: 5,
                    shadowColor: 'black',
                    shadowOffset: { width: 3, height: 4 },
                    shadowOpacity: 0.25
                },
                container: {
                    alignItems: 'center',
                    backgroundColor: ThemeColors.textBlackColor,
                    flexDirection: 'row',
                    flexGrow: 3,
                    height: 50,
                    justifyContent: 'space-between'
                },
                searchIcon: {
                    color: ThemeColors?.textSecondaryColor,
                    size: 28,
                    type: 'material-community'
                },
                text: {
                    ...ThemeText?.Paragraph,
                    marginTop: 0,
                    marginHorizontal: 0,
                    fontFamily: 'GothamBook',
                    color: ThemeColors.textSecondaryColor,
                    justifyContent: 'center',
                    marginVertical: 10
                },
                titleStyle: {
                    ...ThemeText?.Paragraph,
                    marginVertical: 0,
                    marginHorizontal: 0,
                    fontFamily: 'GothamBook',
                    color: ThemeColors?.textSecondaryColor
                }
            },
            MenuBar: {
                containerStyle: {
                    backgroundColor: ThemeColors.baseColor,
                    borderBottomColor: ThemeColors.textPlaceholderColor,
                    borderBottomWidth: StyleSheet.hairlineWidth,
                    height: 50,
                    flexDirection: 'row',
                    justifyContent: 'space-between'
                },
                hamburgerIcon: {
                    color: ThemeColors?.primaryColor,
                    size: 30,
                    type: 'material-community'
                },
                leftComponent: {
                    alignItems: 'center',
                    backgroundColor: 'transparent',
                    height: 50,
                    justifyContent: 'flex-start'
                },
                logo: {
                    justifyContent: 'center',
                    source: require('../../../assets/images/logo.png'),
                    type: 'image'
                },
                loginIconColor: ThemeColors?.primaryColor,
                BottomSheet: {
                    container: {
                        backgroundColor: ThemeColors?.baseColor,
                        borderBottomColor: ThemeColors?.textPlaceholderColor,
                        borderBottomWidth: 0.75
                    },
                    titleStyle: {
                        color: ThemeColors?.textPrimaryColor
                    },
                    cancelBtnContainer: {
                        backgroundColor: ThemeColors?.primaryColor
                    },
                    cancelBtnTitle: {
                        color: ThemeColors?.textSecondaryColor
                    }
                },
                rightComponent: {
                    alignContent: 'center',
                    backgroundColor: ThemeColors.transparent,
                    flexDirection: 'row',
                    height: 50,
                    justifyContent: 'center',
                    text: {
                        color: ThemeColors.primaryColor,
                        fontFamily: 'GothamBook',
                        fontSize: 14,
                        marginRight: 10
                    }
                }
            },
            NavigationList: {
                ancillaryOptionIcon: {
                    color: ThemeColors?.textSecondaryColor,
                    size: 25,
                    name: 'info',
                    type: 'font-awesome-5'
                },
                ancillaryText: {
                    alignContent: 'center',
                    fontFamily: dynamicFontFamily('GothamMedium'),
                    fontSize: dynamicFontSize(24),
                    margin: 5,
                    color: ThemeColors?.textSecondaryColor
                },
                breadcrumbContainer: {
                    alignItems: 'center',
                    backgroundColor: 'black',
                    flexDirection: 'row',
                    height: 50
                },
                chevron: {
                    marginRight: 10,
                    borderColor: ThemeColors.primary,
                    color: ThemeColors?.primary
                },
                chevronColor: ThemeColors?.textPrimaryColor,
                container: {
                    alignContent: 'center',
                    backgroundColor: ThemeColors?.primaryColor,
                    flexDirection: 'row',
                    justifyContent: 'space-around',
                    height: 75,
                    width: '100%'
                },
                text: {
                    color: ThemeColors.textPrimaryColor,
                    alignContent: 'center',
                    fontFamily: dynamicFontFamily('GothamMedium'),
                    fontSize: dynamicFontSize(24)
                }
            }
        },
        web: {
            Ancillary: {
                searchIconStyle: {
                    borderLeftColor: ThemeColors?.grey3,
                    borderLeftWidth: StyleSheet.hairlineWidth,
                    paddingLeft: 5
                },
                liveButton: {
                    backgroundColor: ThemeColors?.red,
                    marginLeft: 2,
                    paddingHorizontal: 2,
                    paddingVertical: 2,
                    position: 'relative'
                },
                link: {
                    title: {
                        ...ThemeText.Navy,
                        fontFamily: 'GothamMedium'
                    }
                },
                dropdown: {
                    placeholderStyle: {
                        ...ThemeText.Navy,
                        fontFamily: 'GothamMedium',
                        marginRight: 10
                    },
                    container: {
                        borderRadius: 0,
                        borderWidth: 0,
                        flexDirection: 'row',
                        justifyContent: 'center',
                        marginRight: 10
                    }
                },
                headerContainer: {
                    backgroundColor: 'transparent',
                    borderBottomColor: ThemeColors?.grey3,
                    borderBottomWidth: StyleSheet.hairlineWidth,
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    width: '100%'
                },
                leftContainer: {
                    flexDirection: 'row',
                    justifyContent: 'space-evenly'
                },
                rightContainer: {
                    alignItems: 'center',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    marginRight: 10
                }
            },
            Primary: {
                container: {
                    backgroundColor: 'transparent',
                    justifyContent: 'flex-end',
                    overflow: 'hidden'
                },
                listItemTitle: {
                    fontFamily: 'GothamBook',
                    color: ThemeColors.textPrimaryColor,
                    fontSize: 16,
                    fontWeight: 600,
                    lineHeight: 18,
                    marginBottom: 0,
                    marginRight: 10
                },
                listItemIcon: {
                    color: ThemeColors.black
                },
                logo: {
                    height: 200,
                    width: 200
                }
            },
            Secondary: {}
        }
    };
};
