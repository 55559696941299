import React, { createContext, useReducer } from 'react';

const Actions = Object.freeze({
    BACK: -1,
    NEXT: 1,
    COMPLETE: 2,
    SKIP: 3,
    SET_EMAIL_CONFIRM_ID: 4,
    SET_MOBILE_CONFIRM_ID: 5
});

const initialState = {
    activeStep: 1,
    allStepsComplete: false,
    emailConfirmationId: undefined,
    mobileConfirmationId: undefined
};

const RegistrationContext = createContext();

const RegistrationReducer = (state, action) => {
    if (Actions.BACK === action.type) {
        if (state.activeStep !== 1) {
            return {
                ...state,
                activeStep: state.activeStep - 1
            };
        }
    } else if (Actions.NEXT === action.type) {
        return {
            ...state,
            activeStep: state.activeStep + 1,
            allStepsComplete: false
        };
    } else if (Actions.COMPLETE === action.type) {
        return {
            ...state,
            allStepsComplete: true
        };
    } else if (Actions.SKIP === action.type) {
        return {
            ...state,
            activeStep: state.activeStep + 2,
            allStepsComplete: false
        };
    } else if (Actions.SET_EMAIL_CONFIRM_ID === action.type) {
        return {
            ...state,
            activeStep: !action.resendCode ? state.activeStep + 1 : state.activeStep,
            emailConfirmationId: action.emailConfirmationCodeId
        };
    } else if (Actions.SET_MOBILE_CONFIRM_ID === action.type) {
        return {
            ...state,
            activeStep: state.activeStep + 1,
            mobileConfirmationId: action.mobileConfirmationId
        };
    }

    return state;
};

const RegistrationContextProvider = props => {
    const [state, dispatch] = useReducer(RegistrationReducer, initialState);

    return <RegistrationContext.Provider value={{ state, dispatch }}>{props.children}</RegistrationContext.Provider>;
};

export { Actions, RegistrationContextProvider };

export default RegistrationContext;
