import React, { useContext, useRef } from 'react';
import Constants from 'expo-constants';
import { default as RecaptchaThatWorks } from 'react-recaptcha-that-works';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import AppContext from '../../../contexts/app';

const Recaptcha = props => {
    const { state } = useContext(AppContext);
    const ref = useRef();
    const onVerify = get(props, 'onVerify');

    const onError = e => console.log('reCaptcha error encounterd', e);

    return (
        <RecaptchaThatWorks
            baseUrl={Constants.manifest.extra?.baseUrl}
            onError={onError}
            onVerify={onVerify}
            ref={props.innerRef || ref}
            siteKey={state.settings?.googleSettings?.reCaptchaSiteKeyV3}
            size='invisible'
        />
    );
};

Recaptcha.propTypes = {
    innerRef: PropTypes.object,
    onVerify: PropTypes.func.isRequired
};

export default Recaptcha;
