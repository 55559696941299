import React from 'react';
import { Dimensions, View } from 'react-native';
import { Picker } from '@react-native-picker/picker';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { useDeviceInfo } from '../../hooks';

const { width } = Dimensions.get('window');

const SelectField = React.forwardRef((props, ref) => {
    const { isMobile } = useDeviceInfo();
    const containerStyle = get(props, 'containerStyle');
    const itemStyle = get(props, 'itemStyle');
    const onBlur = get(props, 'onBlur');
    const onValueChange = get(props, 'onValueChange');
    const options = get(props, 'options');
    const style = get(props, 'style');
    const value = get(props, 'value');

    return (
        <View style={containerStyle}>
            <Picker
                itemStyle={itemStyle}
                mode={isMobile ? 'dialog' : 'dropdown'}
                onBlur={onBlur}
                onValueChange={onValueChange}
                ref={ref}
                selectedValue={value}
                style={style}
            >
                {options.map(option => (
                    <Picker.Item
                        value={option.value}
                        label={option.label}
                        key={option.label}
                        style={style}
                        testID={`SelectField.Item.${option.label}`}
                    />
                ))}
            </Picker>
        </View>
    );
});

SelectField.defaultProps = {
    itemStyle: {
        color: '#000',
        fontFamily: 'GothamMedium',
        fontSize: width <= 480 ? 12 : '12pt',
        fontWeight: '900'
    }
};

SelectField.propTypes = {
    containerStyle: PropTypes.object,
    itemStyle: PropTypes.object,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    onValueChange: PropTypes.func.isRequired,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            value: PropTypes.any.isRequired
        })
    ).isRequired,
    prompt: PropTypes.string,
    style: PropTypes.object,
    value: PropTypes.any
};

export default SelectField;
