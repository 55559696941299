import { createTheme }              from '@rneui/themed';

export const generateTheme = name => {
    let theme;

    if ('sacornerstone' === name) {
        theme =  require('./sacornerstone').default();
    }else if('differencemedia' === name){
        theme = require('./differencemedia').default();
    }
    else {
        theme = require('./default').default();
    }

    return createTheme(theme);
};
