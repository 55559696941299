import React, { useCallback, useContext } from 'react';
import { ImageBackground, View } from 'react-native';
import { useTheme, Button, Icon, Image, ListItem } from '@rneui/themed';
import TouchableScale from 'react-native-touchable-scale';
import { get, isEmpty } from 'lodash';
import { findActive } from '../../../common/helpers/navigation';
import AppContext, { AppActions } from '../../../contexts/app';
import { useApi, useDeviceInfo } from '../../../hooks';
import defaultLogo from '../../../../assets/images/logo.svg';

const Primary = props => {
    const { fetchPage } = useApi();
    const { state, dispatch } = useContext(AppContext);
    const { height, width } = useDeviceInfo();
    const { theme } = useTheme();
    const containerWidth = props.isCollapsed ? width / 3 : width / 2.5;

    const getParentPath = useCallback(() => {
        const pathname = window.location.pathname;

        if (pathname.charCount('/') > 1) {
            return `/${pathname.slice(1).split('/')[0]}`;
        }

        return '/';
    }, []);

    const renderLinks = textColor => {
        if (!isEmpty(state.navigation?.primary)) {
            const active = findActive(state.action?.href || window.location.pathname);

            return (
                <View style={{ alignItems: 'flex-end', flex: 1, justifyContent: 'center' }}>
                    {state?.navigation?.primary?.map(link => {
                        if (link.doNotIncludeInNavigation) {
                            return null;
                        }

                        const id = get(link, 'id');
                        const title = get(link, 'title');
                        const rootPath = get(link, 'rootPath');
                        const isActive =
                            rootPath.equals(active) || (rootPath.length > 1 && rootPath.replaceAll('/', '').toLowerCase().equals(active));
                        const activeStyle = isActive
                            ? {
                                  textShadowColor: theme?.colors?.white,
                                  textShadowOffset: { height: 0, width: 0 },
                                  textShadowRadius: 4
                              }
                            : {};

                        const onPress = async e => {
                            e.preventDefault();

                            if (!link.navigationOnly) {
                                await fetchPage(dispatch, link.rootPath);
                            } else {
                                dispatch({
                                    href: link.rootPath,
                                    data: { ...link },
                                    type: AppActions.NAVIGATE
                                });
                            }
                        };

                        return (
                            <ListItem
                                activeScale={0.95}
                                containerStyle={{ backgroundColor: 'transparent', paddingRight: 0 }}
                                friction={90}
                                key={id}
                                onPress={onPress}
                                tension={100}
                                Component={TouchableScale}
                            >
                                <ListItem.Content>
                                    <View style={{ alignItems: 'center', flexDirection: 'row', cursor: 'pointer' }}>
                                        <ListItem.Title style={[activeStyle, theme.navigation.web.Primary.listItemTitle, { color: textColor }]}>
                                            {title}
                                        </ListItem.Title>
                                        {isActive && <Icon {...theme.navigation.web.Primary.listItemIcon} />}
                                    </View>
                                </ListItem.Content>
                            </ListItem>
                        );
                    })}
                </View>
            );
        }
    };

    if (props.isCollapsed) {
        return (
            <View
                style={{
                    alignContent: 'center',
                    backgroundColor: theme?.colors?.black,
                    justifyContent: 'center',
                    paddingHorizontal: 2.5
                }}
            >
                <Button
                    buttonStyle={{ cursor: 'pointer' }}
                    icon={{
                        color: theme?.colors?.white,
                        name: 'chevron-left-circle-outline',
                        size: 50,
                        type: 'material-community'
                    }}
                    iconPosition='top'
                    onPress={() => {
                        dispatch({
                            href: getParentPath(),
                            type: AppActions.NAVIGATE_TO_PARENT
                        });
                    }}
                    testID='Navigation.Primary.Collapsed.Menu.Button'
                    title='Menu'
                    titleStyle={theme?.text?.White}
                    type='clear'
                />
            </View>
        );
    } else {
        if (props.siteWrapper) {
            const config = get(props.siteWrapper.contentItems, 'Primary Navigation');
            const imageSrc = get(config, 'image.cdnImagePath');
            const internalVideo = get(config, 'internalVideo.cdnImagePath');
            const labelColor = get(config, 'textColor');
            const logoSrc = get(config, 'logo.cdnImagePath', defaultLogo);
            const videoColor = get(config, 'videoTextColor', '#fffff');
            const color = labelColor || theme?.colors?.white;

            if (imageSrc) {
                return (
                    <ImageBackground
                        imageStyle={{ height: '100%', width: '100%' }}
                        source={{ uri: `https:${imageSrc}` }}
                        style={{
                            ...theme?.navigation?.web?.Primary?.container,
                            height: height,
                            width: Math.floor(containerWidth)
                        }}
                    >
                        <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
                            <Image placeholderStyle={{ opacity: 0 }} source={{ uri: defaultLogo }} style={theme.navigation.web.Primary.logo} />
                        </View>
                        {renderLinks(color)}
                    </ImageBackground>
                );
            }

            if (internalVideo) {
                return (
                    <View
                        style={{
                            width: containerWidth,
                            ...theme?.navigation?.web?.Primary?.container
                        }}
                    >
                        <View
                            style={{
                                height: height,
                                left: 0,
                                overflow: 'hidden',
                                position: 'absolute',
                                top: 0,
                                width: Math.floor(containerWidth),
                                zIndex: 0
                            }}
                        >
                            <video
                                autoPlay={!state?.settings?.websiteSettings?.environment?.equals('Development')}
                                loop={true}
                                muted={true}
                                style={{
                                    height: '100%',
                                    left: '50%',
                                    objectFit: 'fill',
                                    position: 'absolute',
                                    top: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    width: width
                                }}
                            >
                                <source src={internalVideo} />
                            </video>
                            <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
                                <Image
                                    placeholderStyle={{ opacity: 0 }}
                                    source={{ uri: defaultLogo }}
                                    style={theme.navigation.web.Primary.logo}
                                />
                            </View>
                            {renderLinks(videoColor)}
                        </View>
                    </View>
                );
            }
        }

        return (
            <View style={[theme?.navigation?.web?.Primary?.container, { width: Math.floor(containerWidth) }]}>
                {renderLinks(theme?.colors?.white)}
            </View>
        );
    }
};

export default Primary;
