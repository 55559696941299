import React, { useContext, useRef, useState } from 'react';
import { FlatList, Linking, View, TouchableOpacity } from 'react-native';
import { useTheme, Button, ListItem, SearchBar } from '@rneui/themed';
import Animated, {
    FadeIn,
    FadeOut,
    SlideInLeft,
    SlideOutLeft
} from 'react-native-reanimated';
import TouchableScale from 'react-native-touchable-scale';
import { get, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import WhereIndicator from './WhereIndicator';
import {
    findActive,
    AncillaryIconMappings
} from '../../../common/helpers/navigation';
import AppContext, { AppActions, AppEvents } from '../../../contexts/app';
import { useApi } from '../../../hooks';

let active = 'Home';
let parent;

const AncillaryToolbar = props => {
    const { theme } = useTheme();
    const ancillaryOptions = get(props, 'ancillaryOptions', []);

    if (!ancillaryOptions.links) {
        return null;
    }

    return (
        <View
            style={theme?.navigation?.mobile?.NavigationList?.container}
            testID='NavigationList.AncillaryToolbar'
        >
            {ancillaryOptions.links.map((link, idx) => {
                const icon = AncillaryIconMappings[link.text.toLowerCase()];

                return (
                    <Button
                        buttonStyle={{ backgroundColor: 'transparent' }}
                        containerStyle={{
                            justifyContent: 'center',
                            alignContent: 'center'
                        }}
                        icon={{
                            ...theme?.navigation?.mobile?.NavigationList
                                ?.ancillaryOptionIcon,
                            name: icon && icon.name,
                            type: icon && icon.type
                        }}
                        key={`${link.text}_${idx}`}
                        onPress={() => props.handlePress(link, true)}
                        title={link.text}
                        titleStyle={
                            theme?.navigation?.mobile?.NavigationList
                                ?.ancillaryText
                        }
                    />
                );
            })}
        </View>
    );
};

const NavigationList = props => {
    const { fetchPage } = useApi(dispatch);
    const { state, dispatch } = useContext(AppContext);
    const searchRef = useRef();
    const listRef = useRef();
    const [listItems, setListItems] = useState([]);
    const [searchQuery, setSearchQuery] = useState();
    const [showSearch, setShowSearch] = useState(false);
    const { theme } = useTheme();

    const NavigationItem = Animated.createAnimatedComponent(TouchableOpacity);

    const buildListItems = path => {
        const current = findActive(
            path || state.action?.href || state.action?.data?.page?.rootPath
        );
        const isPrimary =
            current && state.navigation.primary.hasOwnProperty(current);
        const isSecondary =
            current &&
            state.navigation.secondary.hasOwnProperty(path || current);
        const items = isSecondary
            ? state.navigation.secondary[current]
            : state.navigation.primary;

        if (state.navigation && items?.length > 0) {
            const _listItems = items.map(item => {
                return {
                    isAncillary: false,
                    isExternal: false,
                    url: item.rootPath,
                    title: item.title
                };
            });

            if (current) {
                if (!active.equals(current)) {
                    if (isSecondary) {
                        parent = 'Home';
                        active = current;
                    } else if (!isPrimary) {
                        parent = 'Home';
                        active = state.action?.data?.page?.title || current;
                    } else {
                        parent = undefined;
                        active = current;
                    }
                }
            } else {
                active = 'Home';
                parent = undefined;
            }

            setListItems(_listItems);
        }
    };

    const handlePress = async item => {
        if (item.title?.equals(active) || item.text?.equals(active)) {
            dispatch({
                type: AppActions.HAMBURGER_STATE_CHANGE,
                state: AppEvents.HAMBURGER_STATE_CLOSED
            });
        } else if (item?.isExternal) {
            Linking.openURL(item.url);
        } else {
            const primaryMenuItem = state.navigation.primary.find(
                e => e.title.toLowerCase() === item?.title?.toLowerCase()
            );

            if (primaryMenuItem?.navigationOnly) {
                buildListItems(item?.title?.toLowerCase());
            } else if (
                state.navigation.secondary.hasOwnProperty(
                    item?.title?.toLowerCase()
                )
            ) {
                buildListItems(item?.title?.toLowerCase());
            } else {
                const data = await fetchPage(dispatch, item.url);

                if (!isEmpty(data?.page?.contentItems)) {
                    dispatch({
                        type: AppActions.HAMBURGER_STATE_CHANGE,
                        state: AppEvents.HAMBURGER_STATE_CLOSED
                    });
                }
            }
        }
    };

    const renderItem = item => {
        return (
            <NavigationItem
                entering={FadeIn.duration(500)}
                exiting={FadeOut.duration(500)}
                key={`nav-list-${item?.item?.title}`}
                onPress={() => handlePress(item.item)}
            >
                <ListItem
                    activeScale={0.95}
                    bottomDivider={true}
                    component={TouchableScale}
                    friction={90}
                    tension={100}
                >
                    <ListItem.Content>
                        <ListItem.Title
                            numberOfLines={1}
                            style={
                                theme?.navigation?.mobile?.NavigationList?.text
                            }
                        >
                            {item?.item?.title}
                        </ListItem.Title>
                    </ListItem.Content>
                    <ListItem.Chevron
                        color='transparent'
                        name='chevron-right-circle-outline'
                        reverse={true}
                        reverseColor={
                            theme?.navigation?.mobile?.NavigationList
                                ?.chevronColor
                        }
                        size={32}
                        type='material-community'
                    />
                </ListItem>
            </NavigationItem>
        );
    };

    const setActiveMenu = async active => {
        if (active === 'Home') {
            await fetchPage(dispatch, '/');

            dispatch({
                type: AppActions.HAMBURGER_STATE_CHANGE,
                state: AppEvents.HAMBURGER_STATE_CLOSED
            });
        } else {
            buildListItems(active);
        }
    };

    if (!listItems.length) {
        buildListItems();
    }

    if (listItems?.length === 0) {
        return null;
    }

    return (
        <Animated.View
            entering={SlideInLeft}
            exiting={SlideOutLeft}
            style={{ flex: 1 }}
            testID='NavigationList.Container'
        >
            <WhereIndicator
                active={{ active, parent }}
                setActiveMenu={setActiveMenu}
                setShowSearch={() => {
                    const newState = !showSearch;

                    setShowSearch(newState);

                    dispatch({
                        type: AppActions.SEARCH_STATE_CHANGE,
                        state: newState
                            ? AppEvents.SEARCH_DIALOGUE_STATE_OPEN
                            : AppEvents.SEARCH_DIALOGUE_STATE_CLOSED
                    });
                }}
                testID='NavigationList.WhereIndicator'
            />
            {showSearch && (
                <SearchBar
                    placeholder='Search ...'
                    placeholderTextColor={theme?.colors?.grey1}
                    ref={searchRef}
                    onChangeText={query => setSearchQuery(query)}
                    onSubmitEditing={e => {
                        dispatch({
                            type: AppActions.SEARCH_STATE_CHANGE,
                            event: AppEvents.SEARCH_QUERY_SUBMITTED,
                            data: e.nativeEvent.text
                        });
                    }}
                    showCancel={true}
                    style={[
                        theme?.text?.General,
                        { color: theme?.colors?.black }
                    ]}
                    testID='NavigationList.SearchBar'
                    value={searchQuery}
                />
            )}
            {listItems && (
                <FlatList
                    contentContainerStyle={{ flexGrow: 1 }}
                    data={listItems}
                    bounces={false}
                    keyExtractor={(_, index) => index.toString()}
                    onLayout={() =>
                        listRef?.current?.scrollToOffset({
                            offset: 0,
                            animated: true
                        })
                    }
                    ref={listRef}
                    renderItem={renderItem}
                    scrollEnabled={true}
                    stickyHeaderIndices={[0]}
                    style={{ height: '100%', width: '100%' }}
                    testID='NavigationList.FlatList'
                    ListHeaderComponent={
                        <AncillaryToolbar
                            handlePress={handlePress}
                            {...props}
                        />
                    }
                />
            )}
        </Animated.View>
    );
};

NavigationList.propTypes = {
    ancillaryOptions: PropTypes.shape({
        links: PropTypes.arrayOf(PropTypes.object),
        menus: PropTypes.arrayOf(PropTypes.object)
    })
};

export default NavigationList;
